<template>
    <div class="my-container has-text-primary bg-blue-1">
        <MetaSurveyHeader :real-link="true" :disabled="isLoading"/>

        <div class="white-container">
            <div class="p-0 flexcol" style="justify-content: space-between;">
                <div>
                    <Question1 :household-electricals="householdElectricals" :current-step="currentStep"/>
                </div>

                <div class="has-text-centered mt-5 mb-5">
                    <div class="has-text-danger mb-2">
                        {{ error }}
                    </div>

                    <a v-if="currentStep.value == 4" :class="`button is-primary is-fullwidth height-46 radius-10 has-text-weight-bold ${isLoading ? 'is-loading': ''}`"
                        @click="done">
                        Enregistrer
                    </a>
                    <a v-else class="button is-primary is-fullwidth height-46 radius-10 has-text-weight-bold"
                        @click="next">
                        Suivant
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, reactive } from 'vue';
import API from '@/services/api';
import MetaSurveyHeader from '@/components/actions/MetaSurveyHeader.vue';
import Question1 from '@/components/actions/MetaActionIdentifyWaysToSave/Question1.vue';

const route = useRoute()
const router = useRouter()
const user = useUserStore()
const isLoading = ref(false)

const householdElectricals = reactive({ error: null, value: {
    kitchen: {
        american_fridge: {
            value: 0,
            maximum: 1,
            label: "Réfrigérateur américain"
        },
        fridge_freezer: {
            value: 0,
            maximum: 3,
            label: "Réfrigérateur congélateur"
        },
        freezer: {
            value: 0,
            maximum: 3,
            label: "Congélateur"
        },
        one_door_fridge: {
            value: 0,
            maximum: 3,
            label: "Réfrigérateur 1 porte"
        },
        electric_oven: {
            value: 0,
            maximum: 1,
            label: "Four électrique"
        },
        gas_oven: {
            value: 0,
            maximum: 1,
            label: "Four gaz"
        },
        hotplates: {
            value: 0,
            maximum: 1,
            label: "Plaque de cuisson électrique"
        },
        burner: {
            value: 0,
            maximum: 1,
            label: "Plaque de cuisson gaz"
        },
        dishwasher: {
            value: 0,
            maximum: 1,
            label: "Lave-vaisselle"
        }
    },
    laundry: {
        washing_machine: {
            value: 0,
            maximum: 1,
            label: "Lave-linge"
        },
        clothes_dryer: {
            value: 0,
            maximum: 1,
            label: "Sèche-linge"
        }
    },
    multimedia: {
        television: {
            value: 0,
            maximum: 3,
            label: "Téléviseur"
        },
        console: {
            value: 0,
            maximum: 3,
            label: "Console"
        },
        router: {
            value: 0,
            maximum: 1,
            label: "Box internet"
        }
    },
    other: {
        air_conditioner: {
            value: 0,
            maximum: 1,
            label: "Climatiseur"
        },
        swimming_pool: {
            value: 0,
            maximum: 1,
            label: "Piscine"
        },
        wine_cooler: {
            value: 0,
            maximum: 1,
            label: "Cave à vin"
        }
    }
}})

const currentStep = ref({value: 1})
const error = ref(null)

const next = () => {
    if (currentStep.value.value < 4) currentStep.value.value += 1
}

async function done() {
    let cleanedHouseholdElectrical = {}
    for(const cat in householdElectricals.value) {
        cleanedHouseholdElectrical[cat] = {}
        for(const obj in householdElectricals.value[cat]){
            cleanedHouseholdElectrical[cat][obj] = householdElectricals.value[cat][obj]['value']
        }
    }

    let payload = {
        'question_1':{
            'household_electrical': cleanedHouseholdElectrical,
        }
    }

    if (currentStep.value.value == 4) {
        try {
            isLoading.value = true
            let resp = await API.updateActionState(route.query.actionPk, {action_classname: "MetaActionIdentifyWaysToSave", meta_survey_answers: payload})
            if (resp.status == 200) {
                await user.update_profile()
                isLoading.value = false
                router.push({ name: "MetaEnd", query: { univers: 'other' } })
            } else {
                console.log('ERREUR', resp)
                error.value = "Erreur technique"
                isLoading.value = false
            }
        } catch(e){
            console.log('ERREUR', e)
            error.value = e.response.data.error
            isLoading.value = false
        }
    }

}
</script>

<style scoped>
.my-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    min-width: 330px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: unset;
}

.white-container {
    flex-grow: 1;
    display: flex;
    height: unset;
}
</style>
