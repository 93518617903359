<template>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user'
import { onBeforeMount } from 'vue';

const router = useRouter()
const route = useRoute()
const user = useUserStore()
console.log(route)

onBeforeMount(async () => {
    console.log("HOME ROUTER", user.isAuthenticated)
    console.log("HOME ROUTER", user.account)
    if (user.isAuthenticated) {
        if (!user.account) {
            router.push({ name: "SignUp", query: {...route.query} })
        } else if (!user.account.survey_done) {
            router.push({ name: "Survey1" })
        } else if (!user.account.has_functional_prm) {
            router.push({ name: "NotFunctionalPrm"})
        } else {
            router.push({ name: "Dashboard" })
        }
    } else {
        router.push({ name: "SignUp", query: {...route.query}})
    }
})

</script>


