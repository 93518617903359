<template>
    <div class=" mb-5">
        <input
            class="input input-border has-text-weight-semibold"
            style="color: #09275450"
            v-model="newName"
            type="text"
            placeholder=""
            name="profileOrDeviceName"
            maxlength=50
            @input="handleInput"
        >
        <div v-if="error != null" class="mt-2 has-text-danger has-text-centered font-size-14">{{ error }}</div>
    </div>
</template>

<script setup>
import { watch, ref } from 'vue'

const props = defineProps({
    profileOrDevice: Object,
    error: {
        type: String,
        required: false
    }
})

watch(() => props.profileOrDevice.value, (newOne, oldOne) => {
    console.log("NEW ONE", newOne)
    newName.value = newOne.name
})

const newName = ref(null)

const handleInput = (input) => {
    props.profileOrDevice.value.name = input.target.value
}
</script>