<template>
    <div class="flexcol" style="height: 100vh;">
        <iframe
            :src="formUrl"
            width="100%"
            height="100%"
            frameborder="0"
        >
        </iframe>
    </div>
    
</template>

<script setup>
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore()

const formUrl = computed(() => {
    let utm = userStore.externalParamsArray[1]
    let utmSource = utm != null && Object.keys(userStore.externalParamsArray[1]).includes("utm_source") ? userStore.externalParamsArray[1]['utm_source'] : 'none'
    let email = userStore.account.user.username
    let userId = userStore.account.id
    let firstName = userStore.account.user.first_name
    let prm = userStore.account.enedis_prm
    console.log("url", `https://winter-energies.typeform.com/to/GkfoKANa?utm_source=${utmSource}&email=${email}&user_id=${userId}&prenom=${firstName}&prm=${prm}`)
    return `https://winter-energies.typeform.com/to/GkfoKANa?utm_source=${utmSource}#email=${email}&user_id=${userId}&prenom=${firstName}&prm=${prm}`
})


</script>