<template>
    <div class="modal" :class="{ 'is-active': active }">
        <div class="modal-background"></div>
        <div class="modal-content modal-failed-content flexcol bg-blue-1">
            <div class="p-4 pt-6 font-size-14 is-clickable" @click="emit('close-modal')" style="height: 100px; align-items: center; display: flex; text-decoration: underline;">
                Retour
            </div>
            <div class="white-container has-text-primary">
                <div class="flexcol">
                    <div class="mb-3" style="font-size: 14px;">L'écogeste est terminé</div>
                    <!-- Action title and subtitle -->
                    <div class="mb-5">
                        <div style="width: 70%;">
                            <div class="font-size-18 has-text-weight-bold line-height-1-2">
                                {{ action.value.title }}
                            </div>
                            <div v-if="action.value.action_subtitle" class="mt-1 font-size-14">
                                {{ action.value.action_subtitle }}
                            </div>
                        </div>
                    </div>
                    <div class="primary-button mb-5 bg-blue-7">
                        <span v-if="!action.value.is_durable">Je n'ai pas réussi l'action</span>
                        <span v-else>J'ai arrêté cet écogeste</span>
                    </div>
                    <div class="has-text-weight-bold font-size-18 mb-2 line-height-1-2">
                        <span v-if="!action.value.is_durable">Pouvez-vous préciser pourquoi vous n'avez pas réussi&nbsp;?</span>
                        <span v-else>Pouvez-vous préciser pourquoi vous avez arrêté&nbsp;?</span>
                    </div>
                    <div class="font-size-14 mb-5 line-height-1-2">
                        Cela nous permettra d'améliorer nos analyses, pour que Harry puisse encore mieux vous conseiller.
                    </div>
                    <textarea v-model="reason" class="textarea is-large mb-6 border-blue-1" style="font-size: 1rem; min-height: 12em; border-radius: 10px;"></textarea>
                    <div class="primary-button bg-blue-1 mb-5 is-clickable" @click="emit('send-response', reason)">
                        Envoyer
                    </div>
                    <div class="mb-5 is-clickable font-size-14 has-text-centered has-text-weight-bold" @click="emit('close-modal')" style="text-decoration: underline;">
                        Ignorer
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const emit = defineEmits(["close-modal", "send-response"])

const props = defineProps({
    active: Boolean,
    action: Object
})

const reason = ref("")

</script>

<style scoped>

.modal-failed-content {
    height: 100%;
    max-height: 100%;
    margin: unset;
    color: white;
}

.white-container {
    flex-grow: 1;
    display: flex;
    min-height: unset;
}

</style>