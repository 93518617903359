<template>
    <div class="my-container has-text-centered" style="justify-content: space-between; position: relative;">
        <div>
            <div class="flexcol bg-blue-2" style="padding-top: 48px;">
                <div>
                    <img src="/src/assets/img/signup-done-top-pic.svg" style="height: 100px;"/>

                    <div style="padding: 0 20px 30px 20px;">
                        <div v-if="userStore.isThermostatProcess" class="flexcol font-size-20 has-text-white has-text-weight-bold mb-2 mt-4 line-height-1-2">
                            Dernière étape pour vérifier votre éligibilité
                        </div>
                        <div v-else class="flexcol font-size-20 has-text-white has-text-weight-bold mb-2 mt-4 line-height-1-2">
                            <span>Merci, on est en train</span>
                            <span>d'analyser vos données&nbsp;!</span>
                        </div>
                        
                        <div v-if="userStore.isThermostatProcess" class="has-text-white font-size-14 line-height-1-3">
                            <span>Vous êtes sur le point de découvrir si vous êtes éligible à notre offre de thermostats connectés gratuits. </span>
                            <span>Nous allons maintenant vous </span>
                            <span class="has-text-weight-bold">rediriger vers notre partenaire pour vérifier votre éligibilité</span>
                            <span> et recueillir quelques informations complémentaires.</span>
                        </div>
                        <div v-else class="flexcol has-text-white font-size-14 line-height-1-2">
                            <span>Le diagnostic de votre consommation</span>
                            <span>a commencé.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div style="background: linear-gradient(var(--winter-blue-2) 100px, white 100px)">
                <div class="bg-blue-5 radius-10 font-size-14 line-height-1-3 mx-5" style="padding: 36px;">
                    <div v-if="userStore.isThermostatProcess" class="flexcol text-blue-2">
                        <span class="has-text-weight-bold">🔒 Votre sécurité avant tout 🔒</span>
                        <span>Les informations recueillies font l'objet de traitements informatiques par Watt Watchers by Winter et ses partenaires, uniquement pour constituer et traiter votre dossier.</span>
                        <span>Vos informations sont conservées pendant la période nécessaire à ce traitement.</span>
                    </div>
                    <div v-else class="flexcol has-text-weight-bold">
                        <span class="text-blue-2">Pour personnaliser vos conseils</span>
                        <span class="text-blue-2">et vous aider à économiser,</span>
                        <span class="text-blue-2">nous avons besoin d’informations</span>
                        <span class="text-blue-2">supplémentaires sur votre logement.</span>
                        <span class="has-text-primary">Cela ne vous prendra que</span>
                        <img src="/src/assets/img/signup-done-clock.svg" style="height: 17px; margin-top: 2px;"/>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin: 54px auto;">
            <img src="/src/assets/img/signup-done-bottom-pic.svg"/>

            <a
                v-if="userStore.isThermostatProcess"
                class="button height-48 radius-10 has-text-weight-bold has-text-white mt-3 bg-blue-1"
                style="border: unset; width: 90%;"
                @click="thermoLetsGo"
            >
                Continuer
            </a>

            <RouterLink
                v-else
                class="button height-48 radius-10 has-text-weight-bold has-text-white mt-3 bg-blue-1"
                style="border: unset; width: 90%;"
                :to="{ name: 'Survey1' }"
                @click="trackClick"
            >
                Affiner mon diagnostic énergétique
            </RouterLink>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import { useRoute, useRouter } from 'vue-router';
import API from '@/services/api';

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const trackClick = () => {
    userStore.trackEvent('click_lets_go', {'page': route.path, 'origine': userStore.externalParamsArray[0]})
}

const thermoLetsGo = async () => {
    let resp = await API.addUserOnThermostatBrevoList(userStore.account.id)
    console.log("thermoLetsGo brevo resp", resp)
    trackClick()
    router.push({ name: "SignUpEmbeddedTypeForm" })
}

</script>

<style scoped>
.my-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
</style>
