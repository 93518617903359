const alertsDescription = {
    'fewData': {
        id: 'fewData',
        type: 'warning',
        mainText: 'Votre expérience sera incomplète car nous avons récupéré peu de données de chauffage pour votre logement.',
        subText: [],
        more: true,
        moreLink: '',
        isClosable: false,
        action: false,
        actionText: ''
    },
    'noHeat': {
        id: 'noHeat',
        type: 'warning',
        mainText: 'Votre expérience sera incomplète car nous n’avons pas détecté de chauffage électrique pour votre logement.',
        subText: [
            // {
            //     class: '',
            //     text: 'Vous pouvez nous contacter à '
            // }, {
            //     class: 'contact',
            //     text: 'contact@wattwatchers.fr '
            // }, {
            //     class: '',
            //     text: 'pour que nous vous aidions.'
            // }
        ],
        more: true,
        moreLink: '',
        isClosable: false,
        action: false,
        actionText: ''
    },
    'noHeatOrGaz': {
        id: 'noHeatOrGaz',
        type: 'warning',
        mainText: 'Votre expérience sera incomplète car nous n’avons pas détecté de chauffage électrique ou gaz pour votre logement.',
        subText: [],
        more: true,
        moreLink: '',
        isClosable: false,
        action: false,
        actionText: ''
    }
}

export default alertsDescription
