<template>
    <div class="flexcol" style="margin-bottom: 24px;">
        <slot name="title">
            
        </slot>
        <slot name="sub">
            <span class="font-size-14 line-height-1-2">Les informations sur votre logement servent à personnaliser nos recommandations.</span>
        </slot>
    </div>
</template>

<script setup>



</script>