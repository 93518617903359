<template>
    <div class="flexcol my-4" style="gap: 6px;">
        <Alert
            v-for="a in alertsStore.alertStack"
            :key="a.id"
            :alert="a"
            @close="alertsStore.removeAlert(a.id)"
            @action=""
            @more="emit('open-modal', a.id)"
        />
    </div>
</template>

<script setup>
import Alert from "@/components/Alert.vue"
import { useAlertsStore } from "@/stores/alert";

const alertsStore = useAlertsStore()

const props = defineProps({
    alert: Object,
})
const emit = defineEmits(['open-modal'])

</script>

<style scoped>

</style>