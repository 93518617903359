import './assets/main.scss'

// BACKEND_ROOT is defined in vite.config.js

import { createApp } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { createPinia } from 'pinia';
import { markRaw } from 'vue';
import axios from 'axios'
import App from './App.vue'
import router from './router'
import API from '@/services/api';
import { useUserStore } from '@/stores/user'

/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons'
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'
library.add([faEye, faCircleCheck, faCircle, faMagnifyingGlass, faLocationDot,
    faPlus, faArrowRight, faArrowLeft, faChevronDown, faChevronUp, faClose, faCircleArrowDown,
             faCircleArrowUp, faCirclePlus, faCircleMinus])

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon)
const pinia = createPinia()

pinia.use(({ store }) => {
    store.$router = markRaw(router)
});

app.use(pinia)

const API_URL_WITHOUT_TOKEN = [
    `${BACKEND_ROOT}/accounts/`,
    // `${BACKEND_ROOT}/accounts/validate-email/`, # not used anymore
    `${BACKEND_ROOT}/accounts/search-enedis-prm/`,
    `${BACKEND_ROOT}/accounts/get-connection-link/`,
    `${BACKEND_ROOT}/accounts/check-connection-link/`,
]

let axiosSetUp = function () {
    // point to your API endpoint
    // axios.defaults.baseURL = conf.api.root_url

    // Add a request interceptor
    axios.interceptors.request.use(
        function (config) {
            if (config.url.indexOf(`${BACKEND_ROOT}`) >= 0 &&
                API_URL_WITHOUT_TOKEN.indexOf(config.url) < 0) {
                // Do something before request is sent
                const store = useUserStore()
                const token = store.access_token
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                } else {
                    //console.error("token is not in state?", token)
                }
            }
            return config
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error)
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        async function (error) {
            let store = useUserStore()
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            // console.log("error intercept response")
            
            const originalRequest = error.config;
            console.log("error response", error.response)
            if (error.response) {
                if (
                    error.response.status === 401 &&
                    (
                        error.response.data.detail == "No active account found with the given credentials" || 
                        error.response.data.detail == "Informations d'authentification non fournies."
                    )
                ) {
                    return Promise.reject({ message: error.response.data.detail });
                } else if (
                    error.response.status === 401 &&
                    originalRequest.url.includes("login/token/verify/")
                ) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    router.push(`/inscription`);
                } else if (
                    error.response.status === 401 &&
                    error.response.data.detail == "User not found"
                ) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    router.push(`/inscription`);
                } else if (
                    error.response.status === 401 &&
                    originalRequest.url.includes("login/token/refresh/")
                ) {
                    router.push(`/inscription`);
                    return Promise.reject(error);
                } else if (error.response.status === 401 && !originalRequest._retry) {
                    // console.log("retry", store)
                    originalRequest["_retry"] = true;
                    await store.do_refresh_token();
                    return axios(originalRequest);
                } else if (error.response.status === 403){
                    router.push(`/inscription`);
                    return Promise.reject({ message: "Veuillez vous connecter à" });
                }
            }
            return Promise.reject(error);
        }
    );
}


axiosSetUp();


app.use(router)
app.mount('#app')

