<template>
    <div class="modal" :class="{ 'is-active': active }" style="justify-content: end;">
        <div class="modal-background" style="background-color: #09275490;"></div>
        <div class="modal-content modal-info-content flexcol">
            <div class="blue-container">
                <div class="flexcol">
                    <div class="flexrow my-4" style="justify-content: space-between; align-items: center;">
                        <span v-if="isProfileCreation" class="has-text-primary has-text-weight-bold font-size-18">
                            Modification du profil
                        </span>
                        <span v-else class="has-text-primary has-text-weight-bold font-size-18 line-height-1-2">
                            Création d'un profil de chauffage
                        </span>
                        <img class="is-clickable" src="/src/assets/img/univers/close_primary.svg" @click="emit('close-modal')"/>
                    </div>
                    <div class="has-text-primary font-size-18 has-text-weight-bold mb-2">Nom du profil</div>

                    <ThermostatProfileOrDeviceNameInput
                        :profile-or-device="editedProfile"
                        :error="nameError"
                    />

                    <TimeSlotsBar v-if="isProfileCreation" :time-slot-set="editedProfile.value.thermostat_time_slot_set" :big="true"/>

                    <div v-for="slot, index in editedProfile.value.thermostat_time_slot_set">
                        <TimeSlotOrder
                            :key="index"
                            :index="index"
                            :time-slot="slot"
                            :max-index="editedProfile.value.thermostat_time_slot_set.length"
                            :error="timeSlotsErrorSet.value[index]"
                            @remove-order="removeOrder"
                        />
                    </div>

                    <div
                        class="font-size-14 has-text-primary mt-4 is-clickable has-text-weight-semibold"
                        style="text-decoration: underline; text-align: center;"
                        @click="handleAddNewOrderClick"
                    >
                        Ajouter une nouvelle consigne
                    </div>
                </div>
                
                <div>
                    <div class="has-text-danger mt-3 has-text-centered">
                        {{ globalError }}
                    </div>
                    <button
                        class="button primary-button bg-blue-1 mt-5 mb-5 is-fullwidth"
                        :class="{'is-loading': isLoading}"
                        style="border: unset; box-shadow: none; color: white;"
                        @click="handleValidate"
                    >
                        Valider
                    </button>
                    <div
                        class="font-size-14 has-text-danger mt-4 is-clickable mb-5"
                        style="text-align: center;"
                        @click="emit('delete-profile', editedProfile.value.pk)"
                    >
                        Supprimer ce profil
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, watch, reactive, ref, computed } from 'vue';
import ThermostatProfileOrDeviceNameInput from '@/components/thermostat/ThermostatProfileOrDeviceNameInput.vue';
import TimeSlotsBar from '@/components/thermostat/TimeSlotsBar.vue';
import TimeSlotOrder from '@/components/thermostat/TimeSlotOrder.vue';
import { getHumanizedTime, getTimeDuration } from "@/utils.js"

const props = defineProps({
    active: Boolean,
    profile: {
        type: Object,
        required: false
    },
    error: {
        type: String,
        required: false
    }
})

const emit = defineEmits(['close-modal', 'validate-profile', 'delete-profile'])

const globalError = ref(null)
const timeSlotsErrorSet = reactive({value: {}})
const nameError = ref(null)
const timeSlotsToDelete = ref([])
const isLoading = ref(false)

watch(() => props.profile.value, (newProfile, oldProfile) => {
    console.log("WATCH SELECTED PROFILE EDITION", newProfile)
    if (newProfile != undefined && Object.keys(newProfile).length >= 0) editedProfile.value = {...newProfile}
    else editedProfile.value = {'name': '', 'thermostat_time_slot_set': [{...baseTimeSlot}]}
    globalError.value = null
    timeSlotsErrorSet.value = {}
    nameError.value = null
    isLoading.value = false
    timeSlotsToDelete.value = []
})

watch(() => props.error, (newOne, oldOne) => {
    globalError.value = newOne
    isLoading.value = false
})

watch(() => props.active, (newOne, oldOne) => {
    if (newOne) isLoading.value = false
})

const isProfileCreation = computed(() => {
    return props.profile.value != undefined && (Object.keys(props.profile.value).length >= 0)
})

const baseTimeSlot = {
    'start_time_for_input': '00:00',
    'end_time_for_input': '23:59',
    'mode': 'comfort'
}
const editedProfile = reactive({value: {'name': '', 'thermostat_time_slot_set': [{...baseTimeSlot}]}})
watch(editedProfile, (newOne) => {
    if (editedProfile.value.thermostat_time_slot_set != undefined) {
        for(let i = 1; i <= editedProfile.value.thermostat_time_slot_set.length - 1; i++) {
            editedProfile.value.thermostat_time_slot_set[i].start_time_for_input = editedProfile.value.thermostat_time_slot_set[i - 1].end_time_for_input
            editedProfile.value.thermostat_time_slot_set[i].humanized_start_time = getHumanizedTime(editedProfile.value.thermostat_time_slot_set[i].start_time_for_input)
            editedProfile.value.thermostat_time_slot_set[i].duration = getTimeDuration(editedProfile.value.thermostat_time_slot_set[i].start_time_for_input, editedProfile.value.thermostat_time_slot_set[i].end_time_for_input)
        }
        editedProfile.value.thermostat_time_slot_set[0].start_time_for_input = '00:00'
        editedProfile.value.thermostat_time_slot_set[0].humanized_start_time = getHumanizedTime(editedProfile.value.thermostat_time_slot_set[0].start_time_for_input)
        editedProfile.value.thermostat_time_slot_set[0].duration = getTimeDuration(editedProfile.value.thermostat_time_slot_set[0].start_time_for_input, editedProfile.value.thermostat_time_slot_set[0].end_time_for_input)
    }
})

const removeOrder = (index) => {
    if (Object.keys(editedProfile.value.thermostat_time_slot_set[index]).includes('pk')) {
        timeSlotsToDelete.value.push(editedProfile.value.thermostat_time_slot_set[index]['pk'])
    }
    console.log("removeOrder -> timeSlotsToDelete : ", timeSlotsToDelete.value)
    editedProfile.value.thermostat_time_slot_set.splice(index, 1)
    console.log("removeOrder -> editedProfile.value.thermostat_time_slot_set : ", editedProfile.value.thermostat_time_slot_set)
    if (index == 0) {
        editedProfile.value.thermostat_time_slot_set[0].start_time_for_input = "00:00"    
    } else if (index == editedProfile.value.thermostat_time_slot_set.length) {
        editedProfile.value.thermostat_time_slot_set[editedProfile.value.thermostat_time_slot_set.length - 1].end_time_for_input = "23:59"
    } else {
        for (let i = 1; i < editedProfile.value.thermostat_time_slot_set.length; i++) {
            editedProfile.value.thermostat_time_slot_set[i].start_time_for_input = editedProfile.value.thermostat_time_slot_set[i - 1].end_time_for_input
        } 
    }
    globalError.value = null
    timeSlotsErrorSet.value = {}
    nameError.value = null
}

const handleAddNewOrderClick = () => {
    console.log("handleAddNewOrderClick")
    editedProfile.value.thermostat_time_slot_set.push({...baseTimeSlot})
    let tabLen = editedProfile.value.thermostat_time_slot_set.length
    if (editedProfile.value.thermostat_time_slot_set.length >= 2) {
        editedProfile.value.thermostat_time_slot_set[tabLen - 1].start_time_for_input = editedProfile.value.thermostat_time_slot_set[tabLen - 2].end_time_for_input
    }
}

const handleValidate = () => {
    if (editedProfile.value.name.trim() == "") {
        nameError.value = "Veuillez indiquer un nom de profil"
    } else {
        nameError.value = null
    }
    editedProfile.value.thermostat_time_slot_set.forEach((item, index) => {
        if (item.start_time_for_input >= item.end_time_for_input) {
            console.log("PROBLEM", item.start_time_for_input, item.end_time_for_input)
            timeSlotsErrorSet.value[index] = "Le début doit être inférieur à la fin"
        } else {
            delete timeSlotsErrorSet.value[index]
        }
    })
    if (editedProfile.value.thermostat_time_slot_set.length == 0) {
        globalError.value = "Au moins une consigne doit être renseignée"
    } else if (editedProfile.value.thermostat_time_slot_set[editedProfile.value.thermostat_time_slot_set.length -1].end_time_for_input != "23:59") {
        globalError.value = "La dernière consigne doit avoir pour fin 23:59"
    } else {
        globalError.value = null
    }
    console.log("VALIDATE", editedProfile.value)
    console.log("VALIDATE time slots", Object.keys(timeSlotsErrorSet.value).length == 0)
    console.log("VALIDATE name", nameError.value == null)
    console.log("VALIDATE global", globalError.value == null)

    if (nameError.value == null && Object.keys(timeSlotsErrorSet.value).length == 0 && globalError.value == null) {
        editedProfile.value["time_slots_to_delete"] = timeSlotsToDelete.value
        emit("validate-profile", editedProfile.value)
        isLoading.value = true
    }
}

onBeforeMount(() => {
    console.log("BEFORE MOUNT", props.profile)
})

</script>

<style scoped>

.modal-info-content {
    height: 100vh;
    max-height: 100vh;
    margin: unset;
    padding-top: 50px;
}
.blue-container {
    display: flex;
    flex-direction: column;
    padding: 0 25px 0 25px;
    border-radius: 25px 25px 0 0;
    background-color: var(--winter-blue-10);
    flex-grow: 1;
    justify-content: space-between;
}
</style>