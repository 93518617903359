function debounce(delay, fn){
    var timeoutId = null;
    return function () {
        clearTimeout(timeoutId)
        var args = arguments
        var that = this
        timeoutId = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

const deepCopy = (object) => {
    return JSON.parse(JSON.stringify(object))
}

const THERMOSTAT_MODE_INFOS = {
    "comfort": {
        "label": "Confort",
        "color": "orange-1",
        "next": null,
        "prev": "economic"
    },
    "economic": {
        "label": "Eco",
        "color": "green-1",
        "next": "comfort",
        "prev": "anti_frost"
    },
    "anti_frost": {
        "label": "Hors-gel",
        "color": "blue-3",
        "next": "economic",
        "prev": "off"
    },
    "off": {
        "label": "Arrêt",
        "color": "blue-8",
        "next": "anti_frost",
        "prev": null
    },
    "off_line": {
        "label": "Hors-ligne",
        "color": "black",
        "next": null,
        "prev": null
    },
    "unpaired": {
        "label": "Absent",
        "color": "transparent",
        "next": null,
        "prev": null
    },
    "unknown": {
        "label": "Inconnu",
        "color": "white",
        "next": null,
        "prev": null
    }
}

const DAYS = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

const DAY_TRAD = {
    'monday': DAYS[1],
    'tuesday': DAYS[2],
    'wednesday': DAYS[3],
    'thursday': DAYS[4],
    'friday': DAYS[5],
    'saturday': DAYS[6],
    'sunday': DAYS[0],
}

const getTodayDay = () => {
    const today = new Date();
    return DAYS[today.getDay()];
}

const getHumanizedTime = (timeString) => {
    let splitted = timeString.split(':')
    let hours = splitted[0]
    if (hours.length == 2 && hours[0] == "0") {
        hours = hours[1]
    }
    let minutes = parseInt(splitted[1]) > 0 ? splitted[1] : ''
    return `${hours}h${minutes}`
}

const getTimeDuration = (start, end) => {
    const startTime = new Date(`1970-01-01T${start}`);
    const endTime = new Date(`1970-01-01T${end}`);

    if (endTime < startTime) {
        return 0
    }

    const durationInMs = endTime - startTime;
    const durationInHours = durationInMs / (1000 * 60 * 60);

    return durationInHours;
}

// Sort actions by DESC expected_reduction_euros
const compareBySavings = (a, b) => {
    return b.expected_reduction_euros - a.expected_reduction_euros
}

// Sort actions by is_sensitive_to_ecowatt
const compareByEcowatt = (a, b) => {
    return (b.is_sensitive_to_ecowatt === true) - (a.is_sensitive_to_ecowatt === true);
}

// Process survey error object
const processSurveyError = (error) => {
    console.log("ERROR", error)
    let errorStringified = ""
    if (Array.isArray(error.response.data)) {
        errorStringified = error.response.data.join(", ")
    }
    else if (error.response.data.error) {
        errorStringified = error.response.data.error
    }
    else if (error.response.data.non_field_errors) {
        errorStringified = error.response.data.non_field_errors
    }
    else {
        errorStringified = error.response.data
    }
    return errorStringified
}

// Survey home types list
const homeTypes = [
    {
        label: 'Maison mitoyenne',
        slug: "terraced_house"
    },
    {
        label: 'Maison isolée',
        slug: "detached_house"
    },
    {
        label: 'Appartement',
        slug: "apartment"
    },
    {
        label: 'Bureau',
        slug: "office"
    }
]

// Survey hot water sources list
const hotWaterSources = [
    {
        slug: "elec_water_heater",
        label: 'Chauffe-eau électrique'
    },
    {
        slug: "heat_pump",
        label: 'Pompe à chaleur'
    },
    {
        slug: "thermodynamic",
        label: 'Chauffe-eau thermodynamique',
        padding: 0,
    },
    {
        slug: "gas",
        label: 'Gaz'
    },
    {
        slug: "collective",
        label: 'Chauffage collectif'
    },
    {
        slug: "fuel_oil",
        label: 'Fioul'
    },
    {
        slug: "other",
        label: 'Autres / Je&nbsp;ne&nbsp;sais&nbsp;pas'
    }
]

// Quiz 1 responses set
const quiz1ResponseSet = [
    {
        label: "Une douche de 28 minutes, soit un épisode des Simpsons",
        slug: "28min",
        checked: false,
    },
    {
        label: "Une douche de 44 minutes, soit faire cuire les lasagnes de ma grand-mère",
        slug: "44min",
        checked: false,
    },
    {
        label: "Une douche de 13 minutes, soit préparer 4 oeufs à la coque successivement",
        slug: "13min",
        checked: false,
    }
]

// Quiz 1 right answer
const quiz1RightAnswer = {
    label: "Une douche de 28 minutes, soit un épisode des Simpsons",
    slug: "28min",
}

// Quiz 2 responses set
const quiz2ResponseSet = [
    {
        label: "La climatisation",
        slug: "air_conditioning",
        checked: false,
    },
    {
        label: "Les tireuses à bières",
        slug: "beer_taps",
        checked: false,
    },
    {
        label: "Le chauffage",
        slug: "heat",
        checked: false,
    },
    {
        label: "Le lecteur de CD quand on écoute Céline Dion",
        slug: "cd_player",
        checked: false,
    },
    {
        label: "L'eau chaude",
        slug: "hot_water",
        checked: false,
    }
]

// Quiz 2 right answer
const quiz2RightAnswer = {
    label: "Le chauffage",
    slug: "heat",
}

export {
    debounce,
    THERMOSTAT_MODE_INFOS,
    DAYS,
    DAY_TRAD,
    getTodayDay,
    deepCopy,
    getHumanizedTime,
    getTimeDuration,
    compareByEcowatt,
    compareBySavings,
    processSurveyError,
    homeTypes,
    hotWaterSources,
    quiz1ResponseSet,
    quiz2ResponseSet,
    quiz1RightAnswer,
    quiz2RightAnswer
}
