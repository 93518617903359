<template>
    <div class="my-container">
        <div class="flexcol" style="flex-grow: 1; padding: 20px; justify-content: space-between;">
            <div>
                <ThermostatErasureCard />
                <div>
                    <BackArrow />
                    <div class="title-page">
                        <span>Mes thermostats</span>
                        <span>connectés</span>
                    </div>

                    <div>
                        <div class="accordion-body">
                            <div v-if="loaded" class="accordion">
                                <div v-for="d, index in allDevices" :class="`container bg-blue${index % 2 == 0 ? '-5' : '-4'} ${selectedDevice.value == {} || selectedDevice.value.pk != d['pk'] ? 'not-active': 'active'}`" style="position: relative;">
                                    <div :class="`device is-clickable bg-blue${index % 2 == 0 ? '-5' : '-4'}`" @click="onClickDevice(d)">
                                        <div class="device-header">
                                            <div class="column label font-size-18 has-text-weight-bold has-text-primary p-0">{{ d["name"] }}</div>
                                            <div class="column gauge p-0" style="display: flex; flex-direction: row; align-items: center; gap: 12px; justify-content: end;">
                                                <div class="font-size-16 has-text-primary is-flex is-flex-grow-1 is-justify-content-end" style="word-break: keep-all;">{{ hasManualMode(d) ? THERMOSTAT_MODE_INFOS[d['manual_mode']]["label"].toUpperCase() : THERMOSTAT_MODE_INFOS[d['scheduled_mode']]["label"].toUpperCase() }}</div>
                                                <div :class="`gauge-point bg-${hasManualMode(d) ? THERMOSTAT_MODE_INFOS[d['manual_mode']]['color'] : THERMOSTAT_MODE_INFOS[d['scheduled_mode']]['color']}`"></div>
                                            </div>
                                        </div>
                                        <div class="space is-fullwidth bg-blue-1" style="height: 2px"></div>
                                    </div>
                                    <div class="content">
                                        <ThermostatContent
                                            :device-pk="d['pk']"
                                            :mode="hasManualMode(d) ? THERMOSTAT_MODE_INFOS[d['manual_mode']] : THERMOSTAT_MODE_INFOS[d['scheduled_mode']]"
                                            :manual-mode-end-time="d['humanized_manual_mode_end_time']"
                                            :today-scheduled-day="getTodayScheduledDay(d)"
                                            :error="changeModeManuallyError"
                                            :device-pk-in-error="changeModeManuallyErrorDevicePk"
                                            @change-mode="handleChangeMode"
                                            @open-week-programmation-modal="handleOpenWeekProgrammationModal"
                                            @open-profiles-list-modal="handleOpenProfilesListModal"
                                        />
                                    </div>
                                </div>
                                <div class="has-text-primary has-text-centered" v-if="allDevices.length == 0">
                                    <h3 class="has-text-weight-bold mb-3 mt-5">
                                        Oh non !
                                    </h3>
                                    <h2 class="line-height-1-1">
                                        Vous n'avez pas de thermostats connectés.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="allDevices != null && allDevices.length > 0" style="line-height: 1;">
                <a href="/assets/pdf/Guide_utilisation_thermostats.pdf" target="_blank" class="has-text-primary" style="text-decoration: underline;">
                    Consulter le guide d'utilisation des thermostats connectés
                </a>
            </div>
        </div>
        <ModalThermostatWeekProgrammation
            :active="showModalThermostatWeekProgrammation"
            :device-name="deviceName"
            :same-profile-for-all-week="sameProfileForAllWeek"
            :new-program="newProgram"
            :error="modalThermostatWeekProgrammationError"
            @open-profiles-list-modal="handleOpenProfilesListModal"
            @validate-new-program="handleValidateNewProgram"
            @toggle-same-profile-for-all-week="handleToggleSameProfileForAllWeek"
            @close-modal="resetModalThermostatWeekProgrammation"
        />
        <ModalThermostatProfilesList
            :active="showModalThermostatProfilesList"
            :thermostat-profiles-list="allProfiles"
            :selected-profile-pk="selectedProfilePk"
            @choose-profile="handleChooseProfile"
            @open-profile-edition-modal="handleOpenProfileEditionModal"
            @close-modal="resetModalThermostatProfilesList"
        />
        <ModalThermostatProfileEdition
            :active="showModalThermostatProfileEdition"
            :profile="selectedProfile"
            :error="modalThermostatProfileEditionError"
            @close-modal="resetModalThermostatProfileEdition"
            @validate-profile="handleValidateProfile"
            @delete-profile="handleDeleteProfile"
        />
    </div>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import API from '@/services/api'
import BackArrow from '@/components/BackArrow.vue'
import ThermostatErasureCard from '@/components/thermostat/ThermostatErasureCard.vue'
import ThermostatContent from "@/components/thermostat/ThermostatContent.vue"
import { THERMOSTAT_MODE_INFOS, DAY_TRAD, getTodayDay, deepCopy } from "@/utils.js"
import ModalThermostatProfilesList from "@/components/modals/ModalThermostatProfilesList.vue"
import ModalThermostatWeekProgrammation from "@/components/modals/ModalThermostatWeekProgrammation.vue"
import ModalThermostatProfileEdition from "@/components/modals/ModalThermostatProfileEdition.vue"

const loaded = ref(false)
const allDevices = ref(null)
const allProfiles = ref(null)
const selectedDevice = reactive({value: {}})
const showModalThermostatProfilesList = ref(false)
const showModalThermostatWeekProgrammation = ref(false)
const showModalThermostatProfileEdition = ref(false)
const selectedProfilePk = ref(null)
const selectedProfile = reactive({value: {}})
const selectedDay = ref(null)
const newProgram = reactive({value: []})
const sameProfileForAllWeek = reactive({value: false})
const deviceName = reactive({value: {'name': null}})
const modalThermostatWeekProgrammationError = ref(null)
const modalThermostatProfileEditionError = ref(null)
const changeModeManuallyError = ref(null)
const changeModeManuallyErrorDevicePk = ref(null)

/**
 * Retrieve if the device has a manual current mode
 * @param {object} device
 */
const hasManualMode = (device) => {
    return device.manual_mode != null
}

/**
 * Reset all values needed for changing program
 */
const resetProgramFields = () => {
    newProgram.value = []
    sameProfileForAllWeek.value = false
    deviceName.value = {'name': null}
}

/**
 * Set needed values for changing program
 * SelectedDevice have to be set before
 * @param {number} thermostat_scheduled_day_set_length 
 */
const initProgramFields = (thermostat_scheduled_day_set_length) => {
    if (thermostat_scheduled_day_set_length > 0) {
        newProgram.value = deepCopy(selectedDevice.value.thermostat_scheduled_day_set)
    } else {
        newProgram.value = []
    }
    sameProfileForAllWeek.value = selectedDevice.value.same_profile_for_all_week
    deviceName.value = {'name': selectedDevice.value.name}
}

/**
 * Change the selected device on click on the accordion
 * @param {object} device the device object from backend
 */
const onClickDevice = (device) => {
    console.log("onClickDevice -> device : ", device)
    resetProgramFields()
    if(Object.keys(selectedDevice.value).length > 0 && selectedDevice.value.pk == device.pk) {
        selectedDevice.value = {}
    } else {
        selectedDevice.value = device
        initProgramFields(selectedDevice.value.thermostat_scheduled_day_set.length)
    }
    console.log("onClickDevice -> deviceName.value.name", deviceName.value.name)
    console.log("onClickDevice -> newProgram.value", newProgram.value)
    console.log("onClickDevice -> sameProfileForAllWeek.value", sameProfileForAllWeek.value)
}

/**
 * Reset used datas in ModalThermostatProfileEdition 
 */
const resetModalThermostatProfileEdition = () => {
    showModalThermostatProfileEdition.value = false
    selectedProfile.value = {}
    modalThermostatProfileEditionError.value = null
}

/**
 * Reset used datas in ModalThermostatProfilesList
 */
const resetModalThermostatProfilesList = async () => {
    showModalThermostatProfilesList.value = false
    selectedDay.value = null
    selectedProfilePk.value = null
    // If this is not in a change week programmation process
    // set newProgram value with selectedDevice data
    // in order to cancel potential changes:
    if (!showModalThermostatWeekProgrammation.value) {
        await getThermostatDevicesList()
        newProgram.value = deepCopy(selectedDevice.value.thermostat_scheduled_day_set)
    }
}

/** 
 * Reset used datas in ModalThermostatWeekProgrammation
 */
const resetModalThermostatWeekProgrammation = () => {
    showModalThermostatWeekProgrammation.value = false
    initProgramFields(selectedDevice.value.thermostat_scheduled_day_set.length)
    modalThermostatWeekProgrammationError.value = null
}

/**
 * Called when the user click on next or prev arrows in order to change mode
 * @param {string} newMode 
 */
const handleChangeMode = (newMode) => {
    console.log("handleChangeMode -> newMode", newMode)
    updateThermostatDeviceMode(selectedDevice.value.pk, {
        'mode': newMode
    })
}

/**
 * Open ModalThermostatProfilesList
 * Set datas used for changing profile
 * Called from ThermostatContent or ModalThermostatWeekProgrammation
 * @param {string} profilePk 
 * @param {string} day in english
 */
const handleOpenProfilesListModal = (profilePk, day) => {
    console.log("handleOpenProfilesListModal -> day : ", day)
    console.log("handleOpenProfilesListModal -> profilePk : ", profilePk)
    selectedDay.value = day
    selectedProfilePk.value = profilePk
    showModalThermostatProfilesList.value = true
}

/**
 * Open ModalThermostatWeekProgrammation
 */
const handleOpenWeekProgrammationModal = () => {
    showModalThermostatWeekProgrammation.value = true
    console.log("handleOpenWeekProgrammationModal -> newProgram.value : ", newProgram.value)
    console.log("handleOpenWeekProgrammationModal -> selectedDevice.value.thermostat_scheduled_day_set : ", selectedDevice.value.thermostat_scheduled_day_set)
}

/**
 * Open ModalThermostatProfileEdition
 * Set datas used for editing profile
 * @param {object} profile 
 */
 const handleOpenProfileEditionModal = (profile) => {
    console.log("handleOpenProfileEditionModal -> profile : ", profile)
    if (profile != undefined) {
        selectedProfile.value = deepCopy(profile)
    } else {
        selectedProfile.value = null
    }
    showModalThermostatProfileEdition.value = true
    console.log("handleOpenProfileEditionModal -> selectedProfile.value : ", selectedProfile.value)
}

/**
 * Assign a profile to a day in newProgram value (update or create)
 * @param {string} day in english
 * @param {object} profile 
 */
const updateNewProgram = (day, profile) => {
    console.log("updateNewProgram -> day : ", day)
    console.log("updateNewProgram -> profile : ", profile)
    console.log("updateNewProgram -> newProgram.value : ", newProgram.value)
    let existingDay = newProgram.value.find(item => item.day == day)
    console.log("updateNewProgram -> existingDay : ", existingDay)
    if (day != null && Object.keys(profile).length > 0) {
        if (existingDay) {
            existingDay.profile = profile
        } else {
            newProgram.value.push({
                "day": day,
                "profile": profile
            })
        }
    }
    console.log("updateNewProgram -> newProgram.value : ", newProgram.value)
}

/**
 * Update newProgram.value
 * @param {string} chosenProfilePk
 */
const handleChooseProfile = (chosenProfilePk) => {
    console.log("handleChooseProfile -> chosenProfilePk : ", chosenProfilePk)
    console.log("handleChooseProfile -> selectedDevice.value : ", selectedDevice.value)
    // Even if programmation editing is creation or updating, we put datas in newProgram value
    // Later, at validating, newProgram.value will be sent to backend
    if (Object.keys(selectedDevice.value).length > 0) {
        let chosenProfile = allProfiles.value.find(item => item.pk === chosenProfilePk)
        console.log("handleChooseProfile -> chosenProfile : ", chosenProfile)
        console.log("handleChooseProfile -> selectedDay.value", selectedDay.value)
        if (selectedDay.value == 'all') {
            Object.keys(DAY_TRAD).forEach(day => {
                newProgram.value.push({
                    "day": day,
                    "profile": deepCopy(chosenProfile)
                })
            })
        } else {
            if (sameProfileForAllWeek.value) {
                Object.keys(DAY_TRAD).forEach(day => {
                    updateNewProgram(day, deepCopy(chosenProfile))
                })
            } else {
                updateNewProgram(selectedDay.value, deepCopy(chosenProfile))
                // In case of chosenProfile has just been modified and other days have this profile,
                // do a loop on all prog to inject same profile modification
                newProgram.value.forEach(prog => {
                    if (prog.profile.pk == chosenProfile.pk) {
                        prog.profile = deepCopy(chosenProfile)
                    }
                })
            }
        }

        // Sort newProgram like a normal week
        newProgram.value.sort((a, b) => {
            return Object.keys(DAY_TRAD).indexOf(a["day"]) - Object.keys(DAY_TRAD).indexOf(b["day"])
        })
        console.log("handleChooseProfile -> newProgram.value", newProgram.value)
        console.log("handleChooseProfile -> selectedDevice.value.thermostat_scheduled_day_set", selectedDevice.value.thermostat_scheduled_day_set)
    }
    // If this is not in a change programmation process
    if (!showModalThermostatWeekProgrammation.value) {
        handleValidateNewProgram()
    }
    resetModalThermostatProfilesList()
}

/**
 * Update newProgram.value when sameProfileForAllWeek is true
 */
const handleToggleSameProfileForAllWeek = () => {
    console.log("handleToggleSameProfileForAllWeek -> sameProfileForAllWeek.value : ", sameProfileForAllWeek.value)
    if (sameProfileForAllWeek.value) {
        if (newProgram.value.length > 0) {
            console.log("handleToggleSameProfileForAllWeek -> newProgram.value[0].profile", newProgram.value[0].profile)
            let chosenProfile = newProgram.value[0].profile
            Object.keys(DAY_TRAD).forEach(day => {
                updateNewProgram(day, deepCopy(chosenProfile))
            })
        }
    }
}

/**
 * Prepare newProgram.value for saving
 */
const handleValidateNewProgram = () => {
    console.log("handleValidateNewProgram -> newProgram.value : ", newProgram.value)
    console.log("handleValidateNewProgram -> sameProfileForAllWeek.value : ", sameProfileForAllWeek.value)
    console.log("handleValidateNewProgram -> deviceName.value.name : ", deviceName.value.name)
    let deviceToUpdate = {...selectedDevice.value}
    delete deviceToUpdate.pk
    updateThermostatDevice(selectedDevice.value.pk, {
        ...deviceToUpdate,
        'name': deviceName.value.name,
        'same_profile_for_all_week': sameProfileForAllWeek.value,
        'thermostat_scheduled_day_set': [...newProgram.value]
    })
}

/**
 * Prepare modified profile for saving
 * @param {object} validatedProfile 
 */
const handleValidateProfile = (validatedProfile) => {
    console.log("handleValidateProfile -> validatedProfile : ", validatedProfile)
    if (Object.keys(validatedProfile).includes('pk')) {
        let profileId = validatedProfile.pk
        delete validatedProfile.pk
        console.log("handleValidateProfile -> validatedProfile : ", validatedProfile)
        updateThermostatProfile(profileId, validatedProfile)
    } else {
        console.log("handleValidateProfile -> validatedProfile : CREATION")
        createThermostatProfile(validatedProfile)
    }
}

/**
 * Prepare deleting profile for saving
 * @param {string} profilePk 
 */
const handleDeleteProfile = (profilePk) => {
    console.log("handleDeleteProfile -> profilePk : ", profilePk)
    deleteThermostatProfile(profilePk)
}

/**
 * Get the today program in a profile
 * @param {object} device 
 */
const getTodayScheduledDay = (device) => {
    let scheduledDay = device.thermostat_scheduled_day_set.find(item => DAY_TRAD[item.day] == getTodayDay())
    return scheduledDay != undefined ? scheduledDay : null
}

/**
 * Call API to delete profile
 * @param {string} profileId 
 */
const deleteThermostatProfile = async(profileId) => {
    try {
        let resp = await API.deleteThermostatProfile(profileId)
        console.log("deleteThermostatProfile -> resp : ", resp)
        if (resp.status == 200) {
            await getThermostatProfilesList()
            resetModalThermostatProfileEdition()
        }
    } catch (e) {
        console.log("deleteThermostatProfile -> error.reponse.data[0] : ", e.response.data[0])
        if (e.response.data[0] != undefined) modalThermostatProfileEditionError.value = e.response.data[0]
        else if (Object.keys(e.response.data).includes('error')) modalThermostatProfileEditionError.value = e.response.data.error
        else modalThermostatProfileEditionError.value = e.response.data
    }
}

/**
 * Call API to create profile
 * @param {object} payload 
 */
const createThermostatProfile = async(payload) => {
    try {
        let resp = await API.createThermostatProfile(payload)
        console.log("createThermostatProfile -> resp : ", resp)
        if (resp.status == 200) {
            await getThermostatProfilesList()
            resetModalThermostatProfileEdition()
        }
    } catch (e) {
        console.log("createThermostatProfile -> error.reponse.data[0] : ", e.response.data[0])
        if (e.response.data[0] != undefined) modalThermostatProfileEditionError.value = e.response.data[0]
        else if (Object.keys(e.response.data).includes('error')) modalThermostatProfileEditionError.value = e.response.data.error
        else modalThermostatProfileEditionError.value = e.response.data
    }
}

/**
 * Call API to update profile
 * @param {string} profileId 
 * @param {object} payload 
 */
const updateThermostatProfile = async(profileId, payload) => {
    try {
        let resp = await API.updateThermostatProfile(profileId, payload)
        console.log("updateThermostatProfile -> resp : ", resp)
        if (resp.status == 200) {
            await getThermostatProfilesList()
            resetModalThermostatProfileEdition()
        }
    } catch (e) {
        console.log("updateThermostatProfile -> e.response.data[0]", e.response.data[0])
        if (e.response.data[0] != undefined) modalThermostatProfileEditionError.value = e.response.data[0]
        else if (Object.keys(e.response.data).includes('error')) modalThermostatProfileEditionError.value = e.response.data.error
        else modalThermostatProfileEditionError.value = e.response.data
    }
}

/**
 * Call API to update device
 */
const updateThermostatDevice = async(deviceId, payload) => {
    try {
        let resp = await API.updateThermostatDevice(deviceId, payload)
        console.log("updateThermostatDevice -> resp : ", resp)
        if (resp.status == 200) {
            await getThermostatDevicesList()
            resetModalThermostatWeekProgrammation()
        }
    } catch (e) {
        console.log("updateThermostatDevice -> e.response.data[0] : ", e.response.data[0])
        if (e.response.data[0] != undefined) modalThermostatWeekProgrammationError.value = e.response.data[0]
        else if (Object.keys(e.response.data).includes('error')) modalThermostatWeekProgrammationError.value = e.response.data.error
        else modalThermostatWeekProgrammationError.value = e.response.data
    }
}

/**
 * Call API to update device mode
 * @param {string} deviceId 
 * @param {object} payload 
 */
const updateThermostatDeviceMode = async(deviceId, payload) => {
    try {
        let resp = await API.updateThermostatDeviceMode(deviceId, payload)
        console.log("updateThermostatDeviceMode -> resp : ", resp)
        if (resp.status == 200) {
            await getThermostatDevicesList()
        }
    } catch (e) {
        console.log("updateThermostatDeviceMode -> e.response.data.error", e.response.data.error)
        if (e.response.data[0] != undefined) changeModeManuallyError.value = e.response.data[0]
        else if (Object.keys(e.response.data).includes('error')) changeModeManuallyError.value = e.response.data.error
        else changeModeManuallyError.value = e.response.data
        console.log("ERROR", changeModeManuallyError.value)
        changeModeManuallyErrorDevicePk.value = selectedDevice.value.pk
        setTimeout(() => {
            changeModeManuallyError.value = null
        }, 5000)
    }
}

/**
 * Call API to retrieve devices
 */
const getThermostatDevicesList = async() => {
    let resp = await API.getThermostatDevicesList()
    console.log("getThermostatDevicesList -> resp.data : ", resp.data)
    if (resp.status == 200) {
        allDevices.value = resp.data
        resetProgramFields()
        if (resp.data.length > 0) {
            // If there is not a selectedDevice
            if (Object.keys(selectedDevice.value).length == 0) {
                selectedDevice.value = resp.data[0]
            } else {
                selectedDevice.value = resp.data.find(item => item.pk == selectedDevice.value.pk)
            }
            initProgramFields(selectedDevice.value.thermostat_scheduled_day_set.length)
        }
    }
    console.log("getThermostatDevicesList -> selectedDevice.value : ", selectedDevice.value)
}

/**
 * Call API to retrieve profiles
 */
const getThermostatProfilesList = async() => {
    let resp = await API.getThermostatProfilesList()
    console.log("getThermostatProfilesList -> resp.data : ", resp.data)
    if (resp.status == 200) {
        allProfiles.value = resp.data
        loaded.value = true
    }
}

onBeforeMount(async() => {
    await getThermostatDevicesList()
    await getThermostatProfilesList()
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 360px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.device {
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    padding: 0px 20px 18px 20px;
}
.device-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    border-radius: 14px;
    gap: 10px;
}
.accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.accordion .container {
    position: relative;
    border-radius: 14px;
    width: 100%;
}
.accordion .label {
    position: relative;
    margin-bottom: 0;
}
.accordion .gauge::after {
    content: url(/src/assets/img/feather_menu_icon.svg);
    min-width: 25px;
    height: 20px;
    width: 25px;
    min-height: 20px;
    display: flex;
}
.accordion .content {
    position: relative;
    border-radius: 14px;
    width: 100%;
    overflow: hidden;
    height: 0px;
    transition: height 0.25s ease-in;
}
.accordion .container.active .content {
    height: 330px;
    transition: height 0.25s ease-in;
}
.accordion .container.active .gauge::after {
    content: url(/src/assets/img/feather_menu_icon_open.svg);
}
.accordion .container.active .space {
    opacity: 1;
    transition: opacity 0.25s ease;
}
.accordion .container.not-active .space {
    opacity: 0;
    transition: opacity 0.25s 0.25s ease;
}
.gauge-point {
    height: 27px;
    width: 27px;
    min-width: 27px;
    min-height: 27px;
    border-radius: 50%;
    border-width: 4px;
    border-color: white;
    border-style: solid;
}

</style>