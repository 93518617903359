import { defineStore } from 'pinia';
import alertsDescription from '@/alertsDescription.js';

export const useAlertsStore = defineStore('alerts', {
    state: () => ({
        alertStack: new Set()
    }),
    actions: {
        removeAlert(id) {
            this.alertStack.delete(alertsDescription[id])
            // TODO: faire que les alertes qui peuvent être fermées ne réapparaissent pas
        },
        addAlert(id) {
            this.alertStack.add(alertsDescription[id])
        }
    }
})
