<template>
    <div class="my-menu-button has-text-white is-clickable" style="padding: 10px 11px;" v-bind="$attrs"
        @click="showMenu = true">
        <img style="width:23px;" src="/src/assets/img/menu.svg">
    </div>

    <div class="modal" :class="{ 'is-active': showMenu }">
        <div class="modal-background"></div>
        <div class="modal-content menu-content pt-5 pr-5 pl-5">
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <figure>
                    <img src="/src/assets/img/watt_watchers.svg" style="vertical-align: middle; max-width: 260px;">
                </figure>
                <div class="my-menu-button has-text-white is-clickable"
                    style="padding: 8px 12px;" @click="showMenu = false">
                    <font-awesome-icon icon="fa-solid fa-close" style="font-size:30px" />
                </div>
            </div>

            <div class="block-menu-item mt-4 mb-5">
                <img src="/src/assets/img/menu_foyer_icon.svg" class="is-pulled-left"
                    style="margin-top:6px;margin-left:10px;">
                <img src="/src/assets/img/menu_foyer_icon_blue.svg" class="is-pulled-left"
                    style="margin-top:6px;margin-left:-45px;">
                <div class="menu-item has-text-weight-bold font-size-18">
                    Foyer de
                    <span style="text-transform: capitalize;">
                        {{ userStore.account.user.first_name }}
                    </span>
                </div>
            </div>
            <div
                :class="`block-menu-item ${areDatasAvailable ? 'is-clickable' : 'disabled'} mt-4`"
                @click="() => { if(areDatasAvailable) {router.push({ name: 'Dashboard' }); showMenu = false;} }"
            >
                <div class="icon">
                    <img class="icon-image" src="/src/assets/img/menu/home.svg">
                    <!-- <img v-if="areDatasAvailable" class="icon-image" src="/src/assets/img/menu/home.svg"> -->
                    <!-- <img v-else class="icon-image" src="/src/assets/img/menu/home_grey.svg"> -->
                </div>
                <div class="menu-item has-text-weight-bold">
                    Accueil
                </div>
            </div>
            <div
                :class="`block-menu-item ${areDatasAvailable ? 'is-clickable' : 'disabled'}`"
                @click="() => { if(areDatasAvailable) {router.push({ name: 'Conso' }); showMenu = false;} }"
            >
                <div class="icon">
                    <img class="icon-image" src="/src/assets/img/menu/conso.svg">
                    <!-- <img v-if="areDatasAvailable" class="icon-image" src="/src/assets/img/menu/conso.svg"> -->
                    <!-- <img v-else class="icon-image" src="/src/assets/img/menu/conso_grey.svg"> -->
                </div>
                <div class="menu-item has-text-weight-bold">
                    Mes consommations
                </div>
            </div>
            <div
                :class="`block-menu-item ${areDatasAvailable ? 'is-clickable' : 'disabled'}`"
                @click="() => { if(areDatasAvailable) {router.push({ name: 'ActionsList' }); showMenu = false;} }"
            >
                <div class="icon">
                    <img class="icon-image" src="/src/assets/img/menu/eco.svg">
                    <!-- <img v-if="areDatasAvailable" class="icon-image" src="/src/assets/img/menu/eco.svg"> -->
                    <!-- <img v-else class="icon-image" src="/src/assets/img/menu/eco_grey.svg"> -->
                </div>
                <div class="menu-item has-text-weight-bold">
                    Économiser
                </div>
            </div>
            <div 
                :class="`block-menu-item ${areDatasAvailable && isThermostatMenuAvailable ? 'is-clickable' : 'disabled'}`" 
                @click="() => { if(areDatasAvailable && isThermostatMenuAvailable) {router.push({ name: 'Thermostat' }); showMenu = false;} }"
            >
                <div class="icon">
                    <img class="icon-image" src="/src/assets/img/menu/community.svg">
                    <!-- <img v-if="areDatasAvailable && isThermostatMenuAvailable" class="icon-image" src="/src/assets/img/menu/community.svg"> -->
                    <!-- <img v-else class="icon-image" src="/src/assets/img/menu/community_grey.svg"> -->
                </div>
                <div class="menu-item has-text-weight-bold">
                    Piloter mes thermostats
                </div>
            </div>

            <!-- Separator -->
            <div class="bg-blue-5" style="width: 100%; height: 4px; margin: 20px 0;"></div>
            
            <div
                :class="`block-menu-item ${areDatasAvailable ? 'is-clickable' : 'disabled'}`"
                @click="() => { if(areDatasAvailable) {router.push({ name: 'Parameters' }); showMenu = false;} }"
            >
                <div class="icon">
                    <img src="/src/assets/img/menu/setup.svg">
                    <!-- <img v-if="areDatasAvailable" src="/src/assets/img/menu/setup.svg"> -->
                    <!-- <img v-else src="/src/assets/img/menu/setup_grey.svg"> -->
                </div>
                <div class="menu-item has-text-weight-bold">
                    Paramétrer mon logement
                </div>
            </div>
            <div class="block-menu-item">
                <div class="icon">
                    <img src="/src/assets/img/logout_icon.svg">
                </div>

                <div class="menu-item">
                    <a v-show="userStore.isAuthenticated" class="has-text-weight-bold has-text-primary"
                        @click="logout()">Me déconnecter</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>

import { ref, computed, onMounted } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router';
import API from '@/services/api';

const props = defineProps({
    isMenuOpened: {
        type: Boolean,
        default: false
    }
})

const showMenu = ref(false);
const userStore = useUserStore()
const router = useRouter()


const areDatasAvailable = computed(() => {
    // return userStore.account.enedis_historical_index_data_ready_email_sent_on != null && Object.keys(userStore.account.diagnostic_indicators).length > 0 && userStore.account.diagnostic_indicators.conso_chauffage_avant_telechargement > 0
    return true
})

const isThermostatMenuAvailable = computed(() => {
    return userStore.account.has_connected_thermostat
})


async function logout() {
    console.log("logout")
    await API.sendToAnalytics({
        'message_type': 'logout',
        'target_url': '/logout'
    })
    await userStore.logout()
    router.go("/")
}

onMounted(() => {
    if(props.isMenuOpened) showMenu.value = true
})

</script>


<style scoped>
.my-menu-button {
    background-color: var(--winter-blue-1);
    width: 46px;
    height: 46px;
    display: inline-block;
    border-radius: 50px;
}

.menu-content {
    background-image: url('/src/assets/img/menu/menu_bg.png');
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: top 550px center;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    max-height: calc(100vh);
    position: absolute;
    max-width: 600px;
}

.icon {
    height: 40px;
    width: 40px;
    float: left;
}

.icon-image {
    height: 37px;
    width: 37px;
}

.menu-item {
    padding: 10px;
    margin-left: 50px;
    color: var(--winter-blue-1);
}

.disabled>.menu-item {
    color: var(--winter-blue-7);
}

.block-menu-item {
    height: 50px;
    padding: 5px;
}
</style>
