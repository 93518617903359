<template>
<div v-if="loaded" class="my-container bg-blue-1">
    <div class="blue-container">
        <div class="flexrow" style="justify-content: end; padding: 20px 0 20px 0;">
            <RouterLink :to="{ name: 'ActionsList' }">
                <img src="/src/assets/img/univers/close_primary.svg"/>
            </RouterLink>
        </div>
        <div class="inside-container has-text-primary">
            <div>
                <div class="mb-3" style="display: flex; flex-direction: row; font-size: 14px; align-items: center; gap: 5px;">
                    <div v-if="action.value.recommendation_status == 'done'">L'écogeste est terminé</div>
                    <div v-else>Mon prochain écogeste</div>
                </div>

                <!-- Action title and subtitle -->
                <div class="mb-5" style="display: flex; flex-direction: row; justify-content: space-between; gap: 20px;">
                    <div style="width: 70%;">
                        <div class="font-size-18 has-text-weight-bold line-height-1-2">
                            {{ action.value.title.replace(' !', '&nbsp;!') }}
                        </div>
                        <div v-if="action.value.action_subtitle" class="mt-1 font-size-14">
                            {{ action.value.action_subtitle }}
                        </div>
                    </div>
                    <!-- not in v1 -->
                    <!-- <div style="display: flex; flex-direction: column; flex-shrink: 1;">
                        <img src="/src/assets/img/connected_thermostat.svg"/>
                    </div> -->
                </div>

                <!-- expected_reduction -->
                <EstimatedSavings v-if="Math.round(action.value.expected_reduction_euros) > 0" :expected-reduction-euros="Math.ceil(action.value.expected_reduction_euros)" bg-color="var(--winter-blue-10)"/>

                <!-- dynamic_description -->
                <div v-if="action.value.dynamic_description" class="font-size-14 mt-4 mb-3 has-text-weight-semibold" style="font-style: italic;">
                    Conseils pratiques&nbsp;:
                </div>
                <div v-if="action.value.dynamic_description" v-html="action.value.dynamic_description" class="font-size-14 mb-5 line-height-1-2"></div>
                
                <!-- dynamic_why_this_action -->
                <div v-if="action.value.dynamic_why_this_action" class="why mb-4">
                    <div class="font-size-16 has-text-weight-bold mb-2" style="text-align: center;">Pourquoi ce conseil&nbsp;?</div>
                    <div v-html="action.value.dynamic_why_this_action" class="font-size-14 line-height-1-2"></div>
                </div>

                <!-- Buttons for RECOMMENDED recommendation status -->
                <div v-if="action.value.recommendation_status == 'recommended'">
                    <LetsgoButton
                        :from-action="true"
                        :action-pk="action.value.pk"
                        :action-classname="action.value.action_classname"
                        :is-meta="action.value.is_meta"
                        :text="action.value.is_meta ? 'Répondre au questionnaire' : null"
                    />
                    <div class="not-pertinent mt-3 text-blue-2 has-text-weight-semibold is-clickable" @click="showModalNotRelevantAction = true">
                        Ce conseil n'est pas pertinent pour vous&nbsp;? <br>
                        Aidez-nous à nous améliorer
                    </div>
                </div>

                <!-- Buttons for DOING recommendation status -->
                <div v-if="action.value.recommendation_status == 'doing'" style="text-align: center;">
                    <!-- For non meta actions -->
                    <div v-if="!action.value.is_meta">
                        <div class="text-green-1 font-size-14 mt-3 mb-3" style="font-style: italic;">
                            L'action est en cours&nbsp;!
                        </div>
                        <div class="font-size-18 has-text-weight-bold">
                            Comment se passe l'action&nbsp;?
                        </div>
                        <div class="done-button is-clickable bg-blue-1" @click="endAction(null, false)">
                            <span v-if="!action.value.is_durable">C'est fait&nbsp;!</span>
                            <span v-else>Très bien, je continue</span>
                        </div>
                        <div class="fail-button is-clickable bg-blue-1" @click="showModalFailedAction = true">
                            <span v-if="!action.value.is_durable">Je n'ai pas réussi</span>
                            <span v-else>J'ai arrêté</span>
                        </div>
                    </div>
                    <!-- For meta actions -->
                    <div v-else>
                        <LetsgoButton :action-pk="action.value.pk" :action-classname="action.value.action_classname" :is-meta="action.value.is_meta" :text="action.value.is_meta ? 'Répondre au questionnaire' : null"/>
                    </div>
                </div>

                <!-- Buttons for DONE recommendation status  -->
                <div v-if="action.value.recommendation_status == 'done'" class="text-blue-2" style="text-align: center;">
                    L'action est terminée
                </div>
            </div>
            
            <!-- Harry's image -->
            <img src="/src/assets/img/harry_happy_action.svg" style="margin: 0 auto; width: 80%;"/>
        </div>
    </div>
    <ModalFailedAction :action="action" :active="showModalFailedAction" @close-modal="showModalFailedAction = false" @send-response="sendFailedResponse"/>
    <ModalNotRelevantAction :action="action" :active="showModalNotRelevantAction" @close-modal="showModalNotRelevantAction = false" @send-response="sendNotRelevantResponse"/>
</div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { RouterLink, useRouter, useRoute } from 'vue-router';
import API from '@/services/api';
import EstimatedSavings from '../components/EstimatedSavings.vue';
import LetsgoButton from '../components/LetsgoButton.vue';
import ModalFailedAction from '../components/modals/ModalFailedAction.vue';
import ModalNotRelevantAction from '../components/modals/ModalNotRelevantAction.vue';
import { useUserStore } from '@/stores/user';

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const loaded = ref(false)
const action = reactive({'value': null})
const showModalFailedAction = ref(false)
const showModalNotRelevantAction = ref(false)

watch(() => route.params.pk,
    async newPk => {
        getAction()
    }
)

const getAction = async () => {
    let resp = await API.getAction(route.params.pk)
    if (resp.status == 200) {
        action.value = resp.data
        loaded.value = true
        showModalFailedAction.value = false
        showModalNotRelevantAction.value = false
    } else {
        showModalFailedAction.value = false
        showModalNotRelevantAction.value = false
        router.push({ name: 'ActionsList' })
    }
}

const error = ref(null)

const endAction = async(reason, updateDurableAction) => {
    // If the action is_durable, go back to "Economiser" page
    if (action.value.is_durable && !updateDurableAction) {
        router.push({ name: "ActionsList" })
    }
    // else change action's status to done
    else {
        let resp = await API.updateActionState(action.value.pk, {recommendation_status: 'done', ...reason})
        if (resp.status == 200) {
            let newPk = Object.keys(resp.data).includes('new_pk') ? resp.data['new_pk'] : action.value.pk
            if(action.value.is_meta) {
                router.push({ name: `${action.value.action_classname}`, query: { actionPk: newPk } })
            } else router.push({name: 'Action', params: { pk: newPk }})
        } else {
            error.value = "Erreur technique"
            showModalFailedAction.value = false
            showModalNotRelevantAction.value = false
        }
    }
}

const sendFailedResponse = async(payload) => {
    endAction({'action_failed_reason': payload}, true)
}

const sendNotRelevantResponse = async(payload) => {
    endAction({'action_not_relevant_reason': payload}, true)
}

onMounted(() => {
    getAction()
})
</script>

<style scoped>
.my-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    min-width: 330px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: unset;
}

.blue-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    padding: 0 25px 0 25px;
    border-radius: 25px 25px 0 0;
    background-color: var(--winter-blue-10);
    flex-grow: 1;
}
.inside-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 20px 20px 0 0;
    background-color: white;
    box-shadow: 0 0 20px var(--winter-blue-9);
    padding: 20px 20px 0 20px;
    justify-content: space-between;
}

.why {
    display: flex;
    flex-direction: column;
    background-color: var(--winter-blue-10);
    border-radius: 11px;
    padding: 15px 25px 20px 25px;
}

.not-pertinent {
    text-decoration: underline;
    font-size: 14px;
    line-height: 1;
    text-align: center;
}

.done-button {
    border-radius: 10px;
    padding: 12px;
    margin-top: 8px;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
}
.fail-button {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border: solid 2px var(--winter-blue-1);
}
</style>