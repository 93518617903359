<template>
    <RouterLink class="column is-6" :to="{ name: 'Action', params: {pk: pk} }">
        <div class="container" :class="status">
            <div :class="'corner ' + status + '-corner'">{{ statusLabels[status] }}</div>
            <div class="content">
                <div>
                    <div class="title m-0 has-text-primary">{{ title }}</div>
                    <div v-html="description" class="description text-ellipsis clamp-4 has-text-primary"></div>
                </div>
                <div class="link has-text-primary has-text-weight-semibold">
                    En savoir plus
                </div>
            </div>
        </div>
    </RouterLink>
</template>

<script setup>
import { RouterLink } from 'vue-router';

const props = defineProps({
    status: String,
    title: String,
    description: String,
    pk: String,
    name: String,
})

const statusLabels = {
    "doing": "En cours",
    "done": "Déjà fait"
}

</script>

<style scoped>
.container {
    border-radius: 10px;
    max-height: calc(600px * 0.37);
    height: 40vw;
    display: flex;
    flex-direction: column;
}
.corner {
    font-weight: bold;
    border-radius: 0 0 10px 0;
    font-size: 12px;
    height: 25px;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.doing-corner {
    background-color: var(--winter-green-1);
    color: white;
    padding-bottom: 4px;
    padding-right: 4px;
}
.done-corner {
    background-color: var(--winter-blue-4);
    color: var(--winter-blue-2);
    padding-bottom: 2px;
    padding-right: 2px;
}
.doing {
    border-color: var(--winter-green-1);
    border-width: 4px;
    border-style: solid;
    background-color: var(--winter-green-3);
}
.done {
    border-color: var(--winter-blue-4);
    border-width: 2px;
    border-style: solid;
}
.content {
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.title {
    font-weight: bold;
    font-size: 15px;
}
.description {
    line-height: 1.2;
    font-size: 14px;
    padding-top: 10px;
    @media screen and (max-width: 500px) {
        display: none;
    }
}
.link {
    text-decoration: underline;
    font-size: 14px;
}
</style>