<template>
    <div class="modal" :class="{ 'is-active': active }" style="justify-content: end;">
        <div class="modal-background"></div>
        <div class="modal-content modal-info-content flexcol bg-white">
            <div
                class="has-text-primary has-text-weight-bold font-size-14 is-clickable pb-3"
                style="padding-left: 20px; text-decoration: underline;"
                @click="handleClose"
            >
				Retour
			</div>
            <div class="has-text-primary font-size-25 has-text-weight-bold" style="padding-left: 20px;">
                Profils de chauffage
            </div>
            <div class="white-container has-text-primary">
                <div class="flexcol" style="width: 100%;">
                    <div v-for="profile, index in thermostatProfilesList">
                        <ThermostatProfileCard
                            :key="profile['pk']"
                            :profile="profile"
                            :selected-profile-pk="chosenProfilePk"
                            :dynamic-border="true"
                            :has-day-label="false"
                            modify-button-type="changeTimeSlotsForTheProfile"
                            @open-profile-edition-modal="(profile) => emit('open-profile-edition-modal', profile)"
                            @click="handleClick(profile['pk'])"
                        />
                    </div>
                    <div
                        class="p-3 is-flex is-clickable create-profile-button"
                        @click="emit('open-profile-edition-modal')">
                        <span class="font-size-18 has-text-weight-bold has-text-primary">
                            Je crée mon propre profil
                        </span>
                        <img src="/src/assets/img/univers/plus.svg"/>
                    </div>
                    <div v-if="error" class="has-text-danger mt-4 has-text-centered">
                        Veuillez choisir un profil
                    </div>
                    <div class="primary-button is-clickable bg-blue-1 mt-5 mb-5" @click="handleValidate">
                        Valider
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ThermostatProfileCard from '@/components/thermostat/ThermostatProfileCard.vue'
import { ref, watch } from 'vue'

const props = defineProps({
    active: Boolean,
    thermostatProfilesList: Object,
    selectedProfilePk: {
        type: String,
        required: false
    }
})

watch(() => props.selectedProfilePk, (newPk, oldPk) => {
    console.log("WATCH SELECTED PROFILE PK", newPk)
    chosenProfilePk.value = newPk
})

const emit = defineEmits(["choose-profile", "close-modal", "open-profile-edition-modal"])

const chosenProfilePk = ref(null)
const error = ref(false)

const handleClick = (profilePk) => {
    chosenProfilePk.value = profilePk
}

const handleClose = () => {
    chosenProfilePk.value = null
    emit('close-modal')
}

const handleValidate = () => {
    console.log("CHOSEN PROFILE", chosenProfilePk.value)
    if (chosenProfilePk.value != null) {
        emit("choose-profile", chosenProfilePk.value)
        chosenProfilePk.value = null
        error.value = false
    } else {
        error.value = true
    }
}

</script>

<style scoped>

.modal-info-content {
    height: 100vh;
    max-height: 100vh;
    margin: unset;
    padding-top: 50px;
}

.white-container {
    flex-grow: 1;
    display: flex;
    min-height: unset;
}

.create-profile-button {
    border-radius: 11px;
    border-width: 2px;
    border-color: var(--winter-blue-4);
    border-style: solid;
    place-items: center;
    justify-content: space-between;
}

</style>