<template>
    <div class="modal" :class="{ 'is-active': active }">
        <div class="modal-background"></div>
        <div class="modal-content cgv-modal-content">
            <div v-html="cguContent"></div>
            <a @click="emit('toggle-modal-cgu')"
                class="button is-primary has-text-weight-bold is-fullwidth radius-10 height-46"
                style="position: sticky; bottom: 0;"
            >
                Fermer
            </a>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import API from '@/services/api';

const props = defineProps({
    active: Boolean,
})

const emit = defineEmits(["toggle-modal-cgu"])

const cguContent = ref('CGU...')

watch(() => props.active, (newOne, oldOne) => {
    if (newOne) loadCgu()
})

async function loadCgu() {
    let resp = await API.getCgu()
    cguContent.value = resp.data.content
}
</script>

<style scoped>
.cgv-modal-content {
    background-color: white;
    max-height: unset;
    padding: 20px;
    border-radius: 25px 25px 0 0;
    margin-top: 80px;
    position: relative;
}
</style>