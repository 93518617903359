<template>
    <div class="my-container">
        <div class="has-text-primary" style="padding: 20px; margin-bottom: 60px;">
            <BackArrow />
            <div class="title-page">
                Mes paramètres
            </div>
            <div class="flexcol mb-4 bg-blue-2 has-text-white radius-10 line-height-1-2" style="padding: 12px;">
                <div class="has-text-weight-semibold mb-2 font-size-18">
                    Foyer de {{ userStore.account.user.first_name }} {{ userStore.account.user.last_name }}
                </div>
                <span>
                    {{ userStore.account.enedis_address }} {{ userStore.account.enedis_zipcode }} {{ userStore.account.enedis_city_name }}
                </span>
                <span>PRM n° {{ userStore.account.enedis_prm }}</span>
            </div>

            <div class="bg-blue-5 p-4 radius-10 mb-5">
                <div class="has-text-weight-semibold font-size-16">
                    Votre coût Électricité
                </div>
                <div class="mb-2">
                    Renseignez ici le prix de l’électricité que vous payez habituellement
                </div>
                <div class="flexrow" style="gap: 10px;">
                    <input
                        class="price input is-primary height-73 font-size-20"
                        style="border-width: 3px;"
                        :class="{ 'dangerous': error }"
                        v-model="elec_price"
                        type="number"
                        placeholder=""
                        name="elec-price"
                    >
                    <div
                        class="has-text-weight-semibold font-size-20"
                        style="white-space: nowrap; align-content: center;"
                    >
                        €/kWh
                    </div>
                </div>
                <div class="text-error mb-4">{{ error }}</div>

                <!-- <div class="has-text-weight-semibold font-size-16">
                    Votre coût Gaz
                </div>
                <div class="mb-2">
                    Renseignez ici le prix du gaz que vous payez habituellement
                </div>
                <input
                    class="price input is-primary height-73 font-size-20"
                    style="border-width: 3px;"
                    :class="{ 'is-danger': false }"
                    v-model="gaz_price"
                    type="number"
                    placeholder=""
                    name="gaz-price"
                > -->

                <a class="button mt-5 mb-5 is-primary is-fullwidth height-46 radius-10 has-text-weight-bold"
                    @click="savePrices()">
                    Sauvegarder
                </a>

            </div>


            <!-- survey_data.type -->
            <div class="has-text-primary has-text-weight-semibold font-size-18 my-3 line-height-1-2">
                Votre logement :
            </div>
            <SquareList
                v-if="userStore.account.survey_data"
                :items="types.filter(item => item.slug == userStore.account.survey_data.type)"
                :selected="[]"
            />
                

            <!-- survey_data.heat_sources -->
            <div class="has-text-primary has-text-weight-semibold font-size-18 my-3 line-height-1-2">
                Vos types de chauffage :
            </div>
            <SquareList
                v-if="userStore.account.survey_data"
                :items="heatSources.filter(item => userStore.account.survey_data.heat_sources.includes(item.slug))"
                :selected="[]"
            />


            <!-- survey_data.hot_water_source -->
            <div class="has-text-primary has-text-weight-semibold font-size-18 my-3 line-height-1-2">
                Le moyen utilisé pour chauffer votre ballon d'eau :
            </div>
            <SquareList
                v-if="userStore.account.survey_data"
                :items="getHotWaterSource"
                :selected="[]"
            />

            <!-- survey_data.renovation -->
            <div v-if="userStore.account.survey_data && Object.keys(userStore.account.survey_data).includes('renovation')">
                <div class="has-text-primary has-text-weight-semibold font-size-18 my-3 line-height-1-2">
                    Vous avez réalisé des rénovations :
                </div>
                <div class="columns is-multiline is-mobile mb-0">
                    <div
                        v-if="userStore.account.survey_data && userStore.account.survey_data.renovation"
                        class="column is-12"
                    >
                        <div class="custom-toggle">
                            Oui
                        </div>
                    </div>

                    <div
                        v-if="userStore.account.survey_data && !userStore.account.survey_data.renovation"
                        class="column is-12"
                    >
                        <div class="custom-toggle">
                            Non 
                        </div>
                    </div>
                </div>
            </div>

            <!-- survey_data.cooking_devices -->
            <div v-if="userStore.account.survey_data && Object.keys(userStore.account.survey_data).includes('cooking_devices')">
                <div class="has-text-primary has-text-weight-bold mb-2 font-size-18 my-3 line-height-1-2">
                    Les appareils de cuisson que vous utilisez :
                </div>
                <SquareList
                    v-if="cd.length > 0"
                    :items="cd"
                    :selected="[]"
                />
                <div v-else>Aucun</div>
            </div>

            <!-- survey_data.household_electricals -->
            <div v-if="userStore.account.survey_data && Object.keys(userStore.account.survey_data).includes('household_electricals')">
                <div class="has-text-primary has-text-weight-bold mb-2 font-size-18 my-3 line-height-1-2">
                    Les appareils électroménagers que vous utilisez :
                </div>
                <SquareList
                    v-if="he.length > 0"
                    :items="he"
                    :selected="[]"
                />
                <div v-else>Aucun</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { mapState } from 'pinia';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import BackArrow from '@/components/BackArrow.vue';
import SquareList from '@/components/survey/SquareList.vue';
import { homeTypes, hotWaterSources } from '@/utils.js';

const router = useRouter()
const userStore = useUserStore()

const types = [...homeTypes]
const waterSources = [...hotWaterSources]

const heatSources = [
    {
        slug: "elec",
        label: 'Radiateur électrique',
    },
    {
        slug: "heat_pump",
        label: 'Pompe à chaleur',
    },
    {
        slug: "gas",
        label: 'Chaudière gaz',
    },
    {
        slug: "wood",
        label: 'Bois',
    },
    {
        slug: "fuel_oil",
        label: 'Chaudière fioul',
    },
    {
        slug: "other",
        label: 'Autres / Je&nbsp;ne&nbsp;sais&nbsp;pas',
    },
]

const heItems = [
    {
        label: "Réfrigérateur américain",
        slug: "american_fridge"
    },
    {
        label: "Réfrigérateur congélateur",
        slug: "fridge_freezer"
    },
    {
        label: "Congélateur",
        slug: "freezer"
    },
    {
        label: "Réfrigérateur 1&nbsp;porte",
        slug: "one_door_fridge"
    },
    {
        label: "Cave à vin",
        slug: "wine_cooler"
    },
    {
        label: "Lave-vaisselle",
        slug: "dishwasher"
    },
    {
        label: "Lave-linge",
        slug: "washing_machine"
    },
    {
        label: "Sèche-linge",
        slug: "clothes_dryer"
    },
    {
        label: "Box internet",
        slug: "router"
    },
    {
        label: "Climatiseur mobile",
        slug: "mobile_air_conditioner"
    },
    {
        label: "Piscine",
        slug: "swimming_pool"
    },
    {
        label: "Voiture électrique",
        slug: "electric_car"
    },
    {
        label: "Trottinette électrique",
        slug: "electric_scooter"
    },
    {
        label: "Vélo électrique",
        slug: "electric_bike"
    }
]

const cdItems = [
    {
        label: "Four électrique encastrable",
        slug: "integrated_electric_oven"
    },
    {
        label: "Four gaz encastrable",
        slug: "integrated_gas_oven"
    },
    {
        label: "Four électrique posé sur le plan de travail",
        slug: "electric_mini_oven"
    },
    {
        label: "Plaques de cuisson électriques",
        slug: "hotplates"
    },
    {
        label: "Plaques de cuisson gaz",
        slug: "burner"
    },
    {
        label: "Cuisinière (four&nbsp;et&nbsp;plaques) au gaz",
        slug: "gas_cooker"
    },
    {
        label: "Cuisinière (four&nbsp;et&nbsp;plaques) électrique",
        slug: "electric_cooker"
    },
    {
        label: "Micro-ondes",
        slug: "microwave"
    },
    {
        label: "Autres / Je&nbsp;ne&nbsp;sais&nbsp;pas",
        slug: "other"
    }
]

const getHotWaterSource = computed(() => {
    let s = userStore.account.survey_data.hot_water_source
    if(s == "elec") s = "elec_water_heater"
    return waterSources.filter(item => item.slug == s )
})

const he = computed(() => {
    let tmp = Object.keys(userStore.account.survey_data.household_electricals)
    .filter(item => userStore.account.survey_data.household_electricals[item] > 0)
    .map(item => heItems.filter(el => el.slug == item)[0] )
    return tmp
})

const cd = computed(() => {
    let tmp = Object.keys(userStore.account.survey_data.cooking_devices)
    .filter(item => userStore.account.survey_data.cooking_devices[item] > 0)
    .map(item => cdItems.filter(el => el.slug == item)[0] )
    return tmp
})

const error = ref(null)
const elec_price = ref(null)

mapState(useUserStore, ['userStore'])

if (userStore.account.custom_provider_price) {
    elec_price.value = userStore.account
        .custom_provider_price.toLocaleString("fr-FR", { minimumFractionDigits: 2 })
} else if (userStore.account.provider.elec_price_per_energy) {
    // value from provider is in €/MWh
    // we display it as €/kWh
    const value = userStore.account.provider.elec_price_per_energy / 1000.0
    elec_price.value = value.toLocaleString("fr-FR", { minimumFractionDigits: 2 })
}

async function savePrices() {
    let hasError = false

    error.value = null
    let val = parseFloat(elec_price.value.toLocaleString("fr-FR").replace(",", "."));
    if (Number.isNaN(val)) {
        error.value = "Veuillez saisir un nombre"
        hasError = true
    }

    if (!hasError) {
        let payload = {
            'custom_provider_price': val
        }

        let resp = await API.saveCustomProviderPrices(userStore.account.id, payload)
        if (resp.status == 200) {
            await userStore.update_profile()
            router.push({ name: "Conso" })
        } else {
            error.value = "Erreur technique"
        }
    }

}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.price[type=number] {
    -moz-appearance: textfield;
}

.my-container {
    max-width: 600px;
    min-width: 360px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

</style>
