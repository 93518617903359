<template>
    <div class="my-container">
        <div class="flexcol" style="flex-grow: 1; justify-content: center;">
            <div class="has-text-primary font-size-25 has-text-weight-bold mb-3 line-height-1-2" style="text-align: center;">
                Bravo d'avoir terminé<br>
                ce mini-quiz !
            </div>
            <div class="has-text-primary font-size-14 mb-6 line-height-1-2" style="text-align: center;">
                Vous pouvez retrouver de <br>
                nouveaux conseils personnalisés dans <br>
                l'espace Économiser
            </div>
            <div class="flexrow" style="justify-content: center;">
                <img src="/src/assets/img/univers/meta_end.svg" style="width: 66%;"/>
            </div>
        </div>
        <div class="flexcol p-4 mb-5" style="gap: 8px;">
            <RouterLink class="button is-primary height-46 radius-10 has-text-weight-bold" :to="{ name: 'ActionsList' }">
                Voir mes nouvelles actions
            </RouterLink>
            <RouterLink class="button height-46 radius-10 has-text-weight-bold bg-orange-2" style="border: unset;" :to="{ name: 'Dashboard' }">
                Retour à l'accueil
            </RouterLink>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.my-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    min-width: 330px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: unset;
    background-color: white;
}
</style>