<template>
<div v-for="erasure in erasureSet">
    <div v-if="showErasure[erasure.pk]" class="card-container is-flex is-flex-direction-column is-justify-content-space-between is-relative mx-4">
        <div @click="hideCard(erasure.pk)" style="position: absolute; right:10px; top:5px;">
            <font-awesome-icon icon="fa-solid fa-close has-text-primary"/>
        </div>
        <div class="is-flex is-flex-direction-row is-justify-content-space-between mr-4">
            <div class="is-flex is-flex-direction-column">
                <div class="font-size-18 has-text-weight-bold text-orange-1 mb-1 line-height-1">
                    Le réseau électrique est sous tension.
                </div>
                <div class="font-size-14 line-height-1">
                    Votre chauffage
                    <span class="has-text-weight-bold">sera baissé de 1°</span>
                    <span> le {{ erasure.humanized_start_date }} durant ces plages horaires&nbsp;:</span>
                    <p v-for="slot in erasure.schedule_element_set" class="has-text-weight-bold font-size-14 p-0 m-0">
                         de {{ slot.humanized_start }} à {{ slot.humanized_end }}
                    </p>
                </div>
            </div>
            <!-- <div class="is-flex">
                <img src="/src/assets/img/ecowatt_degree.svg">
            </div> -->
        </div>
        <div v-if="error[erasure.pk] != null" class="has-text-danger mt-3 has-text-centered line-height-1">{{ error[erasure.pk] }}</div>
        <button
            class="button has-text-primary is-fullwidth no-border height-46 radius-10 has-text-weight-bold mt-2"
            :class="{'is-loading': isLoading[erasure.pk]}"
            style="box-shadow: none; font-family: 'Montserrat';"
            @click="cancelParticipation(erasure.pk)">
            Contourner la programmation
        </button>
    </div>
</div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue';
import API from '@/services/api';

const erasureSet = ref([])
const showErasure = reactive({})
const error = reactive({})
const isLoading = reactive({})

const hideCard = (erasurePk) => {
    showErasure[erasurePk] = false
    localStorage.setItem(`hiding_thermostat_erasure_card-${erasurePk}`, "true")
}

const cancelParticipation = async (erasurePk) => {
    isLoading[erasurePk] = true
    try {
        let resp = await API.cancelThermostatErasureParticipation(erasurePk)
        console.log("cancelParticipation -> resp : ", resp)
        if (resp.status == 200) {
            getThermostatErasuresList()
        }
    } catch (e) {
        console.log("cancelParticipation -> e", e)
        if (Object.keys(e.response.data).includes('error')) error[erasurePk] = e.response.data.error
        else error[erasurePk] = e
    }
}

const cleanLocalStorage = () => {
    for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        if (key.includes("hiding_thermostat_erasure_card")) {
            let tmpPk = key.split("-")[1]
            if (!Object.keys(showErasure).includes(tmpPk)) {
                localStorage.removeItem(key)
            }
        }
    }
}

const getThermostatErasuresList = async () => {
    let resp = await API.getThermostatErasuresList()
    console.log("getThermostatErasuresList -> resp.data : ", resp.data)
    if (resp.status == 200) {
        erasureSet.value = resp.data
        erasureSet.value.forEach((erasure) => {
            isLoading[erasure.pk] = false
            let hide = localStorage.getItem(`hiding_thermostat_erasure_card-${erasure.pk}`)
            if(hide == "true") {
                showErasure[erasure.pk] = false
            } else {
                showErasure[erasure.pk] = true
            }
        })
        cleanLocalStorage()
    }
}

onMounted(() => {
    getThermostatErasuresList()
})
</script>

<style scoped>
.card-container {
    background-color: var(--winter-yellow-2);
    border-radius: 10px;
    margin: 5px;
    padding: 15px;
}
</style>