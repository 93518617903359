<template>
    <div class="flexrow is-clickable" @click="router.go(-1)" style="gap: 8px; align-items: center;">
        <img src="/src/assets/img/arrow_left.svg" style="height: 12px; width: 12px;"/>    
        <span class="font-size-14 has-text-weight-semibold has-text-primary">Retour</span>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter()

</script>