<template>
    <div :class="`mb-4 p-4 bg-white ${dynamicBorder ? selectedProfilePk == profile.pk ? 'border-selected is-clickable' : 'border-initial is-clickable' : 'no-border'}`" style="border-radius: 11px;">
        <div v-if="hasDayLabel" class="font-size-18 has-text-weight-bold has-text-primary has-text-centered">
            <span v-if="hasTodayLabel">Aujourd'hui</span>
            <span v-else>{{ profileDay }}</span>
        </div>
        <div class="flexrow mb-3" style="justify-content: space-between;">
            <div class="font-size-18 has-text-weight-bold has-text-primary">
                {{ profile.name }}
            </div>
            <div
                class="font-size-16 has-text-weight-bold has-text-primary is-clickable"
                style="text-decoration: underline;"
                @click="handleModifyClick"
            >
                Modifier
            </div>
        </div>
        <TimeSlotsBar :time-slot-set="profile.thermostat_time_slot_set"/>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { getTodayDay } from "@/utils.js"
import TimeSlotsBar from "./TimeSlotsBar.vue"

const emit = defineEmits(["open-profiles-list-modal", "open-profile-edition-modal"])

const props = defineProps({
    profile: Object,
    hasDayLabel: Boolean,
    hasTodayLabel: Boolean,
    profileDay: {
        type: String,
        required: false
    },
    selectedProfilePk: {
        type: String,
        required: false
    },
    dynamicBorder: Boolean,
    modifyButtonType: String,
})

const dayName = ref(null)

const handleModifyClick = () => {
    console.log("PROFILE CARD CLICK", props.modifyButtonType)
    if (props.modifyButtonType === 'changeProfileForTheDay') {
        emit('open-profiles-list-modal', props.profile.pk)
    }
    else if (props.modifyButtonType === 'changeTimeSlotsForTheProfile') {
        emit('open-profile-edition-modal', props.profile)
    }
}

onBeforeMount(() => {
    dayName.value = getTodayDay()
})

</script>

<style scoped>
.no-border {
    border: none;
}
.border-initial {
    border-color: var(--winter-blue-4);
    border-width: 2px;
    border-style: solid;
}
.border-selected {
    border-color: var(--winter-blue-2);
    border-width: 4px;
    border-style: solid;
}
</style>