import axios from 'axios';
import API from '@/services/api';
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useAlertsStore } from './alert'

const ecowatt_showing_values = [2, 3]

export const useUserStore = defineStore('user', {
    state: () => ({
        isAuthenticated: false,
        account: null,
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        selectedBanAddress: null,
        prmFound: null,
        externalParams: {},
    }),
    getters: {
        isThermostatProcess: (state) => {
            let ret = false
            if (state.account != null && state.account.origin == 'thermostat-connecte') {
                ret = true
            }
            return ret
        },
        externalParamsArray: (state) => {
            let origin = null
            let utm = null
            let externalParams = {...state.externalParams}
            if (externalParams != null) {
                if (Object.keys(externalParams).includes('origine')) {
                    origin = externalParams.origine
                    delete externalParams.origine
                }
                utm = Object.keys(externalParams).length > 0 ? externalParams : null
            }
            return [origin, utm]
        }
    },
    actions: {
        "setExternalParams": function (data) {
            this.externalParams = data
        },
        "set_account": function (data) {
            if (!data.survey_data) {
                data.survey_data = {}
            }
            this.account = data
        },
        "login": async function (user, pass) {
            let resp = await API.login(user, pass)
            // console.log('login', resp.data)
            this.update_tokens(resp.data.access, resp.data.refresh)
            this.isAuthenticated = true
            resp = await API.myProfile()
            // console.log(resp.data)
            this.account = resp.data.account
            this.$router.push({ name: "HomeRouter" })
        },
        "update_profile": async function () {
            let resp = await API.myProfile()
            const alertsStore = useAlertsStore()
            // console.log(resp)
            this.isAuthenticated = true
            if (resp.data.account.origin != null || resp.data.account.utm != null) {
                this.setExternalParams({'origine': resp.data.account.origin, ...resp.data.account.utm })
            }
            if (resp.data.account.incomplete_experience_on_app){
                if (resp.data.account.incomplete_experience_on_app.user_with_too_few_data) {
                    alertsStore.addAlert('fewData')
                } else {
                    alertsStore.removeAlert('fewData')
                }
                if (resp.data.account.incomplete_experience_on_app.user_without_electric_heating) {
                    // A user without electric heating has few data so display just one alert
                    alertsStore.removeAlert('fewData')
                    alertsStore.addAlert('noHeat')
                } else {
                    alertsStore.removeAlert('noHeat')
                }
            }
            this.account = resp.data.account
        },
        "update_tokens": function (access, refresh) {
            localStorage.setItem('access_token', access)
            localStorage.setItem('refresh_token', refresh)
            this.access_token = access
            this.refresh_token = refresh
        },
        "do_refresh_token": async function () {
            try {
                let response = await axios.post(`${BACKEND_ROOT}/login/token/refresh/`, { refresh: localStorage.getItem('refresh_token') })
                this.update_tokens(response.data.access, localStorage.getItem('refresh_token'))
            } catch (error) {
                console.log("refresh_token failed")
                // context.dispatch("logout")
            }
        },
        "logout": async function () {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            this.access_token = null
            this.refresh_token = null
            this.isAuthenticated = false
            this.$router.push({ name: "SignUp" })
        },
        "want_free_thermostat": function () {
            if (this.account.survey_data.thermostat || this.account.survey_data.heat_sources.includes('elec') && this.account.survey_data.heat_sources.length > 2 ) {
                // TODO renvoie vers une page Webflow de non éligibilité
            } else {
                // Send to eligibility survey
                let email = this.account.user.username
                let firstName = this.account.enedis_first_name
                let lastName = this.account.enedis_last_name
                // let surface = this.account.survey_data.surface
                let address = this.account.enedis_address
                let city = this.account.enedis_city_name
                let zipcode = this.account.enedis_zipcode
                // let homeOwner = this.account.survey_data.home_owner ? 'proprietaire' : 'locataire'
                // let department = this.account.enedis_city_code.slice(0, 2)
                // let type = this.account.survey_data.type == 'house' ? 'maison individuelle' : 'appartement'
                let source = this.account.utm != null && Object.keys(this.account.utm).includes('utm_source') ? this.account.utm['utm_source'] : 'null'
                let prm = this.account.enedis_prm
                // let data = `email=${email}&prenom=${firstName}&nom=${lastName}&surface=${surface}&adresse=${address}&ville=${city}&codepostal=${zipcode}&proprietaire=${homeOwner}&departement=${department}&type=${type}`
                // window.location.href = "https://winter-energies.typeform.com/to/iZlKdCU9#" + data;
                // changement du lien: ticket 502 https://gitlab.crossdata.tech/c0211/hamster/backend/-/issues/502
                let data = `email=${email}&prenom=${firstName}&nom=${lastName}&adresse=${address}&codepostal=${zipcode}&ville=${city}&source=${source}&prm=${prm}`
                window.location.href = "https://winter-energies.typeform.com/to/gsWXTOxb#" + data;
            }
        },
        "check_thermostat_eligibility": function () {
            // Send to eligibility survey
            let email = this.account.user.username
            let firstName = this.account.enedis_first_name
            let lastName = this.account.enedis_last_name
            let address = this.account.enedis_address
            let zipcode = this.account.enedis_zipcode
            let city = this.account.enedis_city_name
            let source = this.account.utm != null && Object.keys(this.account.utm).includes('utm_source') ? this.account.utm['utm_source'] : 'null'
            let prm = this.account.enedis_prm
            let data = `email=${email}&prenom=${firstName}&nom=${lastName}&adresse=${address}&codepostal=${zipcode}&ville=${city}&source=${source}&prm=${prm}`
            window.location.href = "https://winter-energies.typeform.com/to/gsWXTOxb#" + data;
        },
        "get_eco_watt_daily_status": async function () {
            let resp = await API.getEcoWattDailyStatus()
            let isEcoWattDay = false
            let statusNumber = -1
            if (resp.status == 200 ) {
                statusNumber = resp.data
                // if today signal is in ecowatt_showing_values, this is an ecowatt day
                if (ecowatt_showing_values.includes(resp.data)) isEcoWattDay = true;
            }
            return {"isEcoWattDay": isEcoWattDay, "statusNumber": statusNumber }
        },
        "hasValidToken": async function () {
            let ret = false
            try {
                let response = await axios.post(`${BACKEND_ROOT}/login/token/verify/`, { token: localStorage.getItem('refresh_token') })
                if (response.status == 200) ret = true 
                console.log("VERIFY TOKEN", response.status)
            } catch (error) {
                console.log("verify_token failed")
            }
            return ret
        },
        "trackEvent": function (event_name, payload) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': event_name,
                ...payload
            });
            console.log("dataLayer", window.dataLayer)
        },
        "endingSurvey": function () {
            console.log("ENDING SURVEY STORE", this)
            API.endingSurveyData(this.account.id).then((resp) => {
                if (resp.status == 200) {
                    console.log("!!! 200 !!!")
                    this.trackEvent('finish_onboarding', {'origine': this.externalParamsArray[0]})
                    this.update_profile()
                    console.log("AFTER ENDING SURVEY check survey_done", this.account.survey_done)
                }
            })
        }
    }
})
