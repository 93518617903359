<template>
    <div :class="`is-clickable action-card ${action.recommendation_status}`" @click="goToAction(action)">
        <div style="min-height: 36px; min-width: 36px; max-height: 36px; max-width: 36px; margin-right: 12px;">
            <img v-if="action.recommendation_status == 'done'" src="/src/assets/img/action/action_done.png" />
            <img v-else :src="`/assets/action/action_${action.action_type}.png`" />
        </div>
        <div class="flexcol has-text-primary" style="gap: 8px; flex-grow: 1;">
            <span class="font-size-14 has-text-weight-bold line-height-1-2">{{ action.title.replace(" !", "&nbsp;!") }}</span>
            <span
                v-if="Math.ceil(action.expected_reduction_euros) > 0 && (action.recommendation_status == 'doing' || action.recommendation_status == 'recommended')"
                class="font-size-12 has-text-weight-medium"
            >
                {{ Math.ceil(action.expected_reduction_euros) }}€/an d'économies potentielles
            </span>
            <div class="flexrow" style="gap: 8px; margin-top: 2px;">
                <Tag :status="action.recommendation_status" :text="getHumanizedType[action.action_type]"/>
                <Tag :status="action.recommendation_status" :text="getStatusInfo[action.recommendation_status]['humanizedStatus']"/>
            </div>
        </div>
        <div style="padding: 6px 0 0 16px;">
            <ColoredChevron :color="getStatusInfo[action.recommendation_status]['chevronColor']"/>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from "@/stores/user";
import ColoredChevron from "@/components/actions/ColoredChevron.vue"
import Tag from "@/components/actions/Tag.vue"

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const props = defineProps({
    action: Object
})

const getStatusInfo = {
    "not_recommended": {
        "chevronColor": "#092754",
        "humanizedStatus": "Pas pertinente",
    },
    "recommended": {
        "chevronColor": "#006BCF",
        "humanizedStatus": "À faire",
    },
    "doing": {
        "chevronColor": "#E88306",
        "humanizedStatus": "En cours",
    },
    "done": {
        "chevronColor": "#468300",
        "humanizedStatus": "Réalisée",
    },
}

const getHumanizedType = {
    "ecogesture": "Écogeste",
    "diy_jobs": "Petits travaux"
}

const goToAction = (action) => {
    userStore.trackEvent('click_action_card', {'page': route.path, 'action_name': action.action_classname, 'action_status': action.recommendation_status})
    router.push({ name: 'Action', params: { 'pk': action.pk } })
}
</script>

<style scoped>
.action-card {
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: row;;

    &.not_recommended {
        background-color: #E2E2E2;
    }
    &.recommended {
        background-color: var(--winter-blue-5);
    }
    &.doing {
        background-color: var(--winter-yellow-2);
    }
    &.done {
        background-color: var(--winter-green-3);
    }
}
</style>