<template>
    <div class="my-container has-text-centered">
        <div class="flexcol" style="justify-content: space-between; flex-grow: 1;">
            <div>
                <QuestionCard
                    :question-array="['Savez-vous quel est le plus gros','poste de dépense énergétique', 'des foyers français&nbsp;?']"
                    :bg-color="userResult ? 'bg-green-3' : 'bg-yellow-2'"
                />

                <AnswerWhiteCard
                    :user-result="userResult"
                    :user-answer="userAnswer"
                    :right-answer="quiz2RightAnswer"
                />
                
                <div style="margin-top: 20px; margin-bottom: 20px;">
                    <img src="/src/assets/img/quiz_heat.svg" />
                </div>
                <div class="font-size-20 line-height-1-2" style="padding: 0 20px;">
                    <div v-if="userResult" class="flexcol text-green-1 has-text-weight-bold">
                        <span>Bingo !</span>
                        <span>C'est bien le chauffage. Il représente 66% de la consommation annuelle d'énergie des français.</span>
                    </div>
                    <div v-else class="flexcol text-blue-2 has-text-weight-bold">
                        <span>Vous y êtes presque,</span>
                        <span>c'est le chauffage. Il représente 66% de la consommation annuelle d'énergie des français.</span>
                    </div>
                </div>
            </div>
            <div class="next-bloc-quiz">
                <div class="flexcol line-height-1-2 has-text-primary has-text-weight-medium" style="margin-bottom: 36px;">
                    <span>À votre tour, découvrez quels sont</span>
                    <span>vos principaux postes de consommation</span>
                    <span>et comment économiser dès aujourd’hui.</span>
                </div>
                <div v-if="error" class="text-error">{{ error }}</div>
                <div
                    v-if="userStore.account.is_pipeline_live_done == true"
                    class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold"
                    style="width: 100%; margin-bottom: 36px;"
                    @click="next">
                    C'est parti !
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, computed, onBeforeMount, onMounted, onUnmounted } from 'vue';
import { quiz2ResponseSet, quiz2RightAnswer } from '@/utils.js';
import QuestionCard from '@/components/waitingScreens/QuestionCard.vue';
import AnswerWhiteCard from '@/components/waitingScreens/AnswerWhiteCard.vue';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const error = ref(false)

let intervalId = null

const userAnswer = ref(null)
const userResult = computed(() => {
    return userAnswer.value != null ? quiz2RightAnswer.slug === userAnswer.value.slug : false
})

const next = () => {
    userStore.trackEvent('click_next_step', {'page': route.path, 'origine': userStore.externalParamsArray[0]})
    router.push({ name: 'HomeRouter' })
}

onBeforeMount(async() => {
    console.log("WaitingScreen5")
    if (userStore.account.quiz_data && userStore.account.quiz_data.biggest_expense != undefined) {
        quiz2ResponseSet.forEach(r => {
            if (r.slug == userStore.account.quiz_data.biggest_expense) {
                userAnswer.value = r
            }
        })
    } else {
        router.push({ name: 'WaitingScreen4' })
    }
})

onMounted(() => {
    // We practice pipeline live, so we call userStore.update_profile
    // in order to have updated userStore.account.is_pipeline_live_done
    // when it's true, the button for see dashboard is displayed
    intervalId = setInterval(async() => {
        console.log("interval", userStore.account.is_pipeline_live_done)
        await userStore.update_profile()
        if (userStore.account.is_pipeline_live_done) {
            console.log("kill interval")
            clearInterval(intervalId)
        }
    }, 5000);
})

onUnmounted(() => {
    clearInterval(intervalId)
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;
}


</style>