<template>
    <div>
        <div class="flexrow is-align-items-center mb-2" style="gap: 6px">
            <div class="is-flex bg-blue-5" style="height: 35px; width: 35px; border-radius: 50%; justify-content: center; align-items: center;">
                <img src="/src/assets/img/univers/hot_water.svg" style="height: 20px;"/>
            </div>
            <div class="has-text-weight-bold font-size-18 text-blue-6">Eau chaude sanitaire</div>
        </div>
        <div class="bg-blue-5 mb-3" style="height: 1px; width: 100%;"></div>
        <div
            class="bg-blue-5 p-4 radius-10 has-text-weight-semibold font-size-14 mb-4"
            style="width:fit-content;">
            Question 4/4
        </div>

        <div class="has-text-primary has-text-weight-bold mb-2 font-size-18 mt-4 line-height-1-2">
            Calculons ensemble le débit de votre pommeau de douche.
        </div>
        <div class="has-text-primary mb-2 font-size-14 mb-5 line-height-1-2">
            Voici un test tout simple pour mesurer le débit de votre douchette.
            <br><br>
            <span class="has-text-weight-bold">Pour le réaliser, vous avez besoin&nbsp;:</span><br>
            - d'un contenant d'1 litre (par exemple un sac congélation, un seau gradué...)<br>
            - d'un chronomètre<br>
            - de votre douchette
        </div>
        <div class="mb-5">
            <div class="flexrow font-size-14 mb-2 line-height-1-2">
                <div>
                    <div class="bg-blue-1 mr-2 blue-dots">
                        <div class="has-text-white has-text-weight-bold">1</div>
                    </div>
                </div>
                <div>
                    <span class="has-text-weight-bold">Démarrez votre chronomètre </span>
                    et commencez immédiatement à 
                    <span class="has-text-weight-bold">remplir votre contenant avec votre douchette.</span>
                </div>
            </div>
            <div class="flexrow font-size-14 mb-2 line-height-1-2">
                <div>
                    <div class="bg-blue-1 mr-2 blue-dots">
                        <div class="has-text-white has-text-weight-bold">2</div>
                    </div>
                </div>
                <div>
                    Attendez que 
                    <span class="has-text-weight-bold">votre contenant se remplisse.</span>
                </div>
            </div>
            <div class="flexrow font-size-14 mb-2 line-height-1-2">
                <div>
                    <div class="bg-blue-1 mr-2 blue-dots">
                        <div class="has-text-white has-text-weight-bold">3</div>
                    </div>
                </div>
                <div>
                    <span class="has-text-weight-bold">Stoppez le chronomètre à l'instant </span>
                    où le contenant de 1L est totalement rempli.
                </div>
            </div>
            <div class="flexrow font-size-14 mb-2 line-height-1-2">
                <div>
                    <div class="bg-blue-1 mr-2 blue-dots">
                        <div class="has-text-white has-text-weight-bold">4</div>
                    </div>
                </div>
                <div>
                    <span class="has-text-weight-bold">Renseignez ci-dessous le nombre de secondes </span>
                    affichées par le chronomètre.
                </div>
            </div>
        </div>

        <div>
            <!-- Shower form -->
            <div class="mb-4">
                <div>Nom de votre douche</div>
                <div class="control mb-2">
                    <input class="input input-border" :class="{ 'is-danger': showerName.error }"
                        v-model="showerName.value" type="text" placeholder="" name="showerName" maxlength=50>
                </div>
                <div class="has-text-danger" v-if="showerName.error">{{ showerName.error }}</div>
                <div>Temps en seconde</div>
                <div class="control mb-2">
                    <input class="input input-border has-text-primary" :class="{ 'is-danger': showerFlow.error }"
                    v-model="showerFlow.value" type="number" min="0" step="0.1" placeholder="" name="showerName">
                </div>
                <div class="has-text-danger" v-if="showerFlow.error">{{ showerFlow.error }}</div>
                <div class="button radius-10 has-text-white bg-blue-1 has-text-weight-bold border-blue-1" style="height: 30px;" @click="validateShower">
                    Ajouter
                </div>
            </div>

            <!-- Showers list -->
            <div>
                <div class="has-text-weight-bold">Vos douches</div>
            </div>
            <div v-if="showers.value.length > 0">
                <div class="shower-box" v-for="(shower, index) in showers.value" :key="`${index}-${shower.shower_flow}`">
                    <div class="p-1" style="position: absolute; top:0; right: 0;" @click="removeShower(index)">
                        <img src="/src/assets/img/close_little_blue.svg"/>
                    </div>
                    <div class="flexrow" style="justify-content: space-between;">
                        <div class="mr-2 has-text-weight-bold">Nom</div>
                        <div style="text-align: end;">{{ shower.shower_name }}</div>
                    </div>
                    <div class="flexrow" style="justify-content: space-between;">
                        <div class="mr-2 has-text-weight-bold">Temps en seconde</div>
                        <div>{{ shower.shower_flow }}</div>
                    </div>
                </div>
            </div>
            <div v-else class="font-size-14">Aucune douche</div>
        </div>
        <div class="has-text-danger" v-if="showers.error && showers.value.length == 0">{{ showers.error }}</div>
    </div>
</template>


<script setup>
import { ref } from 'vue';

const props = defineProps({
    showers: Object
})

const showerName = ref({error: null, value: null})
const showerFlow = ref({error: null, value: null})

const removeShower = (index) => {
    props.showers.value.splice(index, 1)
}

const validateShower = () => {
    if(showerName.value.value != null && showerName.value.value.trim() != "" && showerFlow.value.value > 0) {
        props.showers.value.push({shower_name: showerName.value.value, shower_flow: showerFlow.value.value})
        showerName.value.value = null
        showerName.value.error = null
        showerFlow.value.value = null
        showerFlow.value.error = null
    } else {
        if(showerName.value.value == null || showerName.value.value.trim() == "") showerName.value.error = "Un nom est obligatoire"
        else showerName.value.error = null
        if(showerFlow.value.value == null || showerFlow.value.value == 0) showerFlow.value.error = "Un temps en seconde supérieur à zéro est obligatoire"
        else showerFlow.value.error = null
    }
}

</script>

<style scoped>
.blue-dots {
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shower-box {
    border-radius: 14px;
    border: 1px solid var(--winter-blue-2);
    padding: 20px;
    margin-bottom: 4px;
    position: relative;
}
</style>
