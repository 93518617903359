<template>
    <div v-if="mode['label'] != 'Hors-ligne' && mode['label'] != 'Absent'" class="pr-5 pl-5">
        <!-- Change mode card -->
        <div v-if="mode['label'] != 'Inconnu'" class="flexrow p-2 mb-4 bg-white" style="height: 54px; border-radius: 11px; text-align: center;">
            <img :class="{'is-clickable': mode['prev']}" :src="`/assets/thermostat/arrow-left-circle${mode['prev'] ? '' : '-disabled'}.svg`" @click="onClick('prev')">
            <div class=" line-height-1-2" style="flex-grow: 1; align-self: center;">
                <p class="p-0 m-0" :class="`text-${mode['color']} has-text-weight-bold font-size-20`">{{ mode['label'] }}</p>
                <p v-if="manualModeEndTime != ''" class="font-size-12 m-0 p-0 has-text-primary">jusqu'à {{ manualModeEndTime }}</p>
            </div>
            <img :class="{'is-clickable': mode['next']}" :src="`/assets/thermostat/arrow-right-circle${mode['next'] ? '' : '-disabled'}.svg`" @click="onClick('next')">
        </div>
        <div v-if="error != null && devicePk == devicePkInError" class="has-text-danger font-size-14 has-text-centered mb-3 line-height-1">
            {{ error }}
        </div>

        <!-- Program card -->
        <ThermostatProfileCard
            v-if="todayScheduledDay"
            :profile="todayScheduledDay.profile"
            :dynamic-border="false"
            :has-day-label="true"
            :has-today-label="true"
            modify-button-type="changeProfileForTheDay"
            @open-profiles-list-modal="(profilePk) => emit('open-profiles-list-modal', profilePk, todayScheduledDay.day)"
        />
        <div v-else>
            <div class="p-2 has-text-primary has-text-centered line-height-1-2">
                Afin de contrôler votre appareil, veuillez renseigner une programmation
            </div>
        </div>

        <!-- Modify program link -->
        <div
            class="has-text-primary font-size-16 has-text-weight-bold is-clickable"
            style="text-decoration: underline; text-align: center;"
            @click="emit('open-week-programmation-modal')"
        >
            Modifier le programme de la semaine
        </div>
    </div>
    <div v-else class="pr-5 pl-5 has-text-primary has-text-centered">
        <p v-if="mode['label'] == 'Absent'">
            Votre thermostat n'est plus appairé avec votre appareil
        </p>
        <p v-else>
            Aucune information ou action n'est possible sur cet appareil
        </p>
    </div>
</template>

<script setup>
import ThermostatProfileCard from "./ThermostatProfileCard.vue"
import { ref, onBeforeMount, watch } from 'vue';

const emit = defineEmits([
    "change-mode",
    "open-week-programmation-modal",
    "open-profiles-list-modal"
])

const props = defineProps({
    devicePk: String,
    mode: Object,
    manualModeEndTime: String,
    todayScheduledDay: {
        type: Object,
        required: false
    },
    error: String,
    devicePkInError: String,
})

const error = ref(null)

watch(() => props.error, (newOne, oldOne) => {
    error.value = newOne
})

const onClick = (to) => {
    if (to == 'prev' && props.mode['prev']) {
        console.log("PREV MODE", props.mode['prev'])
        emit("change-mode", props.mode['prev'])
    }
    else if (to == 'next' && props.mode['next']) {
        console.log("NEXT MODE", props.mode['next'])
        emit("change-mode", props.mode['next'])
    }
}

onBeforeMount(() => {
    console.log("BEFORE MOUNT CONTENT", props.todayScheduledDay)
    console.log("BEFORE MOUNT CONTENT", props.mode)
})

</script>

<style scoped>


</style>