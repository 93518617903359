<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="41.269" viewBox="0 0 200 41.269">
        <g id="Groupe_1028" data-name="Groupe 1028" transform="translate(3 -3.863)">
            <text id="_10" data-name="10" transform="translate(33 42.132)" fill="#092754" :font-size="fontSize" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">{{ limits[0] }}</tspan></text>
            <text id="_20" data-name="20" transform="translate(73 42.132)" fill="#092754" :font-size="fontSize" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">{{ limits[1] }}</tspan></text>
            <text id="_30" data-name="30" transform="translate(113 43.132)" fill="#092754" :font-size="fontSize" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">{{ limits[2] }}</tspan></text>
            <text id="_40" data-name="40" transform="translate(153 43.132)" fill="#092754" :font-size="fontSize" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">{{ limits[3] }}</tspan></text>
            <!-- <text id="_50_" data-name="50+" transform="translate(130.86 42.132)" fill="#092754" font-size="8" font-family="Montserrat-Regular, Montserrat"><tspan x="0" y="0">50+</tspan></text> -->
            <g id="Groupe_1027" data-name="Groupe 1027" transform="translate(0 0)">
            <line id="Ligne_313" data-name="Ligne 313" x2="33.184" transform="translate(0 26.411)" fill="none" stroke="#468300" stroke-linecap="round" stroke-width="6"/>
            <line id="Ligne_314" data-name="Ligne 314" x2="33.184" transform="translate(39.849 26.411)" fill="none" stroke="#8ccb57" stroke-linecap="round" stroke-width="6"/>
            <line id="Ligne_315" data-name="Ligne 315" x2="33.184" transform="translate(79.696 26.411)" fill="none" stroke="#ffc134" stroke-linecap="round" stroke-width="6"/>
            <line id="Ligne_316" data-name="Ligne 316" x2="33.184" transform="translate(119.546 26.411)" fill="none" stroke="#E88306" stroke-linecap="round" stroke-width="6"/>
            <line id="Ligne_317" data-name="Ligne 317" x2="33.184" transform="translate(159.394 26.411)" fill="none" stroke="#da3a00" stroke-linecap="round" stroke-width="6"/>
            <g id="Groupe_370" data-name="Groupe 370" :transform="`translate(${x} 32.941) rotate(-164)`">
                <g id="Groupe_369" data-name="Groupe 369" transform="translate(0 11.759) rotate(-40)">
                <path id="Tracé_1806" data-name="Tracé 1806" d="M13.042,1.294l1.066,12.428A1.42,1.42,0,0,1,12.1,15.136L.816,9.892A1.419,1.419,0,0,1,.21,7.861a1.438,1.438,0,0,1,.4-.417L10.82.261a1.412,1.412,0,0,1,2.222,1.033" transform="translate(2.09 2.096)" :fill="color"/>
                <path id="Tracé_1807" data-name="Tracé 1807" d="M17.213,3.2,18.28,15.63a3.519,3.519,0,0,1-4.979,3.5L2.02,13.887A3.487,3.487,0,0,1,.146,11.719,3.519,3.519,0,0,1,1.5,7.829L11.713.646a3.494,3.494,0,0,1,5.5,2.556m-2.45-1.923a2.435,2.435,0,0,0-2.452.222L2.1,8.684a2.453,2.453,0,0,0-.685.725h0a2.485,2.485,0,0,0-.264,2.007,2.446,2.446,0,0,0,1.315,1.52l11.281,5.245a2.47,2.47,0,0,0,3.5-2.458L16.172,3.3a2.427,2.427,0,0,0-1.409-2.017" transform="translate(0 0)" fill="#fff"/>
                </g>
                <path id="Tracé_1808" data-name="Tracé 1808" d="M15.128,2.248,16.2,14.676a2.472,2.472,0,0,1-3.5,2.458L1.418,11.89A2.464,2.464,0,0,1,.368,8.362h0a2.453,2.453,0,0,1,.686-.725L11.267.453a2.453,2.453,0,0,1,3.861,1.795m-3.262-.94L1.652,8.492a1.417,1.417,0,0,0,.21,2.448l11.281,5.245a1.42,1.42,0,0,0,1.808-.548,1.406,1.406,0,0,0,.2-.868L14.088,2.342a1.412,1.412,0,0,0-2.222-1.033" transform="translate(1.474 11.89) rotate(-40)" fill="#fff"/>
            </g>
            </g>
        </g>
    </svg>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    color: String,
    limits: Object
})

const deviceWidth = ref(0)

const fontSize = computed(() => {
    let ret = 11
    if (deviceWidth.value > 500) {
        ret = 9
    }
    return ret
})

const x = computed(() => {
    let barLong = 195
    let padding = 7
    let ret = 0
    switch(props.color) {
        case "green":
            ret = padding + 1 + barLong / 10
            break
        case "yellow-green":
            ret = padding + 2 + barLong / 10 * 3
            break
        case "yellow":
            ret = padding + 3 + barLong / 2
            break
        case "orange":
            ret = padding + 4 + barLong / 10 * 7
            break
        case "red":
            ret = padding + 5 + barLong / 10 * 9
            break
    }
    return ret
})

const color = computed(() => {
    if(props.color == "green") return "#468300"
    else if(props.color == "yellow-green") return "#8ccb57"
    else if(props.color == "yellow") return "#ffc134"
    else if(props.color == "orange") return "#e88306"
    else if(props.color == "red") return "#da3a00"
})

onMounted(() => {
    deviceWidth.value = document.documentElement.clientWidth
})

</script>