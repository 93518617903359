<template>
    <div class="modal" :class="{ 'is-active': active }" style="justify-content: end;">
        <div class="modal-background" style="background-color: #09275490;"></div>
        <div class="modal-content modal-info-content flexcol">
            <div class="blue-container">
                <div class="flexrow my-4" style="justify-content: space-between; align-items: center;">
                    <span class="has-text-primary has-text-weight-bold font-size-18">Programme de la semaine</span>
                    <img class="is-clickable" src="/src/assets/img/univers/close_primary.svg" @click="emit('close-modal')"/>
                </div>
                <div class="has-text-primary font-size-14 has-text-weight-bold mb-2">Nom du thermostat</div>
                <ThermostatProfileOrDeviceNameInput
                    :profile-or-device="deviceName"
                />
                <div class="flexrow mt-2 mb-5" style="gap: 16px; align-items: center;">
                    <div class="has-text-primary font-size-14 line-height-1-2">
                        <span>J'applique un profil de chauffage pour </span>
                        <span class="has-text-weight-bold">tous les jours de la semaine</span>
                    </div>
                    <div>
                        <RadioButton
                            :activated="sameProfileForAllWeek.value"
                            @toggle-radio="toggleSameProfileForAllWeek"
                        />
                    </div>
                </div>
                <div class="flexcol mt-2" style="justify-content: space-between; flex-grow: 1;">
                    <!-- There is already a programmation -->
                    <div v-if="newProgram.value.length == 7">
                        <!-- This programmation is different for all days -->
                        <div v-if="!sameProfileForAllWeek.value">
                            <div v-for="scheduledDay, index in newProgram.value">
                                <ThermostatProfileCard
                                    :key="index"
                                    :profile="scheduledDay.profile"
                                    :profile-day="DAY_TRAD[scheduledDay.day]"
                                    :has-day-label="true"
                                    modify-button-type="changeProfileForTheDay"
                                    @open-profiles-list-modal="(profilePk) => emit('open-profiles-list-modal', profilePk, scheduledDay.day)"
                                />
                            </div>
                        </div>
                        <!-- This programmation is the same for all days -->
                        <div v-else>
                            <ThermostatProfileCard
                                :profile="newProgram.value[0].profile"
                                :has-day-label="false"
                                modify-button-type="changeProfileForTheDay"
                                @open-profiles-list-modal="(profilePk) => emit('open-profiles-list-modal', profilePk, newProgram.value[0].day)"
                            />
                        </div>
                    </div>
                    <!-- There is no programmation yet -->
                    <div v-else>
                        <!-- This programmation is different for each days -->
                        <div v-if="!sameProfileForAllWeek.value">
                            <div v-for="day in Object.keys(DAY_TRAD)" class="mb-2">
                                <div class="has-text-primary font-size-18 has-text-weight-bold mb-2">{{ DAY_TRAD[day] }}</div>
                                <ThermostatProfileChoiceInput
                                    :key="day"
                                    :profile="newProgram.value.find(item => item.day == day) != undefined ? newProgram.value.find(item => item.day == day).profile : null"
                                    @open-profiles-list-modal="emit('open-profiles-list-modal', null, day)"
                                />
                            </div>
                        </div>
                        <!-- This programmation is the same for each days -->
                        <div v-else>
                            <div class="has-text-primary font-size-18 has-text-weight-bold mb-2">Tous les jours</div>
                            <ThermostatProfileChoiceInput
                                :profile="newProgram.value.length > 0 ? newProgram.value[0] : null"
                                @open-profiles-list-modal="emit('open-profiles-list-modal', null, 'all')"
                            />
                        </div>
                    </div>
                    <div>
                        <div v-if="nameError != null || progError != null || globalError != null" class="has-text-danger mt-3 has-text-centered">
                            <p class="m-0 p-0">{{ nameError }}</p>
                            <p class="m-0 p-0">{{ progError }}</p>
                            <p class="m-0 p-0">{{ globalError }}</p>
                        </div>
                        <button
                            class="button primary-button bg-blue-1 mt-5 mb-5 is-fullwidth"
                            :class="{'is-loading': isLoading}"
                            style="border: unset; box-shadow: none; color: white;"
                            @click="handleValidate"
                        >
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import RadioButton from '@/components/thermostat/RadioButton.vue';
import ThermostatProfileCard from '@/components/thermostat/ThermostatProfileCard.vue'
import ThermostatProfileChoiceInput from '@/components/thermostat/ThermostatProfileChoiceInput.vue'
import ThermostatProfileOrDeviceNameInput from '@/components/thermostat/ThermostatProfileOrDeviceNameInput.vue'
import { onBeforeMount, onUpdated, ref, watch } from 'vue'
import { DAY_TRAD } from '@/utils.js'

const props = defineProps({
    active: Boolean,
    newProgram: Object,
    sameProfileForAllWeek: Object,
    deviceName: Object,
    error: {
        type: String,
        required: false
    }
})

const emit = defineEmits([
    'close-modal',
    'open-profiles-list-modal',
    'toggle-same-profile-for-all-week',
    'validate-new-program'
])

watch(() => props.error, (newOne, oldOne) => {
    globalError.value = newOne
    isLoading.value = false
})

watch(() => props.active, (newOne, oldOne) => {
    if (newOne) isLoading.value = false
})

const nameError = ref(null)
const progError = ref(null)
const globalError = ref(null)
const isLoading = ref(false)

const toggleSameProfileForAllWeek = () => {
    props.sameProfileForAllWeek.value = !props.sameProfileForAllWeek.value
    console.log("SAME IN WEEK PROG", props.sameProfileForAllWeek.value)
    emit('toggle-same-profile-for-all-week')
}

const handleValidate = () => {
    if(props.deviceName.value.name.trim() == "") {
        nameError.value = "Veuillez indiquer un nom personnalisé pour votre thermostat"
    } else {
        nameError.value = null
    }
    if(props.newProgram.value.length != 7) {
        progError.value = "Veuillez associer un profil à chaque jour"
    } else {
        progError.value = null
    }
    
    console.log("VALIDATE", props.newProgram.value.length != 7, props.deviceName.value.name.trim() == "")
    if(nameError.value == null && progError.value == null) {
        emit('validate-new-program')
        isLoading.value = true
    }
}

onUpdated(() => {
    console.log("update WEEK PROG", props.deviceName.value.name)
})

onBeforeMount(() => {
    console.log("before mount WEEK PROG", props.newProgram.value)
    console.log("before mount WEEK PROG", props.sameProfileForAllWeek.value)
    console.log("before mount WEEK PROG", props.deviceName.value.name)
})

</script>

<style scoped>

.modal-info-content {
    height: 100vh;
    max-height: 100vh;
    margin: unset;
    padding-top: 60px;
}

.blue-container {
    display: flex;
    flex-direction: column;
    padding: 0 25px 0 25px;
    border-radius: 25px 25px 0 0;
    background-color: var(--winter-blue-10);
    flex-grow: 1;
}


</style>