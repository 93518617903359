<template>
    <div>
        <div class="flexrow is-align-items-center mb-2" style="gap: 6px">
            <div class="is-flex bg-blue-5" style="height: 35px; width: 35px; border-radius: 50%; justify-content: center; align-items: center;">
                <img src="/src/assets/img/univers/other.svg" style="width: 21px;"/>
            </div>
            <div class="has-text-weight-bold font-size-18 text-blue-6">Autres usages électriques</div>
        </div>
        <div class="bg-blue-5 mb-3" style="height: 1px; width: 100%;"></div>
        <div
            class="bg-blue-5 p-4 radius-10 has-text-weight-semibold font-size-14"
            style="width:fit-content;">
            Question
        </div>

        <div class="has-text-primary has-text-weight-bold mb-2 font-size-18 mt-3 line-height-1-2">
            Quels appareils électroménagers utilisez-vous&nbsp;?
        </div>
        <div class="has-text-primary font-size-14 mb-3 line-height-1-2">
            Sélectionnez les équipements que vous utilisez parmi les équipements ci-dessous.
        </div>

        <div class="flexrow mb-5" style="justify-content: space-between; align-items: center;">
            <div class="has-text-primary font-size-18 has-text-weight-bold">
                {{ stepLabels[currentStep.value - 1] }}
            </div>
            <div class="flexrow" style="gap: 10px;">
                <div class="step-button bg-blue-5 radius-10" @click="prevStep">
                    <img v-if="currentStep.value > 1" src="/src/assets/img/household_electrical/arrow_left.svg" />
                    <img v-else src="/src/assets/img/household_electrical/arrow_left_disable.svg" />
                </div>
                <div class="step-button bg-blue-5 radius-10" @click="nextStep">
                    <img v-if="currentStep.value < 4" src="/src/assets/img/household_electrical/arrow_right.svg" />
                    <img v-else src="/src/assets/img/household_electrical/arrow_right_disable.svg" />
                </div>
            </div>
        </div>

        <div class="columns is-multiline is-mobile mb-0">
            <div v-for="(obj, key) in householdElectricals.value[stepKeys[currentStep.value - 1]]" class="column is-6 p-0 mb-4">
                <div class="square-item-button-old is-clickable" :class="{ 'is-selected': obj.value > 0 }"
                    @click="add(obj)">
                    <img class="mb-4" style="height: 45%; max-width: 70%; margin: 0 auto;"
                        :src="'/assets/household_electrical/' + key + '.svg'">
                    <div class="has-text-weight-semibold font-size-14 has-text-centered line-height-1-2">
                        {{ obj.label }}
                    </div>
                    <div v-if="obj.value > 0" 
                        class="bg-blue-5 has-text-primary flexcol has-text-centered has-text-weight-bold font-size-14" 
                        style="height:30px; width: 30px; border-radius: 50%; position: absolute; top: 5px; right: 5px; justify-content: center;">
                        {{ obj.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
const props = defineProps({
    householdElectricals: Object,
    currentStep: Object
})

const stepLabels = ["Cuisine", "Linge", "Multimédia", "Autre"]
const stepKeys = ["kitchen", "laundry", "multimedia", "other"]

const prevStep = () => {
    if(props.currentStep.value > 1) props.currentStep.value -= 1
}
const nextStep = () => {
    if(props.currentStep.value < 4) props.currentStep.value += 1
}
const add = (obj) => {
    if(obj.value < obj.maximum) obj.value += 1;
    else obj.value = 0;
}

</script>

<style scoped>
.step-button {
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
