<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 6/7</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Quels équipements utilisez-vous&nbsp;?</span>
                    </template>
                </SurveyHeader>

                <!-- householdElectricals field -->
                <SquareList :items="sources" :selected="[...householdElectricals.value]" :display-value="true" @click-item="updateHouseholdElectricals"/>

                <div class="text-error mb-4" v-if="householdElectricals.error">{{ householdElectricals.error }}</div>


                <!-- mobileAirConditionerFrequency field -->
                <div v-if="householdElectricals.value.has('mobile_air_conditioner')">
                    <div class="survey-question">
                        Votre climatisateur mobile est utilisé&nbsp;?
                    </div>

                    <RadioButton
                        v-for="el, index in mobileAirConditionerFrequencyOptions"
                        :label="el.label"
                        :checked="el.checked"
                        @click="changeRadioButton(index, mobileAirConditionerFrequency, mobileAirConditionerFrequencyOptions)"
                    />

                    <div class="text-error mb-4" v-if="mobileAirConditionerFrequency.error">{{ mobileAirConditionerFrequency.error }}</div>
                </div>
            </div>


            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>
                
                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref, reactive } from 'vue';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import SquareList from '@/components/survey/SquareList.vue';
import RadioButton from '@/components/survey/RadioButton.vue';
import { processSurveyError } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const householdElectricals = reactive({ error: null, value: new Set()})
const mobileAirConditionerFrequency = ref({ error: null, value: null})

const sources = reactive([
    {
        value: 0,
        maximum: 1,
        label: "Réfrigérateur américain",
        slug: "american_fridge"
    },
    {
        value: 0,
        maximum: 2,
        label: "Réfrigérateur congélateur",
        slug: "fridge_freezer"
    },
    {
        value: 0,
        maximum: 3,
        label: "Congélateur",
        slug: "freezer"
    },
    {
        value: 0,
        maximum: 2,
        label: "Réfrigérateur 1&nbsp;porte",
        slug: "one_door_fridge"
    },
    {
        value: 0,
        maximum: 1,
        label: "Cave à vin",
        slug: "wine_cooler"
    },
    {
        value: 0,
        maximum: 1,
        label: "Lave-vaisselle",
        slug: "dishwasher"
    },
    {
        value: 0,
        maximum: 1,
        label: "Lave-linge",
        slug: "washing_machine"
    },
    {
        value: 0,
        maximum: 1,
        label: "Sèche-linge",
        slug: "clothes_dryer"
    },
    {
        value: 0,
        maximum: 1,
        label: "Box internet",
        slug: "router"
    },
    {
        value: 0,
        maximum: 1,
        label: "Climatiseur mobile",
        slug: "mobile_air_conditioner"
    },
    {
        value: 0,
        maximum: 1,
        label: "Piscine",
        slug: "swimming_pool"
    },
    {
        value: 0,
        maximum: 2,
        label: "Voiture électrique",
        slug: "electric_car"
    },
    {
        value: 0,
        maximum: 3,
        label: "Trottinette électrique",
        slug: "electric_scooter"
    },
    {
        value: 0,
        maximum: 3,
        label: "Vélo électrique",
        slug: "electric_bike"
    }
])

const mobileAirConditionerFrequencyOptions = reactive([
    {
        label: "Occasionnellement, en cas de vague de chaleur",
        slug: "occasionally",
        checked: false
    },
    {
        label: "Régulièrement pendant l’été",
        slug: "regularly",
        checked: false
    }
])

const initOptions = (field, optionsObject) => {
    optionsObject.forEach(el => {
        if(el.slug == field.value) el.checked = true
    })
}

if (userStore.account.survey_data) {
    if (userStore.account.survey_data.household_electricals != undefined) {
        sources.forEach(el => {
            if(Object.keys(userStore.account.survey_data.household_electricals).includes(el.slug)) {
                el.value = userStore.account.survey_data.household_electricals[el.slug]
                if(el.value > 0) householdElectricals.value.add(el.slug)
            }
        })
    }
    mobileAirConditionerFrequency.value.value = userStore.account.survey_data.mobile_air_conditioner_frequency || null
    initOptions(mobileAirConditionerFrequency.value, mobileAirConditionerFrequencyOptions)
}

const resetOptions = (field, optionsObject) => {
    field.value = null
    optionsObject.forEach((el, i) => {
        el.checked = false
    })
}

const changeRadioButton = (index, field, optionsObject) => {
    optionsObject.forEach((el, i) => {
        if(i == index) {
            el.checked = true
            field.value = el.slug
        }
        else el.checked = false
    })
}

const updateHouseholdElectricals = (slug) => {
    sources.forEach(el => {
        if(el.slug == slug) {
            if(el.value < el.maximum) {
                el.value += 1
                householdElectricals.value.add(slug)
            } else {
                el.value = 0
                householdElectricals.value.delete(slug)
                if(slug == 'mobile_air_conditioner') resetOptions(mobileAirConditionerFrequency.value, mobileAirConditionerFrequencyOptions)
            }
        }
    })
}

const done = async() => {
    const devices = sources.reduce(
        (acc, el) => {
            acc[el.slug] = el.value
            return acc
        },
        {}
    )

    let payload = {
        'step6_done': true,
        'household_electricals': devices,
        'mobile_air_conditioner_frequency': mobileAirConditionerFrequency.value.value
    }

    let hasError = false;

    if (householdElectricals.value.has("mobile_air_conditioner") && mobileAirConditionerFrequency.value.value == null) {
        mobileAirConditionerFrequency.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        mobileAirConditionerFrequency.value.error = null
    }

    console.log('done', payload)

    if(!hasError){
        try {
            isLoading.value = true
            let resp = await API.pushSurveyData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0]})
                await userStore.update_profile()
                router.push({ name: "Survey7" })
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "household_electricals": householdElectricals.error || '',
            "mobile_air_conditioner_frequency": mobileAirConditionerFrequency.value.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}
</script>

<style scoped>

</style>
