<template>
    <div class="modal" :class="{ 'is-active': active }" style="justify-content: end;">
        <div class="modal-background bg-blue-1" style="opacity: 63%;"></div>
        <div class="modal-content modal-info-content flexcol">
            <div class="white-container has-text-primary">
                <div class="flexcol" style="justify-content: space-between; width: 100%;">
                    <div class="mb-6">
                        <div class="flexrow mt-2 mb-5" style="justify-content: space-between;">
                            <div class="font-size-20 has-text-weight-bold has-text-primary line-height-1-2 pr-4">
                                Votre consommation au cours du temps
                            </div>
                            <img src="/src/assets/img/close_modal.svg" class="is-clickable" style="align-self: start;" @click="emit('toggle-info')"/>
                        </div>
                        <Article v-for="(art, i) in articles" :key="art['index']" :title="art['title']" :content="art['content']" :not-last="i + 1 != articles.length" />
                    </div>
                    <div
                        class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold mt-0 mb-5"
                        style="width: 100%;"
                        @click="emit('toggle-info')">
                        J'ai compris
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Article from "@/components/Article.vue"

const props = defineProps({
    active: Boolean
})

const emit = defineEmits(["toggle-info"])

const articles = [
    {   
        'index': 1,
        'title': 'Comment calculez-vous la consommation prévue ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'La consommation prévue correspond à la consommation attendue pour votre logement sur la base de vos habitudes de consommation lors de l’année précédente.'
            }
        ]
    },
    {
        'index': 2,
        'title': 'Comment diminuer ma consommation ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Si vous réalisez les actions recommandées (visibles sur la page “Mes actions”), votre consommation d’énergie devrait diminuer.'
            }
        ]
    },
    {
        'index': 3,
        'title': 'D’autres questions ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'N’hésitez pas à nous écrire à <a href="mailto:contact@wattwatchers.fr">contact@wattwatchers.fr</a>, nous serons ravis de vous aider.'
            }
        ]
    }
]

</script>

<style scoped>

.modal-info-content {
    height: 100vh;
    max-height: 100vh;
    margin: unset;
    padding-top: 170px;
}

.white-container {
    flex-grow: 1;
    display: flex;
    min-height: unset;
}

</style>