<template>
    <div class="my-container has-text-primary bg-blue-1">
        <MetaSurveyHeader :real-link="realLink()" :onClick="getBack" :disabled="isLoading"/>

        <div class="white-container">
            <div class="p-0 flexcol" style="justify-content: space-between;">
                <div>
                    <Question1 v-if="currentQuestion == 1" :take-baths="takeBaths" />
                    <Question2 v-if="currentQuestion == 2" :long-shower="longShower" />
                    <Question3 v-if="currentQuestion == 3" @remove-people="removePeople" :people-in-home="peopleInHome" :known-people-in-home="knownPeopleInHome" :want-to-add-people="wantToAddPeople"/>
                    <Question4 v-if="currentQuestion == 4" :showers="showers" />
                </div>

                <div class="has-text-centered mt-5 mb-5">
                    <div class="has-text-danger mb-2">
                        {{ error }}
                    </div>

                    <a :class="`button is-primary is-fullwidth height-46 radius-10 has-text-weight-bold ${isLoading ? 'is-loading': ''}`"
                        @click="next">
                        <span v-if="currentQuestion == 4">Enregistrer</span>
                        <span v-else>Suivant</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, reactive } from 'vue';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import MetaSurveyHeader from '@/components/actions/MetaSurveyHeader.vue';
import Question1 from '@/components/actions/MetaActionIdentifyWaysToSaveHotWater/Question1.vue';
import Question2 from '@/components/actions/MetaActionIdentifyWaysToSaveHotWater/Question2.vue';
import Question3 from '@/components/actions/MetaActionIdentifyWaysToSaveHotWater/Question3.vue';
import Question4 from '@/components/actions/MetaActionIdentifyWaysToSaveHotWater/Question4.vue';

const route = useRoute()
const router = useRouter()
const user = useUserStore()

const knownPeopleInHome = { people_count: user.account.survey_data.nb_people, days_count: 30 }

const takeBaths = ref({ error: null, value: null })
const longShower = ref({ error: null, value: null })
const peopleInHome = reactive({ error: null, value: [] })
const wantToAddPeople = ref({error: null, value: null})
const showers = reactive({ error: null, value: [] })
const currentQuestion = ref(1)
const error = ref(null)
const isLoading = ref(false)

const removePeople = (index) => {
    peopleInHome.value.splice(index, 1)
}

const realLink = () => {
    if(currentQuestion.value == 1) return true
    return false
}

const getBack = () => {
    if(currentQuestion.value != 1) currentQuestion.value -= 1
}

const hasError = ref(false)
const next = () => {
    hasError.value = false
    if(currentQuestion.value == 1) {
        if(takeBaths.value.value == null) {
            takeBaths.value.error = "Merci de sélectionner une option."
            hasError.value = true
        } else {
            takeBaths.value.error = null
            currentQuestion.value = 2
        }
    }
    else if(currentQuestion.value == 2) {
        if(longShower.value.value == null) {
            longShower.value.error = "Merci de sélectionner une option."
            hasError.value = true
        } else {
            longShower.value.error = null
            currentQuestion.value = 3
        }
    }
    else if(currentQuestion.value == 3) {
        // access to peopleInHome with .value and .error 
        // access to wantToAddPeople with .value.value and .value.error 
        if(wantToAddPeople.value.value == null && peopleInHome.value.length == 0) {
            wantToAddPeople.value.error = "Merci de sélectionner une option."
            hasError.value = true
        } else {
            wantToAddPeople.value.error = null
            currentQuestion.value = 4
        }
    }
    else if(currentQuestion.value == 4) {
        if(showers.value.length == 0) {
            showers.error = "Merci d'ajouter une douche"
            hasError.value = true
        } else {
            showers.error = null
            done()
        }
    }
}

async function done() {
    // console.log("DONE", peopleInHome.value.value) gives undefined
    // console.log("DONE", peopleInHome.value.error) gives undefined
    // console.log("DONE", peopleInHome.value.value.length) gives error
    peopleInHome.value.forEach((people) => {
        people.days_count = parseInt(people.days_count)
    })
    let payload = {
        'question_1':{
            'take_baths': takeBaths.value.value,
        },
        'question_2':{
            'long_shower': longShower.value.value,
        },
        'question_3':{
            'people_in_home': peopleInHome.value,
        },
        'question_4':{
            'showers': showers.value,
        }
    }
    console.log('done', payload)

    if (!hasError.value) {
        try {
            isLoading.value = true
            let resp = await API.updateActionState(route.query.actionPk, {action_classname: "MetaActionIdentifyWaysToSaveHotWater", meta_survey_answers: payload})
            if (resp.status == 200) {
                await user.update_profile()
                isLoading.value = false
                router.push({ name: "MetaEnd", query: { univers: 'hot_water' } })
            } else {
                isLoading.value = false
                console.log('ERREUR', resp)
                error.value = "Erreur technique"
            }
        } catch(e){
            isLoading.value = false
            console.log('ERREUR', e)
            error.value = e.response.data.error
        }
        
    }

}
</script>

<style scoped>
.my-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    min-width: 330px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: unset;
}

.white-container {
    flex-grow: 1;
    display: flex;
    height: unset;
}
</style>
