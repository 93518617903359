<template>
    <div class="letsgo is-clickable" @click="beginAction">
        <span v-if="!text">C'est parti&nbsp;!</span>
        <span v-else>{{ text }}</span>
    </div>
    <div class="has-text-danger" v-if="error">{{ error }}</div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import API from '@/services/api';
import { ref } from 'vue';
import { useUserStore } from '@/stores/user';

const router = useRouter()
const userStore = useUserStore()

const props = defineProps({
    actionPk: String,
    actionClassname: String,
    isMeta: Boolean,
    text: {
        type: String,
        required: false
    },
    fromAction: {
        type: Boolean,
        default: false
    }
})

const error = ref(null)

const beginAction = async() => {
    let resp = await API.updateActionState(props.actionPk, {recommendation_status: 'doing'})
    if (resp.status == 200) {
        let newPk = Object.keys(resp.data).includes('new_pk') ? resp.data['new_pk'] : props.actionPk
        // If this is a meta action, we have to push the associated survey
        if(props.isMeta) {
            router.push({ name: `${props.actionClassname}`, query: { actionPk: newPk } })
        }
        else if(props.actionClassname == "ActionInstallAConnectedThermostat") {
            if(router.currentRoute.value.name == "Action") {
                router.push({ name: 'Action', params: { 'pk': newPk } })
            }
            userStore.want_free_thermostat()
        } else {
            router.push({ name: 'Action', params: { 'pk': newPk } })
        }
    } else {
        error.value = "Erreur technique"
    }
}

</script>

<style scoped>

</style>