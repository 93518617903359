<template>
    <div class="my-container" style="justify-content: space-between;">
        <div style="width: 100%;">
            <div class="bg-blue-5" style="padding: 20px 20px 30px;">
                <AlertStack @open-modal="openAlertModal" />
                <div class="title-page">
                    Mon profil
                </div>
                <MyHome :survey="userStore.account.survey_data" />
            </div>
            <div style="padding: 10px 20px;">
                <MenuItem name="Piloter mes thermostats" imgName="profile_thermostat" :chevron="true" @go="onClickThermostat" />
                <MenuItem name="Paramétrer mon logement" imgName="profile_parameters" :chevron="true" @go="onClickParameters" />
                <MenuItem name="Informations légales" imgName="profile_file" :chevron="true" @go="OnClickLegalInformations" />
                <MenuItem name="Me déconnecter" imgName="profile_logout" :chevron="false" link="https://www.winter-energies.fr" @go="logout()" />
                <div style="padding: 16px;">
                    <a
                        :href="`mailto:contact@wattwatchers.fr?subject=Suppression du compte lié à l'email ${userStore.account.user.username}`"
                        class="has-text-weight-medium font-size-14 text-blue-2"
                        @click="deleteAccount"
                    >
                        Supprimer mon compte
                    </a>
                </div>
            </div>
        </div>
        <div style="overflow: hidden; align-items: center;">
            <div style="transform: scale(1.12) translateY(19%);">
                <img src="/src/assets/img/profile_img.svg" style="width: 100%;"/>
            </div>
        </div>

        <ModalInfoAboutFewData :active="showModalInfoAboutFewData" @toggle-info="showModalInfoAboutFewData = !showModalInfoAboutFewData"/>
        <ModalInfoAboutNoHeat :active="showModalInfoAboutNoHeat" @toggle-info="showModalInfoAboutNoHeat = !showModalInfoAboutNoHeat"/>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref } from 'vue';
import API from '@/services/api';
import MyHome from '@/components/MyHome.vue';
import MenuItem from '@/components/MenuItem.vue';
import AlertStack from "@/components/AlertStack.vue";
import ModalInfoAboutFewData from "@/components/modals/ModalInfoAboutFewData.vue";
import ModalInfoAboutNoHeat from "@/components/modals/ModalInfoAboutNoHeat.vue";

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const showModalInfoAboutFewData = ref(false)
const showModalInfoAboutNoHeat = ref(false)

const openAlertModal = (alertId) => {
    if(alertId == 'fewData') {
        showModalInfoAboutFewData.value = true
        userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'not_enough_data'})
    }
    else if(alertId == 'noHeat') {
        showModalInfoAboutNoHeat.value = true
        userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'no_heat'})
    }
}

const onClickThermostat = () => {
    userStore.trackEvent('click_pilot_TC', {'page': route.path})
    router.push({ name: 'Thermostat' })
}

const onClickParameters = () => {
    userStore.trackEvent('click_param_house', {'page': route.path})
    router.push({ name: 'Parameters' })
}

const OnClickLegalInformations = () => {
    userStore.trackEvent('click_legal_info', {'page': route.path})
    router.push({ name: 'LegalInformations' })
}

const logout = async() => {
    console.log("logout")
    userStore.trackEvent('click_log_out', {'page': route.path})
    await API.sendToAnalytics({
        'message_type': 'logout',
        'target_url': '/logout'
    })
    await userStore.logout()
}

const deleteAccount = () => {
    userStore.trackEvent('click_account_deletion', {'page': route.path})
}

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 360px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 98px;
}
</style>
