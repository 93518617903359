<template>
    <Swiper
        :slides-per-view="actions.length > 1 ? 1.3 : 1"
        :space-between="actions.length > 1 ? 10 : 0"
        :modules="[Pagination]"
        :pagination="{ clickable: true }"
    >
        <SwiperSlide v-for="action in actions">
            <div class="card card-container bg-blue-5 p-3 has-text-primary">
                <div>
                    <div class="mb-3 mt-2" style="display: flex; flex-direction: row; font-size: 14px; align-items: center; gap: 5px;">
                        <div>Mon prochain écogeste</div>
                    </div>
                    <div class="mb-3" style="display: flex; flex-direction: row; justify-content: space-between; gap: 20px;">
                        <div>
                            <div class="card-title has-text-weight-bold">
                                {{ action.title }}
                            </div>
                            <div v-if="action.dynamic_description" class="mt-1 font-size-14">
                                <div :class="`text-ellipsis ${Math.round(action.expected_reduction_euros) > 0 ? 'clamp-2' : 'clamp-5'}`">
                                    {{ getCleanDescription(action) }}
                                </div>
                            </div>
                        </div>
                        <!-- not in v1 -->
                        <!-- <div style="display: flex; flex-direction: column; flex-shrink: 1;">
                            <img src="/src/assets/img/connected_thermostat.svg"/>
                        </div> -->
                    </div>
                </div>
                <div class="mb-5" style="display: flex; flex-direction: column; max-width: 100%;">
                    <RouterLink class="link has-text-primary" :to="{ name: 'Action', params: {pk: action.pk} }">
                        En savoir plus
                    </RouterLink>
                    <EstimatedSavings v-if="Math.round(action.expected_reduction_euros) > 0" :expected-reduction-euros="Math.ceil(action.expected_reduction_euros)" bg-color="white"/>
                    <LetsgoButton :action-pk="action.pk" :action-classname="action.action_classname" :is-meta="action.is_meta"/>
                </div>
            </div>
        </SwiperSlide>
    </Swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { RouterLink } from 'vue-router';
import EstimatedSavings from '@/components/EstimatedSavings.vue';
import LetsgoButton from '@/components/LetsgoButton.vue';

const props = defineProps({
    actions: Object,
})

const regexp = /(<([^>]+)>)/ig

const getCleanDescription = (action) => {
    let cleaned = action.dynamic_description.replace(regexp, '')
    return cleaned
}


</script>

<style scoped>

.card-container {
    height: 310px;
    line-height: 1.2;
    box-shadow:none;
    border-radius:20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        height: 310px;
    }
    @media screen and (min-width: 501px) {
        height: 300px;
    }
}
.card-title {
    @media screen and (max-width: 500px) {
        font-size: 16px;
    }
    @media screen and (min-width: 501px) {
        font-size: 18px;
    }
}
.link {
    text-decoration: underline;
    margin-bottom: 8px;

    @media screen and (max-width: 500px) {
        font-size: 12px;
    }
    @media screen and (min-width: 501px) {
        font-size: 14px;
    }
}
</style>