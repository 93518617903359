<template>
    <div class="my-container has-text-centered" style="justify-content: space-between; position: relative;">
        <div class="flexcol bg-yellow-2" style="height: 410px; min-height: 410px; padding-top: 48px;">
            <div>
                <img src="/src/assets/img/not-functional-prm-top-pic.svg" style="height: 100px;"/>
                
                <div class="flexcol font-size-20 text-red-1 has-text-weight-bold mb-2 mt-4 line-height-1-2">
                    <span>Oh non, on ne peut pas</span>
                    <span>récupérer vos données&nbsp;!</span>
                </div>
                
                <div class="has-text-primary font-size-14 line-height-1-2 mx-6">
                    <span>Nous n’avons pas accès aux données de votre compteur électrique</span>
                    <span v-if="userStore.isThermostatProcess">. Vous ne pouvez donc pas bénéficier de l’offre pour des thermostats connectés.</span>
                    <span v-else> et ne pourrons donc pas personnaliser vos conseils.</span>
                </div>
            </div>
        </div>

        <div
            class="flexcol bg-white radius-10 line-height-1-3 mx-5"
            :style="`margin-top: ${userStore.isThermostatProcess ? '-100' : '-110'}px; padding: 18px;`"
        >
            <div class="font-size-16 has-text-weight-bold mb-4">
                <span class="has-text-primary">Voici quelques bonnes pratiques qui pourront </span>
                <span class="text-blue-2">vous aider à diminuer votre consommation énergétique </span>
                <span class="has-text-primary">:</span>
            </div>
            <div class="flexcol has-text-primary ">
                <div class="flexrow mb-3" style="gap: 10px;">
                    <div class="number-tag">1</div>
                    <div class="font-size-14 line-height-1-2" style="text-align: start;">
                        <span class="has-text-weight-semibold">Installer un pommeau de douche écologique. </span>
                        En 3 minutes, divisez d’1/3 votre consommation d’eau chaude.
                    </div>
                </div>
                <div class="flexrow mb-3" style="gap: 10px;">
                    <div class="number-tag">2</div>
                    <div class="font-size-14 line-height-1-2" style="text-align: start;">
                        <span class="has-text-weight-semibold">Faire entretenir sa chaudière </span>
                        par un professionnel. Cela permet une bonne performance énergétique.
                    </div>
                </div>
                <div class="flexrow mb-3" style="gap: 10px;">
                    <div class="number-tag">3</div>
                    <div class="font-size-14 line-height-1-2" style="text-align: start;">
                        <span class="has-text-weight-semibold">Régler votre chauffe-eau à 55°C, </span>
                        cela permet d’éviter la surconsommation.
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-bottom: 54px;">
            <img src="/src/assets/img/signup-done-bottom-pic.svg"/>

            <a
                class="button height-48 radius-10 has-text-weight-bold has-text-white mt-3 bg-blue-1"
                style="border: unset; width: 90%"
                href="https://www.winter-energies.fr/blog?tag=Astuces"
                target="_blank" rel="noopener noreferrer"
                @click="viewAdvices"
            >
                Accéder aux conseils
            </a>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import { useRoute } from 'vue-router'

const userStore = useUserStore()
const route = useRoute()

const viewAdvices = () => {
    userStore.trackEvent('click_view_advices', {'page': route.path, 'origine': userStore.externalParamsArray[0]})
}

</script>

<style scoped>
.my-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.number-tag {
    border-radius: 50%;
    height: 28px;
    min-height: 28px;
    width: 28px;
    min-width: 28px;
    align-content: center;
    background-color: var(--winter-blue-1);
    color: white;
    font-weight: bold;
    margin-top: 3px;
}
</style>
