<template>
    <div class="modal" :class="{ 'is-active': active }" style="justify-content: end;">
        <div class="modal-background bg-blue-1" style="opacity: 63%;"></div>
        <div class="modal-content modal-info-content flexcol">
            <div class="white-container has-text-primary">
                <div class="flexcol" style="justify-content: space-between; width: 100%;">
                    <div class="mb-6">
                        <div class="flexrow mt-2 mb-5" style="justify-content: space-between;">
                            <div class="font-size-20 has-text-weight-bold has-text-primary line-height-1-2 pr-4">
                                Peu de données de chauffage pour votre logement
                            </div>
                            <img src="/src/assets/img/close_modal.svg" class="is-clickable" style="align-self: start;" @click="emit('toggle-info')"/>
                        </div>
                        <Article v-for="(art, i) in articles" :key="art['index']" :title="art['title']" :content="art['content']" :not-last="i + 1 != articles.length" />
                    </div>
                    <div
                        class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold mt-0 mb-5"
                        style="width: 100%;"
                        @click="emit('toggle-info')">
                        J'ai compris
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Article from "@/components/Article.vue"

const props = defineProps({
    active: Boolean
})

const emit = defineEmits(["toggle-info"])

const articles = [
    {   
        'index': 1,
        'title': 'Pourquoi avez-vous peu de données de chauffage pour mon logement ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Nous avons récupéré peu de données de chauffage pour votre logement, cela peut être lié aux raisons suivantes :'
            },
            {
                'type': 'list',
                'text': 'vous avez très peu utilisé votre chauffage (félicitation pour votre sobrieté !)'
            },
            {
                'type': 'list',
                'text': 'vous avez emménagé récemment (probablement après le dernier hiver), n’hésitez pas à revenir au cours de l’hiver, nous aurons alors plus de données'
            },
            {
                'type': 'list',
                'text': 'votre compteur est communiquant depuis trop peu de temps, nous n’avons pas accès à vos données de consommation tant que votre compteur n’était pas communiquant'
            }
        ]
    },
    {
        'index': 2,
        'title': 'Quel est l’impact pour moi ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Les informations disponibles sur la page “Ma consommation” risquent d’être incomplètes et ne se baseront pas encore sur une analyse de votre consommation mais sur un découpage statistique de votre consommation.'
            },
            {
                'type': 'paragraph',
                'text': 'L’estimation des économies pour les actions recommandées sur la page “Mes actions” est moins précise comme nous avons moins de données disponibles pour votre logement.'
            }
        ]
    },
    {
        'index': 3,
        'title': 'D’autres questions ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'N’hésitez pas à nous écrire à <a href="mailto:contact@wattwatchers.fr">contact@wattwatchers.fr</a>, nous serons ravis de vous aider.'
            }
        ]
    }
]

</script>

<style scoped>

.modal-info-content {
    height: 100vh;
    max-height: 100vh;
    margin: unset;
    padding-top: 170px;
}

.white-container {
    flex-grow: 1;
    display: flex;
    min-height: unset;
}

</style>