<template>
    <div :class="`container ${status}`">
        <div v-if="status == 'write'" class="p-1" style="position: absolute; top:0; right: 0;" @click="close">
            <img src="/src/assets/img/close_little_blue.svg"/>
        </div>
        <div class="corner">
            <span v-if="status == 'read'">Votre foyer</span>
            <span v-else>Autres</span>
        </div>
        <div class="content">
            <div class="flexrow content-line">
                <div class="has-text-primary has-text-weight-bold font-size-14">
                    Nombre de personnes
                </div>
                <div class="font-size-14" style="width:15%; padding-left: 2px;">
                    {{ people.people_count }}
                </div>
            </div>
            <div :class="status" style="height: 1px;"></div>
            <div class="flexrow content-line">
                <div class="has-text-primary has-text-weight-bold font-size-14">
                    Nombre de jours comptabilisés par mois
                </div>
                <div class="font-size-14" style="width:15%;">
                    {{ people.days_count }} jours
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

const emit = defineEmits(['remove-people'])

const props = defineProps({
    status: String,
    people: Object,
    index: {
        type: Number,
        required: false
    }
})

const close = () => {
    emit('remove-people', props.index)
}

</script>

<style scoped>
.container {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 8px;
}
.container.read {
    background-color: var(--winter-blue-5);
    border: solid 1px white;
}
.container.write {
    background-color: white;
    border: solid 1px var(--winter-blue-4);
}
.corner {
    font-weight: bold;
    border-radius: 10px 0 10px 0;
    font-size: 12px;
    height: 25px;
    width: 30%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--winter-blue-4);
    color: var(--winter-blue-2);
    padding-bottom: 2px;
    padding-right: 2px;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.content-line {
    padding: 0.6rem;
    justify-content: space-between;
    line-height: 1.2;
}
.read {
    background-color: white;
}
.write {
    background-color: var(--winter-blue-4);
}
</style>