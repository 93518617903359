<template>
    <div class="my-container">
        <TopCard origin="regular"/>
        <div class="content-container" style="justify-content: space-between;">
            <div class="font-size-20 has-text-primary has-text-weight-bold has-text-centered mt-6 line-height-1-2">
                Veuillez patienter, nous vous connectons à Watt Watchers.
            </div>
            <div v-if="error.length > 0" class="mt-6">
                <div v-for="e in error">
                    <p class="has-text-centered text-red-1">{{ e }}</p>
                </div>
                <!-- Button "Retour" -->
                <RouterLink
                    class="is-clickable has-text-centered has-text-primary font-size-14 has-text-weight-bold mt-5 mb-2"
                    style="text-decoration: underline; display: block; width: 100%;"
                    :to="{name: 'SignUp'}"
                >
                    Retour
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import API from '@/services/api';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import TopCard from '@/components/auth/TopCard.vue';

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const error = reactive([])

let uib64 = route.params.uib64
let token = route.params.token

async function check() {
    try {
        let resp = await API.checkConnectionLink(uib64, token)
        if (resp.status == 200) {
            await userStore.update_tokens(resp.data.access, resp.data.refresh)
            await userStore.update_profile()
            router.push({name: "HomeRouter"})
        }
    } catch(e) {
        if (Array.isArray(e.response.data)) {
            error.splice(0, error.length, ...e.response.data)
                error.forEach((err, index) => {
                if (err == "Invalid token") {
                    error[index] = "Le token est périmé, veuillez en redemander un nouveau."
                }
            })
        } else {
            error.push(e.response.statusText)
        }
    }
}

check()
</script>

<style scoped>
.my-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 60px 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border-radius: 25px 25px 0 0;
}
</style>