<template>
    <div class="modal" :class="{ 'is-active': active }" style="justify-content: end;">
        <div class="modal-background bg-blue-1" style="opacity: 63%;"></div>
        <div class="modal-content modal-info-content flexcol">
            <div class="white-container has-text-primary">
                <div class="flexcol" style="justify-content: space-between; width: 100%;">
                    <div class="mb-6">
                        <div class="flexrow mt-2 mb-5" style="justify-content: space-between;">
                            <div class="font-size-20 has-text-weight-bold has-text-primary line-height-1-2 pr-4">
                                Vos actions personnalisées
                            </div>
                            <img src="/src/assets/img/close_modal.svg" class="is-clickable" style="align-self: start;" @click="emit('toggle-info')"/>
                        </div>
                        <div class="has-text-weight-medium line-height-1-1 font-size-14">
                            Vous pouvez retrouver toutes les actions que nous vous recommandons sur la page “Mes actions”.
                        </div>
                        <Article v-for="(art, i) in articles" :key="art['index']" :title="art['title']" :content="art['content']" :not-last="i + 1 != articles.length" />
                    </div>
                    <div
                        class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold mt-0 mb-5"
                        style="width: 100%;"
                        @click="emit('toggle-info')">
                        J'ai compris
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Article from "@/components/Article.vue"

const props = defineProps({
    active: Boolean
})

const emit = defineEmits(["toggle-info"])

const articles = [
    {   
        'index': 1,
        'title': 'Qu’est-ce qu’une action ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Une action est un changement à effectuer dans votre logement ou dans vos habitudes qui vous permettra de diminuer votre consommation d’énergie.'
            }
        ]
    },
    {
        'index': 2,
        'title': 'Comment choisissez-vous les actions que vous me recommandez ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Lors de votre inscription, vous nous fournissez des informations sur vos habitudes et votre logement. Grâce à ces informations et notre analyse de votre consommation, nous choisissons les actions compatibles avec vos équipements, vos usages et qui permettent une réduction maximale de votre consommation d’énergie.'
            },
            {
                'type': 'paragraph',
                'text': 'Vous pouvez toutes les retrouver sur la page “Mes actions” accessible depuis votre menu.'
            }
        ]
    },
    {
        'index': 3,
        'title': 'Comment calculez-vous les économies potentielles ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Les économies potentielles sont calculées par action, en croisant l’analyse de votre consommation et les résultats d’études menées auprès d’un grand nombre de foyers.'
            }
        ]
    },
    {
        'index': 4,
        'title': 'Quelle est la différence entre une action “🌱 Écogeste” et “🛠️ Petits travaux” ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Une action “🌱 Écogeste” est une bonne habitude à adopter. Vous pouvez réaliser ces actions sans achat supplémentaire et sans prise de tête. Pris séparément, ils ont souvent un impact plus faible qu’une action “Petit travaux” mais, additionnés, leur impact sur la planète et sur votre porte-monnaie est fort.'
            },
            {
                'type': 'paragraph',
                'text': 'Une action “🛠️ Petits travaux” nécessite parfois l’achat d’un équipement ou un peu de bricolage. Elles sont moins faciles à mettre en place qu’un “Ecogeste” mais ont un impact plus fort et vous permettent de faire plus d’économies.'
            }
        ]
    },
    {
        'index': 5,
        'title': 'Quels sont les différents statuts ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'Une action passera par les statuts suivants :'
            },
            {
                'type': 'chip',
                'number': '1',
                'text': 'à faire (couleur bleue)'
            },
            {
                'type': 'chip',
                'number': '2',
                'text': 'en cours (couleur orange)'
            },
            {
                'type': 'chip',
                'number': '3',
                'text': 'réalisée (couleur verte)'
            },
            {
                'type': 'paragraph',
                'text': 'Si l’action n’est pas appropriée pour votre logement ou vos habitudes, elle aura le statut “non pertinente” (couleurs grise).'
            }
        ]
    },
    {
        'index': 6,
        'title': 'D’autres questions ?',
        'content': [
            {
                'type': 'paragraph',
                'text': 'N’hésitez pas à nous écrire à <a href="mailto:contact@wattwatchers.fr">contact@wattwatchers.fr</a>, nous serons ravis de vous aider.'
            }
        ]
    }
]

</script>

<style scoped>

.modal-info-content {
    height: 100vh;
    max-height: 100vh;
    margin: unset;
    padding-top: 170px;
}

.white-container {
    flex-grow: 1;
    display: flex;
    min-height: unset;
}

</style>