<template>
    <div class="mt-5 prm-not-found-card bg-yellow-2">
        <div class="flexrow sub-card">
            <div class="text-card">
                <div class="font-size-18 has-text-weight-bold title-part text-orange-1">
                    Oups, on ne trouve pas votre compteur...
                </div>
                <div v-if="reason == 'enedis_called_prm_already_in_db'" class="font-size-14 has-text-primary text-part">
                    Oups, il y a déjà un compte associé à ce compteur. 
                    <span class="is-clickable text-blue-2 has-text-weight-medium" @click="router.push({name: 'ConnectionLink'})">Connectez-vous.</span>
                    Si vous pensez qu'il s'agit d'une erreur, contactez-nous à 
                    <a href="mailto:contact@wattwatchers.fr">contact@wattwatchers.fr</a>.
                </div>
                <div v-else class="font-size-14 has-text-primary text-part">
                    Merci de vérifier que vous avez bien renseigné <span class="has-text-weight-semibold">votre adresse complète</span>
                    (ex. 1 rue de l'Électricité, 01000 Watt) et que <span class="has-text-weight-semibold">le nom est bien le même que sur vos factures d'électricité</span>.
                </div>
            </div>
            <img src="/src/assets/img/prm-found-or-not.svg"/>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter()

const props = defineProps({
    reason: String
})

</script>

<style scoped>
.prm-not-found-card {
    border-radius: 10px;
    padding: 16px;
    width: 100%;
}
.prm-not-found-card .sub-card {
    margin: 0 auto;
    max-width: 310px;
}
.prm-not-found-card .sub-card .text-card {
    max-width: 220px;
    min-width: 220px;
    margin-right: 5px;
}
.prm-not-found-card .sub-card .text-card .title-part {
    line-height: 1.2;
    margin-bottom: 5px;
}
.prm-not-found-card .sub-card .text-card .text-part {
    line-height: 1.2;
}
.prm-not-found-card .sub-card img {
    margin-top: -60px;
    z-index: 1;
}

</style>