<template>
    <div class="survey-container has-text-primary">
        <div class="survey-inner-container">
            <div>
                <div class="survey-step">Étape 2/7</div>
                <SurveyHeader>
                    <template #title>
                        <span class="survey-title">Les habitants de votre logement</span>
                    </template>
                </SurveyHeader>


                <!-- homeOwner field -->
                <div class="survey-question">
                    Êtes-vous locataire ou propriétaire&nbsp;?
                </div>

                <RadioButton
                    v-for="el, index in homeOwnerOptions"
                    :label="el.label"
                    :checked="el.checked"
                    @click="changeHomeOwner(index)"
                />

                <div class="text-error mb-4" v-if="homeOwner.error">{{ homeOwner.error }}</div>


                <!-- nbPeople field -->
                <div class="survey-question">
                    Combien de personnes vivent dans le logement&nbsp;?
                </div>

                <div class="control has-icons-right mb-2 ">
                    <input class="input input-border has-text-primary" :class="{ 'dangerous': nbPeople.error }" style="padding-right: 110px; padding-left: 1em;"
                        v-model="nbPeople.value" type="number" placeholder="" min="0">
                    <span class="icon is-right pt-1 custom-input-unit" style="width: 110px;">
                        personne(s)
                    </span>
                </div>

                <div class="text-error mb-4" v-if="nbPeople.error">{{ nbPeople.error }}</div>


                <!-- nbAdults field -->
                <div v-if="nbPeople.value > 1">
                    <div class="survey-question">
                        Parmi ces personnes, combien d’adultes&nbsp;?
                    </div>

                    <div class="control has-icons-right mb-2 ">
                        <input class="input input-border has-text-primary" :class="{ 'dangerous': nbAdults.error }" style="padding-right: 85px; padding-left: 1em;"
                            v-model="nbAdults.value" type="number" placeholder="" min="0" :max="nbPeople.value">
                        <span class="icon is-right pt-1 custom-input-unit" style="width: 85px;">
                            adulte(s)
                        </span>
                    </div>

                    <div class="text-error mb-4" v-if="nbAdults.error">{{ nbAdults.error }}</div>
                </div>


                <!-- surface field -->
                <div class="survey-question">
                    Quelles sont les tranches d’âge des occupants&nbsp;?
                    <div class="mt-1 font-size-14 line-height-1-1">Sélectionnez toutes celles qui s’appliquent.</div>
                </div>

                <CheckBox
                    v-for="age in residentsAgesOptions"
                    :item="age"
                    :checked="residentsAges.value.has(age.slug)"
                    @update-checkbox="changeResidentsAges"
                />

                <div class="text-error mb-4" v-if="residentsAges.error">{{ residentsAges.error }}</div>
            </div>


            <!-- global errors and next page button -->
            <div class="has-text-centered mt-5 mb-5">
                <div class="text-error mb-2">
                    {{ error }}
                </div>

                <a
                    class="button classic"
                    :class="{'is-loading': isLoading}"
                    @click="done"
                >
                    Suivant
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { watch, ref, reactive } from 'vue';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import SurveyHeader from '@/components/survey/SurveyHeader.vue';
import RadioButton from '@/components/survey/RadioButton.vue';
import CheckBox from '@/components/survey/CheckBox.vue';
import { processSurveyError } from '@/utils.js';

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const isLoading = ref(false)

const error = ref(null)

const homeOwnerOptions = reactive([
    {
        label: "Je suis locataire",
        checked: false
    },
    {
        label: "Je suis propriétaire",
        checked: false
    }
])
const residentsAgesOptions = [
    {
        label: "0-18 ans",
        slug: "under_18"
    },
    {
        label: "18-60 ans",
        slug: "18_60"
    },
    {
        label: "Plus de 60 ans",
        slug: "over_60"
    },
    {
        label: "Je ne souhaite pas répondre",
        slug: "unknown"
    }
]

const homeOwner = ref({ error: null, value: null })
const nbPeople = ref({ error: null, value: null })
const residentsAges = reactive({ error: null, value: new Set() })
const nbAdults = ref({ error: null, value: null })

watch(nbPeople.value, async(newOne, oldOne) => {
    if(newOne.value == 1) nbAdults.value.value = null
})

if (userStore.account.survey_data) {
    homeOwner.value.value = userStore.account.survey_data.home_owner
    nbPeople.value.value = userStore.account.survey_data.nb_people || null
    if(userStore.account.survey_data.residents_ages && userStore.account.survey_data.residents_ages.length > 0) {
        userStore.account.survey_data.residents_ages.forEach(el => residentsAges.value.add(el))
    }
    nbAdults.value.value = userStore.account.survey_data.nb_adults || null
    if (homeOwner.value.value == true) {
        homeOwnerOptions[1].checked = true
    } else if (homeOwner.value.value == false) {
        homeOwnerOptions[0].checked = true
    }
}

const changeHomeOwner = (homeOwnerIndex) => {
    homeOwnerOptions.forEach((el, i) => {
        if(i == homeOwnerIndex) {
            el.checked = true
        }
        else el.checked = false
    })
    homeOwner.value.value = homeOwnerIndex == 0 ? false : true
}

const changeResidentsAges = ([slug, checked]) => {
    if(checked) residentsAges.value.add(slug)
    else residentsAges.value.delete(slug)
}

const defineFamilyTypes = () => {
    let ret = null
    if(residentsAges.value.size >= 1 && !residentsAges.value.has("unknown")) {
        ret = {
            single: nbPeople.value.value == 1 && (residentsAges.value.has("under_18") || residentsAges.value.has("18_60")),
            couple_without_child: nbPeople.value.value == nbAdults.value.value,
            single_parent: nbPeople.value.value > nbAdults.value.value && nbAdults.value.value == 1,
            couple_with_child: nbPeople.value.value > nbAdults.value.value && nbAdults.value.value >= 2,
            single_senior: nbPeople.value.value == 1 && residentsAges.value.has("over_60"),
            couple_senior: nbPeople.value.value > 1 && residentsAges.value.has("over_60") && residentsAges.value.size == 1
        }
    }
    return ret
}

const done = async() => {

    let payload = {
        'step2_done': true,
        'home_owner': homeOwner.value.value,
        'nb_people': nbPeople.value.value,
        'residents_ages': [...residentsAges.value],
        'nb_adults': nbAdults.value.value,
        'family_types': defineFamilyTypes()
    }

    let hasError = false;

    if (homeOwner.value.value == null) {
        homeOwner.value.error = "Merci de sélectionner une option."
        hasError = true
    } else {
        homeOwner.value.error = null
    }
    if (nbPeople.value.value == null || !Number.isInteger(nbPeople.value.value) || nbPeople.value.value < 0) {
        nbPeople.value.error = "Merci de renseigner un nombre entier positif."
        hasError = true
    } else {
        nbPeople.value.error = null
    }
    if (nbPeople.value.value > 1 && (nbAdults.value.value == null || !Number.isInteger(nbAdults.value.value) || nbAdults.value.value < 0)) {
        nbAdults.value.error = "Merci de renseigner un nombre entier positif."
        hasError = true
    } else {
        nbAdults.value.error = null
    }
    if (residentsAges.value.size == 0 ) {
        residentsAges.error = "Merci de sélectionner au moins une option."
        hasError = true
    } else {
        residentsAges.error = null
    }
    
    console.log('done', payload)
    
    if (!hasError) {
        try {
            isLoading.value = true
            let resp = await API.pushSurveyData(userStore.account.id, payload)
            if (resp.status == 200) {
                userStore.trackEvent('click_next_step', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0]})
                await userStore.update_profile()
                router.push({ name: "Survey3" })
            }
        } catch (e) {
            isLoading.value = false
            error.value = processSurveyError(e)
            userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': error.value})
        }
    } else {
        let errorSet = ({
            "home_owner": homeOwner.value.error || '',
            "nb_people": nbPeople.value.error || '',
            "nb_adults": nbAdults.value.error || '',
            "residents_ages": residentsAges.error || ''
        })
        userStore.trackEvent('click_next_step', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'error_type': JSON.stringify(errorSet)})
    }
    isLoading.value = false
}
</script>

<style scoped>

</style>
