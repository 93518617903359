<template>
    <div class="my-container">
        <Header></Header>
        <div class="flexcol pl-5 pr-5 subcontainer" style="justify-content: space-between; flex-grow: 1;">
            <div>
                <div class="has-text-primary font-size-25 has-text-weight-bold mb-4">
                    Économiser
                </div>
                <div class="has-text-primary mb-3">
                    Choisir un univers :
                </div>
                <div>
                    <div class="accordion-body">
                        <div class="accordion">
                            <div v-for="u in univers" class="container" :class="`${selectedUnivers != u ? 'not-active': 'active'}`" style="position: relative;">
                                <div class="univers bg-blue-5 is-clickable" @click="onClick(u)">
                                    <div>
                                        <div class="label font-size-14 has-text-weight-bold text-blue-6">{{ universTitles[u] }}</div>
                                    </div>
                                    <div class="gauge" style="display: flex; flex-direction: row; align-items: center; gap:10px;">
                                        <div class="has-text-primary font-size-14 has-text-weight-semibold">{{gaugeLabels[gauges[u]]}}</div>
                                        <div :class="`gauge-point gauge-bg-${gauges[u]}`"></div>
                                    </div>
                                </div>
                                <!-- We have to put picture here in order to let it exceed accordion width -->
                                <img v-if="allActions.filter(a => a.univers == u && a.recommendation_status == 'recommended').length == 0" class="bravo" :class="`${selectedUnivers != u ? 'not-active': 'active'}`" src="/src/assets/img/bravo.png"/>
                                <div v-if="loaded" class="content">
                                    <UniversContent
                                        :univers=u
                                        :actions="allActions.filter(a => a.univers == u)"
                                        :consumption-colors="userStore.account.consumption_colors[u]"
                                        :univers-label="universLabels[u]"
                                        :is-eco-watt-day="isEcoWattDay"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="primary-button is-clickable bg-blue-1 mt-5 mb-5" @click="router.push({name: 'Conso'})">
                Voir ma consommation
            </div>
        </div>
    </div>
</template>

<script setup>
import API from '@/services/api';
import Header from '@/components/Header.vue';
import UniversContent from '@/components/univers/UniversContent.vue';
import { ref, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const univers = []
if (userStore.account.diagnostic_indicators.conso_chauffage_avant_telechargement > 0) univers.push('heat')
if (userStore.account.diagnostic_indicators.conso_ECS_avant_telechargement > 0) univers.push('hot_water')
if (userStore.account.diagnostic_indicators.conso_autres_avant_telechargement > 0) univers.push('other')

const universTitles = {
    'heat': "Chauffage électrique",
    'hot_water': "Eau chaude",
    'other': "Autres",
}
const universLabels = {
    'heat': "le chauffage électrique",
    'hot_water': "l'eau chaude sanitaire",
    'other': "les autres usages électriques",
}
const selectedUnivers = ref(null)
let allActions = []
const loaded = ref(false)
const isEcoWattDay = ref(false)

const onClick = (univers) => {
    selectedUnivers.value == univers ? selectedUnivers.value = null : selectedUnivers.value = univers
}

const gauges = {
    "heat": userStore.account.consumption_colors.heat.color,
    "hot_water": userStore.account.consumption_colors.hot_water.color,
    "other": userStore.account.consumption_colors.other.color,
}

const gaugeLabels = {
    "red": "Mauvais",
    "orange": "Moyen",
    "yellow": "Correct",
    "yellow-green": "Bien",
    "green": "Très bien"
}

async function getActionsList() {
    let resp = await API.getActionsList()
    if (resp.status == 200) {
        allActions = resp.data
    }
}

async function getEcoWattDailyStatus() {
    isEcoWattDay.value = await userStore.get_eco_watt_daily_status().isEcoWattDay
    loaded.value = true
}

onBeforeMount(async() => {
    await getActionsList()
    await getEcoWattDailyStatus()
    if (route.query.accordionActive != '') {
        selectedUnivers.value = route.query.accordionActive
    }
})

</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 340px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;
}
.univers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 65px;
    border-radius: 14px;
}
.accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.accordion .container {
    position: relative;
    width: 100%;
}
.accordion .label {
    position: relative;
    margin-bottom: 0;
}
.accordion .gauge::after {
    content: url(/src/assets/img/feather_menu_icon.svg);
    display: flex;
}
.accordion .content {
    position: relative;
    background: white;
    width: 100%;
    overflow: hidden;
}
.accordion .container.not-active .content {
    height: 0px;
}
.accordion .container.active .gauge::after {
    content: url(/src/assets/img/feather_menu_icon_open.svg);
}
.gauge-point {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border-width: 4px;
    border-color: white;
    border-style: solid;
}
.gauge-bg-green {
    background-color: var(--winter-green-1);
}
.gauge-bg-yellow-green {
    background-color: var(--winter-green-2);
}
.gauge-bg-yellow {
    background-color: var(--winter-yellow-1);
}
.gauge-bg-orange {
    background-color: var(--winter-orange-1);
}
.gauge-bg-red {
    background-color: var(--winter-red-1);
}
.bravo.not-active {
    display: none;
}
.bravo {
    position: absolute;
    z-index: 1;

    @media screen and (max-width: 350px) {
        max-width: 135%; 
        width: 135%;
        left: -40px;
        top: 460px;
    }
    @media screen and (min-width: 351px) and (max-width: 400px) {
        max-width: 135%; 
        width: 135%;
        left: -40px;
        top: 450px;
    }
    @media screen and (min-width: 401px) and (max-width: 450px) {
        max-width: 130%; 
        width: 130%;
        left: -30px;
        top: 450px;
    }
    @media screen and (min-width: 451px) and (max-width: 500px) {
        max-width: 120%; 
        width: 120%;
        left: -20px;
        top: 440px;
    }
    @media screen and (min-width: 501px) and (max-width: 580px) {
        max-width: 110%; 
        width: 110%;
        left: -10px;
        top: 440px;
    }
    @media screen and (min-width: 581px) {
        max-width: 100%; 
        width: 100%;
        left: 10px;
        top: 420px;
    }
}
</style>