<template>
    <!-- Cadre jauge -->
    <div :class="`mt-3 mb-6 gauge-border-${props.consumptionColors.color}`" style="position: relative; border-radius: 10px; border-width: 2px; border-style: solid; padding:0.4rem 1rem 0 1rem;">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <div style="display: flex; flex-direction: row; gap: 10px; max-width: 40%;">
                <img :src="`/assets/univers/${univers}.svg`" style="min-width: 35px;"/>
                <div class="has-text-primary line-height-1 flexcol" style="justify-content: center;">
                    <div style="font-size: 30px; font-weight: bold;">{{ props.consumptionColors.amount }}</div>
                    <div>euros</div>
                </div>
            </div>
            <div class="gauge-part">
                <div class="flexrow mb-1 mt-1">
                    <ColorsBar :color="props.consumptionColors.color" :limits="props.consumptionColors.limits" />
                </div>
                <div class="has-text-primary similar">
                    Depuis septembre, vous dépensez <span style="font-weight: 600;">{{ consumptionPercentage }}</span>
                     qu'un foyer similaire
                </div>
            </div>
        </div>
        <div v-if="consumptionColors.similar_home_percentage != 0" class="mt-4 mb-2" style="display: flex; align-items: center;">
            <div class="similar-box-text">
                <div class="has-text-primary">{{ redBoxText }}</div>
                <div class="text-blue-2">{{ redBoxSubText }}</div>
            </div>
        </div>
        <!-- Needed for absolute positionning of harry's image, to avoid overlapping -->
        <div v-else style="height: 100px;"></div>
        <img src="/src/assets/img/harry_univers_page.svg" style="position: absolute; bottom: -47px; right: 0;"/>
        <img src="/src/assets/img/harry_arm_univers_page.svg" style="position: absolute; bottom: -35px; right: 8px;"/>
    </div>

    <div>
        <!-- Harry vous conseille avec un swipe des actions recommandées -->
        <div v-if="recommendedActions.length > 0" class="mb-4">
            <div class="text-blue-2 font-size-14 has-text-centered mb-2">HARRY VOUS CONSEILLE</div>
            <div>
                <UniversRecommendedSwiper :actions="recommendedActions" />
            </div>
        </div>
        <div v-else class="bg-blue-5 congrats">
            <div class="text-blue-2 font-size-14 has-text-centered mb-2">HARRY VOUS FÉLICITE</div>
            <div class="has-text-weight-bold has-text-primary font-size-14 mb-3 line-height-1-2">
                D'après notre analyse de vos données énergétiques, vous avez déjà accompli tous les gestes
                que nous pouvons vous recommander.
            </div>
        </div>

        <!-- Autres gestes pour l'univers -->
        <div v-if="doneOrDoingActions.length > 0">
            <div class="has-text-primary font-size-18 has-text-weight-bold mb-3 line-height-1-2">
                Autres gestes pour {{ universLabels[univers] }}&nbsp;:
            </div>
            <div class="columns is-mobile is-multiline mb-1">
                <LittleActionCard
                    v-for="(action, index) in doneOrDoingActions"
                    :key="action.pk"
                    :name="action.action_classname"
                    :pk="action.pk"
                    :status="action.recommendation_status"
                    :title="action.title"
                    :description="action.dynamic_description"
                />
            </div>
            <div
                v-if="doneOrDoingLength > 4"
                class="has-text-primary mb-6 is-clickable has-text-weight-semibold"
                style="text-align: center; text-decoration: underline;"
                @click="moreActions = !moreActions"
            >
                <div v-if="!moreActions">+ Charger plus d'actions</div>
                <div v-else>- Masquer les actions</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import LittleActionCard from './LittleActionCard.vue';
import ColorsBar from './ColorsBar.vue';
import UniversRecommendedSwiper from './UniversRecommendedSwiper.vue';

const props = defineProps({
    univers: String,
    actions: Object,
    consumptionColors: Object,
    universLabel: String,
    isEcoWattDay: Boolean
})

const universLabels = {
    "heat": "le chauffage électrique",
    "hot_water": "l'eau chaude sanitaire",
    "other": "l'électroménager"
}

const moreActions = ref(false)
const doneOrDoingLength = ref(0)

const consumptionPercentage = computed(() => {
    let ret = `${Math.abs(props.consumptionColors.similar_home_percentage)}% `
    if(props.consumptionColors.similar_home_percentage > 0) {
        ret += "de moins"
    } else {
        ret += "de plus"
    }
    return ret
})
const redBoxText = computed(() => {
    let ret = `Dans ton foyer, ${props.universLabel} représente un gros poste de dépense.`
    if(props.consumptionColors.similar_home_percentage > 0) {
        ret = `Dans ton foyer, ${props.universLabel} représente un faible poste de dépense.`
    }
    return ret
})
const redBoxSubText = computed(() => {
    let ret = "Il faut redoubler d'efforts !"
    if(props.consumptionColors.similar_home_percentage > 0) {
        ret = "Bravo !"
    }
    return ret
})


// Sort actions by DESC expected_reduction_euros
const compare = (a, b) => {
    if ( a.expected_reduction_euros > b.expected_reduction_euros ){
        return -1;
    }
    if ( a.expected_reduction_euros < b.expected_reduction_euros ){
        return 1;
    }
    return 0;
}

// We have to filter first RECOMMENDED actions SENSITIVE TO ECOWATT if this is an ecowatt day
// After that we filter RECOMMENDED actions 
const recommendedActions = computed(() => {
    let recoActions = props.actions.filter(a => a.recommendation_status == 'recommended').sort(compare)
    if (props.isEcoWattDay) {
        let isSensitiveToEcowattActions = recoActions.filter(a => a.is_sensitive_to_ecowatt).sort(compare)
        let isNotSensitiveToEcowattActions = recoActions.filter(a => !a.is_sensitive_to_ecowatt).sort(compare)
        recoActions = [...isSensitiveToEcowattActions, ...isNotSensitiveToEcowattActions]
    }
    return recoActions
})

const doneOrDoingActions = computed(() => {
    let doingActions = props.actions.filter(a => a.recommendation_status == 'doing').sort(compare)
    let doneActions = props.actions.filter(a => a.recommendation_status == 'done').sort(compare)
    let doneOrDoingConcat = [...doingActions, ...doneActions]
    doneOrDoingLength.value = doneOrDoingConcat.length
    return moreActions.value ? doneOrDoingConcat : doneOrDoingConcat.slice(0, 4)
})
</script>

<style scoped>

.similar {
    line-height:1.2;
    
    @media screen and (max-width: 500px) {
        font-size: 10px;
    }
    @media screen and (min-width: 501px) {
        font-size: 12px;
        width: 80%;
    }
}
.similar-box-text {
    display: flex;
    flex-direction: column;
    line-height:1.2;
    width: 70%;
    min-height: 75px;
    justify-content: center;
    
    @media screen and (max-width: 500px) {
        font-size: 12px;
    }
    @media screen and (min-width: 501px) {
        font-size: 14px;
    }
}
.gauge-part {
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 500px) {
        max-width: 50%;
    }
    @media screen and (min-width: 501px) {
        max-width: 55%;
    }
}
.gauge-border-green {
    border-color: var(--winter-green);
}
.gauge-border-yellow-green {
    border-color: var(--winter-green-2);
}
.gauge-border-yellow {
    border-color: var(--winter-yellow);
}
.gauge-border-orange {
    border-color: var(--winter-orange);
}
.gauge-border-red {
    border-color: var(--winter-red);
}
.congrats {
    border-radius: 20px;
    padding: 1.3em 1.3em 5em 1.3em;

    @media screen and (max-width: 400px) {
        margin-bottom: 5em;
    }
    @media screen and (min-width: 401px) and (max-width: 450px) {
        margin-bottom: 7em;
    }
    @media screen and (min-width: 451px) {
        margin-bottom: 8em;
    }
}

</style>