<template>
    <div>
        <div class="flexrow mb-2 mt-4" style="justify-content: space-between;">
            <div class="font-size-18 has-text-primary has-text-weight-bold">
                Consigne {{ index + 1 }}
            </div>
            <img v-if="maxIndex > 1" class="is-clickable" src="/src/assets/img/close_little_primary.svg" @click="emit('remove-order', index)"/>
        </div>
        <div class="flexrow has-text-centered" style="gap: 10px;">
            <div class="time-box column is-one-third p-0 bg-gray-1" style="align-content: center;">
                <span class="has-text-primary has-text-weight-bold mr-3" style="align-content: center;">De</span>
                <input
                    class="has-text-primary custom-input p-0 m-0 bg-gray-1"
                    type="time"
                    name="start"
                    :step="60"
                    v-model="timeSlot.start_time_for_input"
                    :min="min"
                    :max="max"
                    required
                    disabled
                />
            </div>
            <div class="time-box column is-one-third p-0 bg-white" style="align-content: center;">
                <span class="has-text-primary has-text-weight-bold mr-3" style="align-content: center;">À</span>
                <input
                    class="has-text-primary custom-input"
                    type="time"
                    name="end"
                    :step="60"
                    v-model="timeSlot.end_time_for_input"
                    :min="min"
                    :max="max"
                    required
                />
            </div>
            <div class="column is-one-third p-0">
                <div class="dropdown" :class="{ 'is-active': showDropdown }"
                    style="width: 100%;">
                    <div class="dropdown-trigger" @click="showDropdown = !showDropdown" style="width: 100%;">
                        <button class="button no-border is-fullwidth radius-10 has-text-primary line-height-1 height-48"
                            aria-haspopup="true" aria-controls="dropdown-menu">
                            <div class="is-flex is-flex-direction-row is-justify-content-space-between" style="width: 100%; align-items: center; gap: 10px;">
                                <span v-if="timeSlot.mode" :class="`has-text-weight-semibold text-${THERMOSTAT_MODE_INFOS[timeSlot.mode].color}`">{{ THERMOSTAT_MODE_INFOS[timeSlot.mode].label }}</span>
                                <font-awesome-icon v-if="!showDropdown" icon="fa-solid fa-chevron-down"/>
                                <font-awesome-icon v-else icon="fa-solid fa-chevron-up"/>
                            </div>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu" style="width:100%;">
                        <div class="dropdown-content input-border">
                            <a @click="showDropdown = !showDropdown; timeSlot.mode = modeKey;"
                                v-for="[modeKey, modeValue] in Object.entries(modeSet)"
                                style="white-space:break-spaces; display: flex; flex-direction: row; justify-content: space-between;"
                                class="dropdown-item has-text-weight-semibold pr-3"
                                :class="`text-${modeValue.color}`"
                            >
                                {{ modeValue.label }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 font-size-14 has-text-danger has-text-centered">{{ error }}</div>
        <div class="order-separator mt-5"></div>
    </div>
</template>

<script setup>
import { ref } from "vue"
import { THERMOSTAT_MODE_INFOS } from '@/utils.js'

const props = defineProps({
    index: Number,
    timeSlot: Object,
    min: String,
    max: String,
    maxIndex: Number,
    error: String,
})

const emit = defineEmits(["remove-order"])

const modeSet = {...THERMOSTAT_MODE_INFOS}
delete modeSet.off_line
delete modeSet.unknown
delete modeSet.unpaired

const showDropdown = ref(false)

</script>

<style scoped>
.time-box {
    height: 48px;
    border-radius: 11px;
}
.custom-input {
    border: none;
    font-size: 14px;
}
.order-separator {
    height: 2px;
    width: 100%;
    background-color:  var(--winter-blue-1);
}
.dropdown-menu {
    min-width: unset;
}
</style>