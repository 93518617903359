<template>
    <div style="border-radius: 20px; box-shadow: 0 0 20px var(--winter-blue-9); padding: 20px; margin: 40px 0;">
        <div class="flexcol has-text-weight-bold has-text-primary line-height-1-1 mt-2" style="text-align: center;">
            <span>Voici une répartition par poste de</span>
            <span>votre consommation d’énergie :</span>
        </div>
        <div ref="chartContainer" style="position: relative; height: 100%; width: 100%;">
            <img src="/src/assets/img/info_icon.svg" class="is-clickable" style="position: absolute; right: 20px; top: 20px; height: 23px; width: 23px;" @click="emit('toggle-doughnut-info')"/>
            <div style="position: absolute; top: 20px; pointer-events: none; height: 100%; width: 100%;">
                <div class="flexcol line-height-1" style="justify-content: center; align-items: center; height: 100%;">
                    <div class="mb-2" style="text-align: center;">
                        <div class="has-text-weight-bold text-blue-2 mb-1">
                            <span style="font-size: 40px;">{{ Math.floor(indicators.conso_total_avant_telechargement * props.elecPrice) }}</span>
                            <span style="font-size: 30px;">€</span>
                        </div>
                        <div class="font-size-14 has-text-primary has-text-weight-medium line-height-1-2">dépensés sur</div>
                        <div class="font-size-14 has-text-primary has-text-weight-medium line-height-1-2">les 12 derniers mois</div>
                    </div>
                </div>
            </div>
            <canvas ref="chartCanvas" id="doughnut"></canvas>
        </div>
        <div>
            <div v-for="(use, index) in Object.keys(useConsumption)">
                <div v-if="useConsumption[use]['consumption_euros'] > 0" class="flexrow" style="justify-content: start; align-items: center; gap: 10px;">
                    <div :style="`height: 10px; width: 20px; background-color:${baseColors[index]}`"></div>
                    <div class="font-size-14 has-text-primary">{{ useConsumption[use]['label'] }}</div>
                    <div class="font-size-14 has-text-primary has-text-weight-bold">{{ useConsumption[use]['consumption_euros'] }}€</div>
                </div>
            </div>
        </div>
    </div> 
</template> 

<script setup> 
import { Chart, registerables } from 'chart.js';
import { toFont } from 'chart.js/helpers';
import { onMounted, ref, reactive, watch } from 'vue';
Chart.register(...registerables);

const labelOutsidePlugin = {
    id: 'labelOutside',
    afterDatasetsDraw(chart, args, options) {
        const { ctx, data } = chart;
        if(chart.canvas.id == 'doughnut') {
            chart.data.datasets.forEach((dataset, datasetIndex) => {
                const meta = chart.getDatasetMeta(datasetIndex);
                meta.data.forEach((element, index) => {
                    const costFontSize = 16;
                    const useFontSize = 10;
                    const costFontWeight = 'bold';
                    const useFontWeight = 'normal';
                    const fontFamily = 'Montserrat';
                    const padding = options.padding;
                    const color = data.datasets[0].backgroundColor[index];

                    const position = element.tooltipPosition();
                    const angle = ((element.startAngle + element.endAngle) / 2);
                    const radius = element.outerRadius;
                    const offsetX = Math.cos(angle) * (radius - padding * 0.75);
                    const offsetY = Math.sin(angle) * (radius - padding * 0.75);
                    const textX = position.x + offsetX;
                    const textY = position.y + offsetY;

                    ctx.fillStyle = color;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';

                    const costLabel = data.datasets[0].data[index] + '€';
                    ctx.font = toFont({
                        size: costFontSize,
                        weight: costFontWeight,
                        family: fontFamily
                    }).string;
                    ctx.fillText(costLabel, textX, textY);
                    
                    const useLabel = data.labels[index];
                    ctx.font = toFont({
                        size: useFontSize,
                        weight: useFontWeight,
                        family: fontFamily,
                    }).string;
                    ctx.fillText(useLabel, textX, textY + costFontSize);
                });
            });
        }
        
    }
};

// C'est uniquement dans le cas des légendes autour du doughnut
// C'est commenté jusqu'à septembre pour voir comment on améliore
// Chart.register(labelOutsidePlugin);

const props = defineProps({
    actions: Array,
    indicators: Object,
    elecPrice: Number
})

const emit = defineEmits(['toggle-doughnut-info'])

console.log('INDICATORS', props.indicators)

const humanize = (value) => {
    return parseInt(Math.round(value))
}

const doughnut = ref(null)
const chartContainer = ref(null);
const chartCanvas = ref(null);

const totalConsumption = props.indicators.conso_total_avant_telechargement
const consoChauffage = props.indicators.conso_chauffage_avant_telechargement || props.indicators.conso_chauffage_statistique_annuelle

const useConsumption = {
    "heat": {
        "label": "Chauffage",
        "consumption_euros": humanize(consoChauffage * props.elecPrice),
        "percentage": humanize(consoChauffage * 100 / totalConsumption)
    },
    "hot_water": {
        "label": "Eau chaude",
        "consumption_euros": humanize(props.indicators.conso_ECS_avant_telechargement * props.elecPrice),
        "percentage": humanize(props.indicators.conso_ECS_avant_telechargement * 100 / totalConsumption)
    },
    "air_conditioning": {
        "label": "Climatisation",
        "consumption_euros": humanize(props.indicators.conso_climatisation_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_climatisation_annuelle * 100 / totalConsumption)
    },
    "household_electrical": {
        "label": "Électroménager",
        "consumption_euros": humanize(props.indicators.conso_electromenager_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_electromenager_annuelle * 100 / totalConsumption)
    },
    "multimedia": {
        "label": "Multimédia",
        "consumption_euros": humanize(props.indicators.conso_multimedia_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_multimedia_annuelle * 100 / totalConsumption)
    },
    "lighting": {
        "label": "Éclairage",
        "consumption_euros": humanize(props.indicators.conso_eclairage_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_eclairage_annuelle * 100 / totalConsumption)
    },
    "cooking": {
        "label": "Cuisson",
        "consumption_euros": humanize(props.indicators.conso_cuisson_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_cuisson_annuelle * 100 / totalConsumption)
    },
    "swimming_pool": {
        "label": "Piscine",
        "consumption_euros": humanize(props.indicators.conso_piscine_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_piscine_annuelle * 100 / totalConsumption)
    },
    "other": {
        "label": "Autres",
        "consumption_euros": humanize(props.indicators.conso_autres_annuelle * props.elecPrice),
        "percentage": humanize(props.indicators.conso_autres_annuelle * 100 / totalConsumption)
    }
}
console.log("USE CONSUMPTION", useConsumption)

const baseColors = ["#E88306", "#1349A0", "#110309", "#440D24", "#77183F", "#AA225B", "#D63275", "#E16699", "#EB99BB"]

// Bind datas to chart
const data = { 
    labels: [], 
    datasets: [{ 
        data: [], 
        backgroundColor: [],
        cutout: "90%",
        hoverBackgroundColor: [],
    }]
};

onMounted(() => {
    Object.keys(useConsumption).forEach((use, index) => {
        if(useConsumption[use]['consumption_euros'] > 0) {
            data.datasets[0].data.push(useConsumption[use]['consumption_euros'])
            data.labels.push(useConsumption[use]['label'])
            data.datasets[0].backgroundColor.push(baseColors[index])
            data.datasets[0].hoverBackgroundColor.push(baseColors[index])
        }
    });

    let padding = 0
    if (chartContainer.value && chartCanvas.value) {
        const containerWidth = chartContainer.value.getBoundingClientRect().width;
        // C'est uniquement dans le cas des légendes autour du doughnut
        // C'est commenté jusqu'à septembre pour voir comment on améliore
        // padding = containerWidth * 0.2
        padding = containerWidth * 0.1
    }

    // Create chart config with datas
    const config = {
        type: 'doughnut',
        data: data,
        options: {
            circumference: 250,
            rotation: -125,
            elements: {
                arc: {
                    borderRadius: 10,
                }
            },
            layout: {
                padding: padding,
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
                labelOutside: {
                    padding: padding,
                }
            },
        },
    };

    doughnut.value = new Chart(document.getElementById('doughnut'), config)
})

</script> 



<style scoped>

</style> 

