<template>
    <div>
        <div class="flexrow is-align-items-center mb-2" style="gap: 6px">
            <div class="is-flex bg-blue-5" style="height: 35px; width: 35px; border-radius: 50%; justify-content: center; align-items: center;">
                <img src="/src/assets/img/univers/hot_water.svg" style="height: 20px;"/>
            </div>
            <div class="has-text-weight-bold font-size-18 text-blue-6">Eau chaude sanitaire</div>
        </div>
        <div class="bg-blue-5 mb-3" style="height: 1px; width: 100%;"></div>
        <div
            class="bg-blue-5 p-4 radius-10 has-text-weight-semibold font-size-14 mb-4"
            style="width:fit-content;">
            Question 3/4
        </div>

        <OnePeopleCard 
            status="read"
            :people="knownPeopleInHome"
        />

        <div class="has-text-primary has-text-weight-bold mb-2 font-size-18 mt-4 line-height-1-2">
            Y a-t-il d'autres personnes qui sont présentes au moins 1 jour par mois&nbsp;?
        </div>
        <div class="has-text-primary mb-2 font-size-14 mb-5 line-height-1-2">
            Une personne présente uniquement les week-ends ou une semaine sur deux, 
            consomme moins d’eau qu’un résident permanent, 
            mais cela impacte tout de même la consommation totale d’eau et donc d’électricité.
        </div>

        <OnePeopleCard 
            v-for="(people, index) in peopleInHome.value"
            :key="`${index}-${people.people_count}-${people.days_count}`"
            @remove-people="removePeople"
            status="write"
            :people="people"
            :index="index"
        />

        <div class="columns is-multiline is-mobile mb-0">
            <div class="column is-12">
                <div class="custom-toggle"
                    :class="{ 'is-selected': wantToAddPeople.value == true }"
                    @click="wantToAddPeople.value = true; wantToAddPeople.error = null;">
                    <div class="flexrow" style="justify-content: space-between;">
                        <div class="has-text-primary has-text-weight-bold">
                            <span v-if="peopleInHome.value.length == 0">Oui, j</span><span v-else>J</span>'ajoute d'autres personnes
                        </div>
                        <img src="/src/assets/img/chevron_right_blue.svg"/>
                    </div>
                </div>
                <div v-if="peopleInHome.value.length == 0" class="custom-toggle mb-0"
                    :class="{ 'is-selected': wantToAddPeople.value == false }"
                    @click="wantToAddPeople.value = false; wantToAddPeople.error = null;">
                    Non
                </div>
            </div>
        </div>

        <div class="has-text-danger mb-4" v-if="wantToAddPeople.error">{{ wantToAddPeople.error }}</div>

        <div class="modal" :class="{ 'is-active': wantToAddPeople.value }">
            <div class="modal-background"></div>
            <div class="modal-content flexcol bg-white" style="height: 100%; max-height: 100%; margin: unset; padding: 20px; justify-content: space-between;">
                <div>
                    <div class="pt-6" style="height:100px;">
                        <div @click="wantToAddPeople.value = null" class="is-clickable has-text-primary font-size-14 has-text-weight-bold" style="text-decoration: underline;">
                            Retour
                        </div>
                    </div>
                    <div class="has-text-primary has-text-weight-bold font-size-18 mb-3">
                        Nombre de personnes
                    </div>
                    <div class="custom-toggle mb-0 flexrow p-2 mb-6" style="justify-content: space-between;">
                        <div class="plusminus" @click="if(peopleCount.value > 1) peopleCount.value -= 1;">
                            <img v-if="peopleCount.value <= 1" src="/src/assets/img/univers/minus_disabled.svg" />
                            <img v-else src="/src/assets/img/univers/minus.svg" />
                        </div>
                        <div class="font-size-25 text-blue-2 has-text-weight-bold">{{ peopleCount.value }}</div>
                        <div class="plusminus" @click="peopleCount.value += 1;">
                            <img src="/src/assets/img/univers/plus.svg"/>
                        </div>
                    </div>
                    <div class="has-text-primary has-text-weight-bold font-size-18 mb-3 line-height-1-2">
                        Nombre de jours comptabilisés par mois
                    </div>
                    <div class="flexrow has-text-primary font-size-14" style="justify-content: space-between;">
                        <div>0</div>
                        <div>30</div>
                    </div>
                    <div class="slider" style="position: relative;">
                        <input style="width: 100%; height: 3px; z-index: 1;" type="range" min="0" max="30" v-model="daysCount.value" step="1" :oninput="onInput">
                        <div class="bg-blue-3" style="height: 12px; width: 12px; border-radius: 50%; position: absolute; left: 0; z-index: 0;"></div>
                        <div class="bg-blue-3" style="height: 12px; width: 12px; border-radius: 50%; position: absolute; right: 0; z-index: 0;"></div>
                    </div>
                    <div :style="`height: 36px; width: 36px; position: relative; display: flex; align-items: center; justify-content: center; margin-left: -6px; margin-right: 6px; left:calc(${ratio} * (100% - 23px));`">
                        <div class="font-size-14 has-text-primary has-text-weight-bold" style="position: absolute; padding-top: 3px;">{{ daysCount.value }}</div>
                        <img src="/src/assets/img/univers/blue_value.svg"/>
                    </div>
                    <div class="mt-4 font-size-14 has-text-primary line-height-1-2">
                        Par exemple pour une personne présente uniquement les week-ends, indiquez 8 jours de présence. <br>
                        Pour une personne présente une semaine sur deux, indiquez 15 jours de présence.
                    </div>
                </div>
                <div class="has-text-centered mt-5 mb-5">
                    <div class="has-text-danger mb-4" v-if="peopleCount.error">{{ peopleCount.error }}</div>
                    <div class="has-text-danger mb-4" v-if="daysCount.error">{{ daysCount.error }}</div>
                    <a class="button is-primary is-fullwidth height-46 radius-10 has-text-weight-bold"
                        @click="validate">
                        Valider
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import OnePeopleCard from './OnePeopleCard.vue';
import { ref } from 'vue';

const emit = defineEmits(['remove-people'])

const props = defineProps({
    peopleInHome: Object,
    knownPeopleInHome: Object,
    wantToAddPeople: Object
})

const peopleCount = ref({error: null, value: 1})
const daysCount = ref({error: null, value: 0})
const ratio = ref(0)

const onInput = (event) => {
    ratio.value = event.target.value / 30
}

const validate = () => {
    peopleCount.value.error = null
    daysCount.value.error = null
    if (peopleCount.value.value > 0 && daysCount.value.value > 0){
        props.peopleInHome.value.push({people_count: peopleCount.value.value, days_count: daysCount.value.value})
        props.wantToAddPeople.value = null
        peopleCount.value.value = 1
        daysCount.value.value = 0
        ratio.value = 0
    } else {
        if (peopleCount.value.value <= 0) peopleCount.value.error = "Le nombre de personnes doit être supérieur à zéro."
        if (daysCount.value.value <= 0) daysCount.value.error = "Le nombre de jours doit être supérieur à zéro."
    }
}

const removePeople = (index) => {
    emit('remove-people', index)
}
</script>

<style scoped>
.plusminus {
    display: flex;
}

.slider {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}
.slider p {
    font-size: 26px;
    font-weight: 600;
    font-family: Open Sans;
    padding-left: 30px;
    color: black;
}
.slider input[type="range"]::-webkit-slider-runnable-track, .slider input[type="range"]::-moz-range-track {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
  	width: 100%;
 	height: 3px;
  	background: var(--winter-blue-3);
  	border: none;
  	outline: none;
}
.slider input[type="range"]::-webkit-slider-thumb, .slider input[type="range"]::-moz-range-thumb {
   	-webkit-appearance: none !important;
    -moz-appearance: none !important;
    width: 20px;
    height: 20px;
    background: var(--winter-blue-2);
    border: 2px solid var(--winter-blue-2);
    border-radius: 50%;
    cursor: pointer;
    transition: border 1000ms ease;
}
.blue-value {
    width: 20px;
    height: 20px;
    background: var(--winter-blue-2);
    border: 2px solid var(--winter-blue-2);
    border-radius: 50%;
}
</style>
