<template>
    <div class="my-container has-text-primary bg-blue-1">
        <MetaSurveyHeader :real-link="realLink()" :onClick="getBack" :disabled="isLoading"/>

        <div class="white-container">
            <div class="p-0 flexcol" style="justify-content: space-between;">
                <div>
                    <Question1 v-if="currentQuestion == 1" :simple-glazing="simpleGlazing" />
                    <Question2 v-if="currentQuestion == 2" :airtightness-flaw="airtightnessFlaw" />
                    <Question3 v-if="currentQuestion == 3" :shutters-curtains="shuttersCurtains" />
                </div>

                <div class="has-text-centered mt-5 mb-5">
                    <div class="has-text-danger mb-2">
                        {{ error }}
                    </div>

                    <a :class="`button is-primary is-fullwidth height-46 radius-10 has-text-weight-bold ${isLoading ? 'is-loading': ''}`"
                        @click="next">
                        <span v-if="currentQuestion == 3">Enregistrer</span>
                        <span v-else>Suivant</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { ref } from 'vue';
import API from '@/services/api';
import MetaSurveyHeader from '@/components/actions/MetaSurveyHeader.vue';
import Question1 from '@/components/actions/MetaActionIdentifyThermalLeaks/Question1.vue';
import Question2 from '@/components/actions/MetaActionIdentifyThermalLeaks/Question2.vue';
import Question3 from '@/components/actions/MetaActionIdentifyThermalLeaks/Question3.vue';

const route = useRoute()
const router = useRouter()
const user = useUserStore()

const simpleGlazing = ref({ error: null, value: null })
const airtightnessFlaw = ref({ error: null, value: null })
const shuttersCurtains = ref({ error: null, value: null })
const currentQuestion = ref(1)
const error = ref(null)
const isLoading = ref(false)

const realLink = () => {
    if(currentQuestion.value == 1) return true
    return false
}

const getBack = () => {
    if(currentQuestion.value != 1) currentQuestion.value -= 1
}

const hasError = ref(false)
const next = () => {
    hasError.value = false
    if(currentQuestion.value == 1) {
        if(simpleGlazing.value.value == null) {
            simpleGlazing.value.error = "Merci de sélectionner une option."
            hasError.value = true
        } else {
            simpleGlazing.value.error = null
            currentQuestion.value = 2
        }
    }
    else if(currentQuestion.value == 2) {
        if(airtightnessFlaw.value.value == null) {
            airtightnessFlaw.value.error = "Merci de sélectionner une option."
            hasError.value = true
        } else {
            airtightnessFlaw.value.error = null
            currentQuestion.value = 3
        }
    }
    else if(currentQuestion.value == 3) {
        if(shuttersCurtains.value.value == null) {
            shuttersCurtains.value.error = "Merci de sélectionner une option."
            hasError.value = true
        } else {
            shuttersCurtains.value.error = null
            done()
        }
    }
}

async function done() {
    let payload = {
        'question_1':{
            'simple_glazing': simpleGlazing.value.value,
        },
        'question_2':{
            'airtightness_flaw': airtightnessFlaw.value.value,
        },
        'question_3':{
            'shutters_curtains': shuttersCurtains.value.value,
        }
    }
    console.log('done', payload)

    if (!hasError.value) {
        try {
            isLoading.value = true
            let resp = await API.updateActionState(route.query.actionPk, {action_classname: "MetaActionIdentifyThermalLeaks", meta_survey_answers: payload})
            if (resp.status == 200) {
                await user.update_profile()
                isLoading.value = false
                router.push({ name: "MetaEnd", query: { univers: 'heat' } })
            } else {
                isLoading.value = false
                console.log('ERREUR', resp)
                error.value = "Erreur technique"
            }
        } catch(e){
            isLoading.value = false
            console.log('ERREUR', e)
            error.value = e.response.data.error
        }
    }
}
</script>

<style scoped>
.my-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    min-width: 330px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: unset;
}

.white-container {
    flex-grow: 1;
    display: flex;
    height: unset;
}
</style>
