<template>
    <RouterView />
    <nav v-if='navRoutes.indexOf(route.name) >= 0' class="my-nav">
        <div class="bg-white flexcol" style="height: 100%; max-width: 600px; margin: 0 auto; padding: 20px 20px 0 20px; box-shadow: 0 -20px 20px #749FC712; justify-content: space-between;">
            <div class="flexrow">
                <RouterLink v-for="r in navRoutes" :key="r" :to="{name: r}" style="flex: 1;" @click="onClickNavBar(r)">
                    <NavTag :img-name="r.toLowerCase()" :active="route.name == r" :title="navRoutesHumanized[r]"/>
                </RouterLink>
            </div>
            <img src="/src/assets/img/nav_wave.svg" :style="getWaveStyle()"/>
        </div>
    </nav>
</template>

<script setup>
import { useRoute, useRouter, RouterView, RouterLink } from 'vue-router';
// import superagent from "superagent";
import { useUserStore } from '@/stores/user';
import NavTag from "@/components/NavTag.vue";

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const navRoutes = ['Dashboard', 'Conso', 'ActionsList', 'Profile']

const navRoutesHumanized = {
    'Dashboard': 'Accueil',
    'Conso': 'Ma conso',
    'ActionsList': 'Mes actions',
    'Profile': 'Profil'
}

const getWaveStyle = () => {
    return `width: calc(100% / ${navRoutes.length}); transform: translateX(calc(${100 * navRoutes.indexOf(route.name)}%)); transition: transform 0.5s ease;`
}

const onClickNavBar = (r) => {
    let destination = router.getRoutes().filter(route => route.name == r)
    if(destination.length >= 1) {
        userStore.trackEvent('click_bottom_nav', {'page': route.path, 'destination_page': destination[0].path})
    }
}

</script>

<style scoped>
.my-nav {
    position: fixed;
    bottom: 0;
    height: 98px;
    min-height: 98px;
    max-height: 98px;
    width: 100%;
    min-width: 360px;
    z-index: 1000;
}
</style>
